import mapValues from 'lodash/mapValues';

export type ListFormat =
  | 'formatShortUnitList'
  | 'formatNarrowUnitList'
  | 'formatLongAndList'
  | 'formatLongOrList';
export type ListFormatters = Record<ListFormat, (list: string[]) => string>;

const buildIntlFormatter = (
  lng: string | string[] | undefined,
  options: Intl.ListFormatOptions,
) => {
  const formatter = new Intl.ListFormat(lng, options);
  return formatter.format.bind(formatter);
};

const intlFormatOptions: Record<ListFormat, Intl.ListFormatOptions> = {
  formatShortUnitList: { type: 'unit', style: 'short' },
  formatNarrowUnitList: { type: 'unit', style: 'narrow' },
  formatLongAndList: { type: 'conjunction', style: 'long' },
  formatLongOrList: { type: 'disjunction', style: 'long' },
};

const getListFormatters = (lng: string | string[] | undefined): ListFormatters =>
  mapValues(intlFormatOptions, (options) => buildIntlFormatter(lng, options));

export default getListFormatters;
