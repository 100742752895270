import { useCallback, useEffect, useRef } from 'react';

export interface UseFocusInOutArgs {
  handleFocusIn?: EventListenerOrEventListenerObject;
  handleFocusOut?: EventListenerOrEventListenerObject;
}

/**
 * Hook for using the focusin and focusout events on a DOM element, which are not natively supported
 * by React.
 *
 * @returns A callback ref to be set on the element to receive focusin and/or focusout event
 * listeners.
 */
const useFocusInOut = ({ handleFocusIn, handleFocusOut }: UseFocusInOutArgs) => {
  const previousData = useRef<{
    element: HTMLDivElement;
    focusIn?: EventListenerOrEventListenerObject;
    focusOut?: EventListenerOrEventListenerObject;
  } | null>(null);

  const removeEventListeners = () => {
    if (previousData.current) {
      if (previousData.current.focusIn) {
        previousData.current.element.removeEventListener('focusin', previousData.current.focusIn);
      }

      if (previousData.current.focusOut) {
        previousData.current.element.removeEventListener('focusout', previousData.current.focusOut);
      }
    }
  };

  useEffect(() => removeEventListeners, []);

  return useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null) {
        removeEventListeners();

        if (handleFocusIn) {
          node.addEventListener('focusin', handleFocusIn);
        }

        if (handleFocusOut) {
          node.addEventListener('focusout', handleFocusOut);
        }

        previousData.current = { element: node, focusIn: handleFocusIn, focusOut: handleFocusOut };
      }
    },
    [handleFocusIn, handleFocusOut],
  );
};

export default useFocusInOut;
