import { Properties } from 'csstype';
import { system } from 'styled-system';

import { RadiiValue } from '@tanium/theme-data';

import AnyStringLiteral from './AnyStringLiteral';
import sxCompose from './sxCompose';

export interface BorderRadiusStyleProps {
  borderTopLeftRadius?: RadiiValue | AnyStringLiteral;
  borderTopRightRadius?: RadiiValue | AnyStringLiteral;
  borderBottomLeftRadius?: RadiiValue | AnyStringLiteral;
  borderBottomRightRadius?: RadiiValue | AnyStringLiteral;
  borderTopRadius?: RadiiValue | AnyStringLiteral;
  borderRightRadius?: RadiiValue | AnyStringLiteral;
  borderBottomRadius?: RadiiValue | AnyStringLiteral;
  borderLeftRadius?: RadiiValue | AnyStringLiteral;
}

const createBorderRadiusStyleFn = <K extends string>(prop: K, cssProps: (keyof Properties)[]) =>
  system({
    [prop]: {
      properties: cssProps,
      scale: 'radii',
    },
  });

export const borderTopLeftRadius = createBorderRadiusStyleFn('borderTopLeftRadius', [
  'borderTopLeftRadius',
]);

export const borderBottomLeftRadius = createBorderRadiusStyleFn('borderBottomLeftRadius', [
  'borderBottomLeftRadius',
]);

export const borderTopRightRadius = createBorderRadiusStyleFn('borderTopRightRadius', [
  'borderTopRightRadius',
]);

export const borderBottomRightRadius = createBorderRadiusStyleFn('borderBottomRightRadius', [
  'borderBottomRightRadius',
]);

export const borderTopRadius = createBorderRadiusStyleFn('borderTopRadius', [
  'borderTopLeftRadius',
  'borderTopRightRadius',
]);

export const borderRightRadius = createBorderRadiusStyleFn('borderRightRadius', [
  'borderTopRightRadius',
  'borderBottomRightRadius',
]);

export const borderBottomRadius = createBorderRadiusStyleFn('borderBottomRadius', [
  'borderBottomLeftRadius',
  'borderBottomRightRadius',
]);

export const borderLeftRadius = createBorderRadiusStyleFn('borderLeftRadius', [
  'borderTopLeftRadius',
  'borderBottomLeftRadius',
]);

export const borderRadiusStylePropNames: (keyof BorderRadiusStyleProps)[] = [
  'borderTopLeftRadius',
  'borderTopRightRadius',
  'borderBottomLeftRadius',
  'borderBottomRightRadius',
  'borderTopRadius',
  'borderRightRadius',
  'borderBottomRadius',
  'borderLeftRadius',
];

export default sxCompose(
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderTopRadius,
  borderRightRadius,
  borderBottomRadius,
  borderLeftRadius,
);
