import {
  HEX_000000,
  HEX_0C1017,
  HEX_121820,
  HEX_253548,
  HEX_292D38,
  HEX_3B93EE,
  HEX_477FBA,
  HEX_ADB5C7,
  HEX_CED7E2,
} from '@tanium/colors';
import { getRgbString } from '@tanium/coreui-utils';
import { Theme } from '@tanium/theme-data';

import defineColor from './defineColor';
import defineShadow from './defineShadow';

export const Overlay1 = defineShadow([
  `0 1px 3px  ${HEX_ADB5C7}`,
  `0 2px 4px ${getRgbString(HEX_000000, '19%')}`,
]);

export const Overlay2 = defineShadow([
  `0 4px 10px  ${getRgbString(HEX_292D38, '30%')}`,
  `0 2px 4px ${getRgbString(HEX_000000, '19%')}`,
]);

export const Overlay4 = defineShadow([
  `0 4px 8px  ${getRgbString(HEX_292D38, '10%')}`,
  `0 4px 16px ${HEX_121820}`,
]);

export const Primary2 = defineShadow([
  `0 2px 1px ${getRgbString(HEX_CED7E2, '75%')}`,
  `0 2px 3px ${HEX_000000}`,
]);

export const Primary3 = defineShadow([
  `0 2px 1px -1px ${getRgbString(HEX_CED7E2, '75%')}`,
  `0 1px 0 ${HEX_0C1017}`,
]);

export const Primary4 = (theme: Theme) => {
  const color = defineColor([HEX_CED7E2, HEX_0C1017]);
  return `0 1px 0 ${color(theme)}`;
};

export const InputFocus = () => `0 0 8px ${HEX_477FBA}`;

export const Inner1 = (theme: Theme) => {
  const color = defineColor([HEX_477FBA, getRgbString(HEX_253548, '40%')])(theme);
  return defineShadow([`inset 0 1px 3px ${color}`, `inset 0 1px 2px ${color}`])(theme);
};

export const Inner2 = (theme: Theme) => {
  const color = defineColor([HEX_3B93EE, HEX_000000])(theme);
  return `inset 0 1px 3px ${color}`;
};
