import React, { useMemo } from 'react';

import PersistentNotificationsContext from './PersistentNotificationsContext';
import { PersistentNotificationsContextType } from './types';

interface PersistentNotificationsProviderProps extends PersistentNotificationsContextType {
  children?: React.ReactNode;
}

/**
 * A component that can be used to provide persistent notifications to other components in the
 * component tree.
 */
const PersistentNotificationsProvider = ({
  children,
  notifications,
}: PersistentNotificationsProviderProps) => {
  const contextValue = useMemo<PersistentNotificationsContextType>(() => ({ notifications }), [
    notifications,
  ]);

  return (
    <PersistentNotificationsContext.Provider value={contextValue}>
      {children}
    </PersistentNotificationsContext.Provider>
  );
};

export default PersistentNotificationsProvider;
