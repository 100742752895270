import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { devWarning } from '@tanium/coreui-utils';

import {
  SetState,
  UseControlledPropArgs,
  UseControlledPropReturnValue,
  ValueGetter,
  ValueSetter,
} from './UseControlledPropArgs';

const controlledPropWarning = (propName: string, componentName: string, isControlled: boolean) =>
  `"${propName}" prop on component ${componentName} was initialized as ${
    isControlled ? 'controlled' : 'uncontrolled'
  }, but is now ${
    isControlled ? 'uncontrolled' : 'controlled'
  }, and the component was not remounted. This may lead to unintended behavior. See: https://reactjs.org/docs/forms.html#controlled-components`;

/**
 * User-defined typeguard to confirm whether the given value is a getter function.
 * Note that this only checks that the value is a function at all, so this means that
 * the useControlledProp hook should not be used to control function values.
 */
const isValueGetter = <T>(valueOrGetter: ValueGetter<T> | T): valueOrGetter is ValueGetter<T> =>
  typeof valueOrGetter === 'function';

/**
 * User-defined typeguard to confirm whether the given value is a getter function that
 * accepts previous value (used in controlled setState).
 * Note that this only checks that the value is a function at all, so this means that
 * the useControlledProp hook should not be used to control function values.
 */
const isNextValueGetter = <T>(valueOrGetter: ValueSetter<T> | T): valueOrGetter is ValueSetter<T> =>
  typeof valueOrGetter === 'function';

/**
 * An abstraction for dealing with controlled props and state in the same way. This hook has the
 * same return signature as useState. The only changes in behavior are that:
 * - Warnings are issued to consumers of your component if an invalid configuration is detected at
 *   runtime, including if a controlled prop becomes uncontrolled or vice versa;
 * - An update callback is used to allow changing props as a result of an update.
 *
 * After instantiation, you should not reference the prop directly, but only as part of the return
 * type of this hook.
 *
 * @see https://reactjs.org/docs/forms.html#controlled-components
 */
function useControlledProp<T>({
  value,
  defaultValue,
  propName,
  componentName,
  onUpdate,
}: UseControlledPropArgs<T>): UseControlledPropReturnValue<T> {
  const isControlled = useRef(value !== undefined);

  // Handle the scenario where the consumer provides a getter function for the value.
  const effectiveValue = useMemo<T>(() => (isValueGetter(value) ? value() : (value as T)), [value]);

  if (process.env.NODE_ENV !== 'production') {
    // The above condition is invariant
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const isCurrentlyControlled = value !== undefined;

      devWarning(
        isCurrentlyControlled === isControlled.current,
        controlledPropWarning(propName, componentName, isControlled.current),
      );
    }, [componentName, propName, value]);
  }

  const controlledUpdate: SetState<T> = useCallback(
    (valueOrGetter) => {
      const updatedValue = isNextValueGetter(valueOrGetter)
        ? valueOrGetter(effectiveValue)
        : valueOrGetter;
      onUpdate({ value: updatedValue, isPropControlled: isControlled.current });
    },
    [effectiveValue, onUpdate],
  );

  const [state, setState] = useState(defaultValue);
  const firstRun = useRef(true);

  // Create a mutable reference to onUpdate. This way the correct onUpdate callback is invoked
  // below, but only when the state is updated (not the callback itself) and without omitting a
  // (non-mutable) value from the dependency array.
  const mutableOnUpdate = useRef(onUpdate);
  mutableOnUpdate.current = onUpdate;

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      mutableOnUpdate.current({ isPropControlled: isControlled.current, value: state! });
    }
  }, [state]);

  return (isControlled.current
    ? [effectiveValue, controlledUpdate]
    : [state, setState]) as UseControlledPropReturnValue<T>;
}

export default useControlledProp;
