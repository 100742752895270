import { defineVariant } from '@tanium/style-system';

import {
  PrimaryActiveBorder,
  PrimaryActiveShadow,
  PrimaryDisabledFill,
  PrimaryDisabledText,
  PrimaryFill,
  PrimaryHoverFill,
  PrimaryHoverText,
  PrimarySubduedFill,
  PrimarySubduedText,
  PrimaryText,
  SecondaryActiveShadow,
  SecondaryBorder,
  SecondaryDisabledBorder,
  SecondaryDisabledFill,
  SecondaryDisabledText,
  SecondaryFill,
  SecondaryHoverBorder,
  SecondaryHoverFill,
  SecondaryHoverText,
  SecondarySubduedBorder,
  SecondarySubduedFill,
  SecondarySubduedText,
  SecondaryText,
} from './themeDefinitions';
import { ButtonVariant } from './types';
import { VARIANT_LINK, VARIANT_PRIMARY } from './variantTypes';

const primaryVariantStyles = {
  bg: PrimaryFill,
  color: PrimaryText,
  borderColor: 'transparent',
  [':not(:disabled)' as string]: {
    ':hover, :active': {
      bg: PrimaryHoverFill,
      color: PrimaryHoverText,
    },
    ':active': {
      boxShadow: PrimaryActiveShadow,
      borderColor: PrimaryActiveBorder,
    },
  },
  ':disabled': {
    bg: PrimaryDisabledFill,
    color: PrimaryDisabledText,
    borderColor: 'transparent',
  },
};

const secondaryVariantStyles = {
  bg: SecondaryFill,
  color: SecondaryText,
  borderColor: SecondaryBorder,
  [':not(:disabled)' as string]: {
    ':hover, :active': {
      bg: SecondaryHoverFill,
      color: SecondaryHoverText,
      borderColor: SecondaryHoverBorder,
    },
    ':active': {
      boxShadow: SecondaryActiveShadow,
    },
  },
  ':disabled': {
    bg: SecondaryDisabledFill,
    color: SecondaryDisabledText,
    borderColor: SecondaryDisabledBorder,
  },
};

export const variant = defineVariant<Exclude<ButtonVariant, typeof VARIANT_LINK>>(
  {
    primary: primaryVariantStyles,
    primarySubdued: {
      ...primaryVariantStyles,
      bg: PrimarySubduedFill,
      color: PrimarySubduedText,
    },
    secondary: secondaryVariantStyles,
    secondarySubdued: {
      ...secondaryVariantStyles,
      bg: SecondarySubduedFill,
      color: SecondarySubduedText,
      borderColor: SecondarySubduedBorder,
    },
  },
  { defaultVariant: VARIANT_PRIMARY },
);

export default variant;
