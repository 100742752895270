import { useRef } from 'react';

import { generateRandomId } from '@tanium/coreui-utils';

export default () => {
  const idRef = useRef<string | undefined>(undefined);

  if (idRef.current === undefined) {
    idRef.current = generateRandomId();
  }

  return idRef.current;
};
