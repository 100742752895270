import { useContext } from 'react';

import HistoryContext from './HistoryContext';

/**
 * A hook to retrieve history.
 */
const useHistory = () => {
  const { history } = useContext(HistoryContext);

  return history;
};

export default useHistory;
