import React from 'react';

import { Box } from '@tanium/react-box';
import { ThemeZIndices } from '@tanium/theme-data';

import NavBarOpen from './open/NavBarOpen';
import NavBarPinned from './pinned/NavBarPinned';
import { ExpansionToggleEvent, NavBarState, NavSectionDetailed } from './types';

export interface NavBarBaseProps {
  /**
   * The sections (i.e., items and optional subsections) to display in the nav bar.
   */
  sections: NavSectionDetailed[];
  /**
   * Current state of the NavBar.
   */
  state: NavBarState;
  /**
   * Callback to invoke when the user mouses over the NavBar.
   */
  onMouseEnter: React.MouseEventHandler;
  /**
   * Callback to invoke when the user mouses out of the NavBar.
   */
  onMouseLeave: React.MouseEventHandler;
  /**
   * Callback invoked when a user triggers an expansion change on a specific section.
   */
  onToggleSectionExpanded: (e: ExpansionToggleEvent) => void;
}

/**
 * Renders the appropriate variation of the nav bar depending on state.
 */
const NavBarBase = ({
  sections,
  state,
  onMouseEnter,
  onMouseLeave,
  onToggleSectionExpanded,
}: NavBarBaseProps) =>
  state !== 'closed' ? (
    <Box
      position={state === 'open' ? 'absolute' : 'relative'}
      zIndex={state === 'open' ? ThemeZIndices.Popover : 'auto'}
      height={state === 'pinned' ? '100%' : undefined}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {state === 'pinned' && (
        <NavBarPinned sections={sections} onToggleSectionExpanded={onToggleSectionExpanded} />
      )}
      {state === 'open' && <NavBarOpen sections={sections} />}
    </Box>
  ) : null;

export default NavBarBase;
