import { useContext } from 'react';

import ToastNotificationsContext from './ToastNotificationsContext';

/**
 * A hook to retrieve toast notifications.
 */
const useToastNotifications = () => {
  const toastNotifications = useContext(ToastNotificationsContext);

  return toastNotifications;
};

export default useToastNotifications;
