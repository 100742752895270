import React from 'react';

import { Box } from '@tanium/react-box';
import { Stack } from '@tanium/react-stack';
import { useTheme } from '@tanium/react-theme-context';

import { VerticalHeaderDivider } from '../../themeDefinitions';
import { NavBarState } from '../types';
import HomePageProductHeader from './HomePageProductHeader';
import PageProductHeader from './PageProductHeader';
import ShowButton from './ShowButton';

export interface NavBarTriggerProps {
  /**
   * Some NavBar trigger renderers may implement a "collapsed" UI for
   * when the user scrolls down on the page.
   */
  collapsed: boolean;
  /**
   * When `disabled` is true, the trigger should not allow the user to show the nav bar.
   * When omitted, this prop is treated as false.
   */
  disabled?: boolean;
  /** The trigger's current state, which may affect the displayed UI. */
  state: NavBarState;
  /** Callback to invoke when the user clicks on the show button */
  onClick: React.MouseEventHandler;
  /** Callback to invoke when the user mouses over the show button OR header */
  onMouseEnter: React.MouseEventHandler;
  /** Callback to invoke when the user mouses out of the show button OR header */
  onMouseLeave: React.MouseEventHandler;
}

export type NavBarTriggerType = 'homepage' | 'page';

interface BaseProps extends NavBarTriggerProps {
  type: NavBarTriggerType;
}

const ShowButtonWrapper = ({
  type,
  isSmallMode,
  children,
}: {
  type: NavBarTriggerType;
  isSmallMode: boolean;
  children: React.ReactNode;
}) => {
  const theme = useTheme();
  if (type === 'homepage' && !isSmallMode) {
    return (
      <Box borderRight={1} borderColor={VerticalHeaderDivider(theme)} p={1}>
        {children}
      </Box>
    );
  }
  return <Box pl={1}>{children}</Box>;
};

/**
 * This general purpose NavBarTrigger can render as the homepage or page variant.
 */
const NavBarTrigger = ({
  collapsed,
  disabled,
  state,
  type,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: BaseProps) => {
  const isSmallMode = collapsed || state === 'pinned';
  return (
    <Stack alignItems="stretch" itemSpacing={type === 'page' || isSmallMode ? 0 : 1}>
      {!disabled && (
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="stretch"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <ShowButtonWrapper type={type} isSmallMode={isSmallMode}>
            <ShowButton
              onClick={onClick}
              navBarState={state}
              isCollapsed={collapsed}
              navBarType={type}
            />
          </ShowButtonWrapper>
        </Box>
      )}
      {/* Left padding is built in as part of the SVG design when the show button is there.
      When the button isn't there, add some left padding. */}
      <Box pl={disabled ? 2 : 0} py={1}>
        {type === 'homepage' ? (
          <HomePageProductHeader collapsed={collapsed} state={state} />
        ) : (
          <PageProductHeader />
        )}
      </Box>
    </Stack>
  );
};

export default NavBarTrigger;
