export const ZINDEX_FIXED_HEADER = 100;
export const ZINDEX_GROUP_INPUT_FOCUS = 1;
export const ZINDEX_RAIL_NAV = ZINDEX_FIXED_HEADER + 10;

// Following z-index values are based on bootstrap z-index values for
// consistency

export const ZINDEX_DROPDOWN = 1000;
export const ZINDEX_MODAL_OVERLAY = 1040;
export const ZINDEX_MODAL_CONTENT = ZINDEX_MODAL_OVERLAY + 10;

// Taken from tux-console core.css
export const ZINDEX_NOTIFICATIONS = 9999;
