import React from 'react';

import styled from '@tanium/react-emotion-9';
import { ProductIcon } from '@tanium/react-product-icon';
import { Stack, StackChild } from '@tanium/react-stack';
import { useWorkbenchDetails } from '@tanium/react-workbench-details-context';
import { css } from '@tanium/style-system';
import { ThemeFontWeights } from '@tanium/theme-data';

import { ProductNameText } from '../../themeDefinitions';
import UnstyledLink from '../UnstyledLink';

const StyledHeader = styled('h1')(
  css({
    margin: 0,
    fontWeight: ThemeFontWeights.Bold,
    color: ProductNameText,
    fontSize: 14,
  }),
);

/**
 * The icon + product name that appears in the navbar header of a page (not home page).
 */
const PageProductHeader = () => {
  const { homeUrl, productName, icon } = useWorkbenchDetails();
  return (
    <UnstyledLink href={homeUrl}>
      <Stack alignItems="center" itemSpacing={1 / 2}>
        {/* lineHeight=0 to cancel the line-height that throws off vertical centering */}
        <StackChild grow={0} lineHeight={0}>
          <ProductIcon height={20} width={20} icon={icon} productName={productName} />
        </StackChild>
        <StyledHeader>{productName}</StyledHeader>
      </Stack>
    </UnstyledLink>
  );
};

export default PageProductHeader;
