import React, { useCallback, useMemo, useState } from 'react';

import { getGlobalStyles } from '@tanium/coreui-mixins';
import { Box } from '@tanium/react-box';
import { BreadcrumbItem, Breadcrumbs } from '@tanium/react-breadcrumbs';
import styled from '@tanium/react-emotion-9';
import { useProduct } from '@tanium/react-product-context';
import { Stack } from '@tanium/react-stack';
import { ToastNotificationsDisplay } from '@tanium/react-toast-notifications-display';
import { useControlledProp } from '@tanium/react-use-controlled-prop';
import { ResizeObserverEntry, useResizeObserver } from '@tanium/react-use-resize-observer';
import { WorkbenchPortalProvider } from '@tanium/react-workbench-portal-context';
import { css } from '@tanium/style-system';
import { ThemeZIndices } from '@tanium/theme-data';

import NavBar from './navbar/NavBar';
import HomePageNavBarTrigger from './navbar/trigger/HomePageNavBarTrigger';
import { NavBarTriggerProps } from './navbar/trigger/NavBarTrigger';
import PageNavBarTrigger from './navbar/trigger/PageNavBarTrigger';
import useNavBarTrigger from './navbar/trigger/useNavBarTrigger';
import { NavSection } from './navbar/types';
import {
  ContentPaneFill,
  HeadingBottomBorder,
  HeadingBottomShadow,
  HeadingFill,
  WorkbenchWrapperFill,
} from './themeDefinitions';

const Wrapper = styled('div')(
  css({
    bg: WorkbenchWrapperFill,
    position: 'relative',
    height: '100%',
    overflowX: 'auto',
    // set z-index of 0 to ensure workbench stays below console chrome (e.g.,
    // dropdown menus)
    zIndex: 0,
  }),
  getGlobalStyles,
);

interface SectionsChangeEvent {
  sections: NavSection[];
}

export interface SkeletonProps {
  /**
   * The breadcrumbs shown in the workbench header.
   */
  breadcrumbs?: BreadcrumbItem[];

  /**
   * The current page label shown in the breadcrumbs.
   */
  currentPageLabel?: string;

  /**
   * The sections that initially populate the NavBar within the Workbench.
   * Use this when section management is uncontrolled.
   * If neither `defaultSections` nor `sections` is provided, the nav bar is hidden.
   */
  defaultSections?: NavSection[];

  /**
   * A NavBarTrigger component to be displayed within the workbench.
   */
  navBarTriggerRenderer?: React.ComponentType<NavBarTriggerProps>;

  /**
   * The route that is currently selected in the NavBar.
   */
  renderedRoute?: string;

  /**
   * The sections that populate the NavBar within the Workbench.
   * Use this when section management is controlled.
   * If neither `defaultSections` nor `sections` is provided, the nav bar is hidden.
   */
  sections?: NavSection[];

  /**
   * Content to be displayed in the workbench's main content pane.
   */
  children?: React.ReactNode;

  /**
   * Invoked when the sections should change, e.g. from expanding/collapsing a section.
   */
  onSectionsChange?: (e: SectionsChangeEvent) => void;
}

const FixedHeaderWrapper = styled('div', {
  shouldForwardProp: (prop) => !['innerRef', 'showBottomBorder'].includes(prop),
})<{ showBottomBorder: boolean }>(
  () =>
    css({
      zIndex: ThemeZIndices.WorkbenchHeader,
      width: '100%',
      position: 'sticky',
      top: 0,
      left: 0,
      bg: HeadingFill,
      pl: 1, // results in edge of image being 16px from edge
      pr: 2,
      // Invisible border and shadow so that it can transition
      borderBottom: 1,
      borderBottomColor: 'transparent',
      boxShadow: '0',
      // Animate certain changing styles within the fixed header.
      ['&, *' as string]: {
        transition: 'all .3s ease-out',
      },
      // Change the wrapper's width instantly rather than animating.
      // The other properties continue to animate.
      ['&' as string]: {
        transitionProperty: 'border',
      },
    }),
  ({ showBottomBorder }) =>
    showBottomBorder &&
    css({
      borderBottomColor: HeadingBottomBorder,
      boxShadow: HeadingBottomShadow,
    }),
);

// The height of the fixed header when it is collapsed, used to place the "open" nav bar properly.
export const COLLAPSED_FIXED_HEADER_HEIGHT = 37;

// The width of the pinned navbar
const PINNED_NAVBAR_WIDTH = 190;

/**
 * WorkbenchSkeleton is in charge of the layout of the nav bar and the page content relative to each
 * other.
 */
const WorkbenchSkeleton = ({
  breadcrumbs,
  children,
  currentPageLabel,
  defaultSections: defaultSectionsProp,
  navBarTriggerRenderer: NavBarTriggerComponent = PageNavBarTrigger,
  renderedRoute,
  sections: sectionsProp,
  onSectionsChange,
}: SkeletonProps) => {
  // TODO: COREUI-2885 to accept an `isHomePage` prop instead of `navBarTriggerRenderer`.
  // Infer whether we're on a home page so we know when to do special fixed header handling.
  const isHomePage = NavBarTriggerComponent === HomePageNavBarTrigger;

  const { persistentStorageKey } = useProduct();

  // Keep track of the (expandable) sections in the NavBar.
  const [sections, setSections] = useControlledProp({
    defaultValue: defaultSectionsProp,
    componentName: 'Workbench',
    onUpdate: ({ value: updatedValue }) => {
      onSectionsChange?.({ sections: updatedValue });
    },
    propName: 'sections',
    value: sectionsProp,
  });

  const {
    navBar: navBarStateProps,
    navBarTrigger: navBarTriggerStateProps,
    state: navBarState,
    waypoint: collapseProductHeaderWaypoint,
  } = useNavBarTrigger({ renderedRoute, sections, storagePrefix: persistentStorageKey });

  const { collapsed: headerIsCollapsed } = navBarTriggerStateProps;

  // Keep track of header height so the "open" nav bar can place itself accordingly.
  const [fixedHeaderHeight, setFixedHeaderHeight] = useState(24);
  const handleResize = useCallback(
    (e: ResizeObserverEntry) => setFixedHeaderHeight(e.target.clientHeight),
    [],
  );
  const productNameContainerRef = useResizeObserver(handleResize);

  /**
   * Update the `expanded` prop of the specified section.
   */
  const handleToggleExpansion = useCallback(
    ({ sectionId, expanded }) => {
      setSections((oldSections) =>
        oldSections.map((s) =>
          'sectionId' in s && s.sectionId === sectionId ? { ...s, expanded } : s,
        ),
      );
    },
    [setSections],
  );

  const navBar = useMemo(
    () =>
      navBarStateProps ? (
        <NavBar {...navBarStateProps} onToggleSectionExpanded={handleToggleExpansion} />
      ) : undefined,
    [navBarStateProps, handleToggleExpansion],
  );

  const [headerPortalContainer, setHeaderPortalContainer] = useState<HTMLDivElement | null>(null);

  return (
    <Wrapper>
      <WorkbenchPortalProvider headerPortalContainer={headerPortalContainer}>
        <FixedHeaderWrapper
          innerRef={productNameContainerRef}
          // Always show the border on non-home pages.
          // On home page, only show the border when the header is collapsed (user is scrolling down)
          // or when the nav bar is pinned.
          showBottomBorder={!isHomePage || headerIsCollapsed || navBarState === 'pinned'}
        >
          <Stack alignItems="center" itemSpacing={1} justifyContent="space-between">
            <Stack alignItems="center" itemSpacing={1 / 2}>
              <NavBarTriggerComponent {...navBarTriggerStateProps} />
              {breadcrumbs && (
                <Breadcrumbs breadcrumbs={breadcrumbs} currentPageLabel={currentPageLabel} />
              )}
            </Stack>
            <div ref={setHeaderPortalContainer} />
          </Stack>
        </FixedHeaderWrapper>
        {/* Place the open nav bar to be beneath the header and it will set its own dimensions. */}
        {navBar && navBarState === 'open' && (
          <Box
            position="sticky"
            top={`${fixedHeaderHeight}px`}
            left={0}
            zIndex={ThemeZIndices.NavBarPopover}
          >
            {navBar}
          </Box>
        )}
        {navBar && navBarState === 'pinned' && (
          <Box
            overflowY="auto"
            height={`calc(100% - ${COLLAPSED_FIXED_HEADER_HEIGHT}px)`}
            width={`${PINNED_NAVBAR_WIDTH}px`}
            position="sticky"
            top={`${COLLAPSED_FIXED_HEADER_HEIGHT}px`}
            left={0}
            zIndex={1}
          >
            {navBar}
          </Box>
        )}
        {/* When the NavBar is pinned the page content needs to move out of the way of the navbar */}
        <Box
          position="absolute"
          top={0}
          width={
            navBar && navBarState === 'pinned' ? `calc(100% - ${PINNED_NAVBAR_WIDTH}px)` : '100%'
          }
          left={navBar && navBarState === 'pinned' ? `${PINNED_NAVBAR_WIDTH}px` : undefined}
          height="100%"
        >
          <Box backgroundColor={ContentPaneFill} pt={`${fixedHeaderHeight}px`} height="100%">
            {collapseProductHeaderWaypoint}
            <ToastNotificationsDisplay />
            {children}
          </Box>
        </Box>
      </WorkbenchPortalProvider>
    </Wrapper>
  );
};

export default WorkbenchSkeleton;
