import { createContext } from 'react';

import { WorkbenchDetailsContextType } from './types';

const WorkbenchDetailsContext = createContext<WorkbenchDetailsContextType>(({
  productName: '',
  icon: '',
  homeUrl: '',
} as unknown) as WorkbenchDetailsContextType);

export default WorkbenchDetailsContext;
