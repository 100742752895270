import React from 'react';
import { AnyProps, devWarning, ForwardedRefComponent, withCoreUIType } from '@tanium/coreui-utils';
import { ThemeDefinition } from '@tanium/react-theme-definition';

export const STACK_CHILD_COREUITYPE = 'StackChild';

export type Overflow = 'hidden' | 'visible';

/**
 * Props that can be set on a `StackChild` component directly that don't have
 * `item*` equivalent default props on the `Stack` component. (Props like
 * `alignItems` that correspond to CSS properties only, but don't actually
 * affect the props set on rendered child component, don't count.)
 */
export interface StackChildOnlyProps {
  /**
   * The `align-self` value for this child.
   */
  alignSelf?: string;
}

/**
 * Props that apply to `StackChild` components and are inherited by default from
 * `item*` props on a parent `Stack` component.
 */
export interface StackChildInheritableProps {
  /**
   * The `flex-basis` for this child.
   */
  basis?: number | string;
  /**
   * The component to use to render this child. Any unrecognized props are
   * spread to this component.
   */
  component?: React.ReactType;
  /**
   * Whether this item should be followed by a divider or not. Note that the
   * last child never has a divider.
   */
  divider?: boolean;
  /**
   * The color of the divider following this item, if a divider is rendered. This
   * prop is either a ThemeDefinition to support multiple theme colors, or just a string literal.
   */
  dividerColor?: ThemeDefinition | string;
  /**
   * The `flex-grow` value for this child.
   */
  grow?: number;
  /**
   * The `overflow` value for this child.
   */
  overflow?: Overflow;
  /**
   * The `flex-shrink` value for this child.
   */
  shrink?: number;
}

/**
 * Props that apply to `Stack` children that are determined (or calculated) by
 * the `Stack` component, and aren't directly set by consumers of `StackChild`.
 */
export interface StackChildInternalProps {
  spacing: number;
  spacingSide: 'Right' | 'Bottom';
}

export interface StackChildOwnProps extends StackChildInheritableProps, StackChildOnlyProps {}

/**
 * Props that are created by `Stack` and actually passed to the `StackChild`
 * component at runtime.
 */
export interface StackChildProcessedProps extends AnyProps {
  /** A "secret" flag to verify the child is being rendered by a `Stack`. */
  __isStackChild: boolean;
  className: string;
  component: NonNullable<StackChildInheritableProps['component']>;
}

/**
 * Props that can be set by consumers of the `StackChild` component.
 */
export interface Props extends StackChildOwnProps, AnyProps {}

// Defining a stub object instead of `(keyof StackChildOwnProps)[]`
// ensures the resulting key list is--and remains--exhaustive.
const ownPropsStub: Record<keyof StackChildOwnProps, undefined> = {
  alignSelf: undefined,
  basis: undefined,
  component: undefined,
  divider: undefined,
  dividerColor: undefined,
  grow: undefined,
  overflow: undefined,
  shrink: undefined,
};
const ownPropsKeys = Object.keys(ownPropsStub) as (keyof StackChildOwnProps)[];

const removeOwnProps = <T extends StackChildOwnProps>(props: T) => {
  const copy = { ...props };
  ownPropsKeys.forEach((key) => {
    delete copy[key];
  });

  return copy as Omit<T, keyof StackChildOwnProps>;
};

/**
 * A component that modifies the behavior of a immediate child of a `Stack`
 * component. This component should only be used as an immediate child of a
 * `Stack` component.
 */
const StackChildInner = React.forwardRef<unknown, Props>((props, ref) => {
  const {
    __isStackChild: isStackChild,
    component: Component,
    ...rest
  } = props as StackChildProcessedProps & StackChildOwnProps;

  if (!isStackChild) {
    devWarning(
      false,
      'StackChild component is being rendered unexpectedly. StackChild components should only be used as direct children of a Stack component.',
    );
    return null;
  }

  // When `StackChild` gets cloned, the "own props" are preserved because
  // `cloneElement` merges the provided props into the prior props. However,
  // they cannot be forwarded because they are unlikely to be valid props on the
  // rendered `component` and could cause errors if those props end up on DOM
  // elements.
  const componentProps = removeOwnProps(rest);

  return <Component ref={ref} {...componentProps} />;
}) as ForwardedRefComponent<unknown, Props>;

export const StackChild = withCoreUIType(STACK_CHILD_COREUITYPE)(StackChildInner);
