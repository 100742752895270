import { useEffect, useRef } from 'react';

/**
 * Hook to store and retrieve a previous value (from the previous invocation of the hook).
 *
 * @param newValue The new value, which will be returned as the previous value on the next
 * invocation.
 *
 * @returns The value that was stored the last time the hook was invoked. If the hook has not
 * previously been invoked (i.e., it is the first render), the value passed in will be returned.
 */
function usePreviousValue<T extends unknown>(newValue: T): T;
function usePreviousValue<T extends unknown, U extends unknown>(
  newValue: T,
  defaultValue: U,
): T | U;
function usePreviousValue<T extends unknown, U extends unknown>(newValue: T, defaultValue?: U) {
  const value = useRef(typeof defaultValue === 'undefined' ? newValue : defaultValue);

  const { current } = value;

  // Updating the value ref in an effect protects against aborted renders, e.g., in concurrent mode
  useEffect(() => {
    value.current = newValue;
  }, [newValue]);

  return current;
}

export default usePreviousValue;
