import defineThemeValue, { ThemeDefinitionFactory } from './defineThemeValue';

/**
 * Create a theme definition for colors
 *
 * @param themeDependentValue A tuple where the first element represents a bluestar value and the second is the dark mode value
 */
const defineColor = defineThemeValue as ThemeDefinitionFactory<string>;

export default defineColor;
