import React, { useMemo } from 'react';

import { WorkbenchPortalContextType } from './types';
import WorkbenchPortalContext from './WorkbenchPortalContext';

interface Props extends WorkbenchPortalContextType {
  children?: React.ReactNode;
}

/**
 * A component that can be used to provide portal container information to other components in the
 * component tree.
 */
const PortalContainerProvider = ({ headerPortalContainer, children }: Props) => {
  const contextValue = useMemo(() => ({ headerPortalContainer }), [headerPortalContainer]);
  return (
    <WorkbenchPortalContext.Provider value={contextValue}>
      {children}
    </WorkbenchPortalContext.Provider>
  );
};

export default PortalContainerProvider;
