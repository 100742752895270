import { Theme, THEME_DARKMODE } from '@tanium/theme-data';

// We check for `THEME_DARKMODE` existence here as an extra precaution
// in case the consumer has not updated their peer dependencies properly
// and is on a version of `theme-data` that has neither a `THEME_DARKMODE`
// constant nor a theme `name`.
const isDarkMode = (theme: Theme) =>
  typeof THEME_DARKMODE !== 'undefined' && theme.name === THEME_DARKMODE;

export default isDarkMode;
