import React from 'react';

import { Box } from '@tanium/react-box';
import { BreadcrumbCaretThemeAware as BreadcrumbCaret } from '@tanium/react-graphics';
import { Stack } from '@tanium/react-stack';
import { ThemeFontWeights } from '@tanium/theme-data';

import Breadcrumb from '../Breadcrumb';
import { CaretFill, CurrentPageText } from '../themeDefinitions';

export interface BreadcrumbItem {
  href: string;
  label: string;
}

interface Props {
  /**
   * Plain JavaScript representations of the breadcrumb links.
   */
  breadcrumbs: BreadcrumbItem[];
  /**
   * The title of the current page, which will appear as the final, non-linked "breadcrumb".
   */
  currentPageLabel?: string;
}

const getCaret = (index: number) => (
  <Box key={`separator-${index}`} color={CaretFill}>
    <BreadcrumbCaret />
  </Box>
);

const getBreadcrumb = (href: string, label: string) => (
  <Breadcrumb key={`${label}|${href}`} href={href}>
    {label}
  </Breadcrumb>
);

/**
 * A set of Breadcrumbs which includes a non-linked label for the current page.
 */
const Breadcrumbs = ({ breadcrumbs: pojoBreadcrumbs, currentPageLabel }: Props) => {
  // Convert POJO breadcrumbs to Breadcrumb child elements
  const children = pojoBreadcrumbs.reduce((acc, { href, label }, index) => {
    acc.push(getCaret(index));
    acc.push(getBreadcrumb(href, label));
    return acc;
  }, [] as React.ReactNodeArray);

  if (currentPageLabel !== undefined) {
    children.push(getCaret(pojoBreadcrumbs.length));
    children.push(
      <Box key="current-page-label" color={CurrentPageText} fontWeight={ThemeFontWeights.Bold}>
        {currentPageLabel}
      </Box>,
    );
  }

  return children.length > 0 ? (
    <Stack fontSize="12px" itemSpacing={1 / 2}>
      {children}
    </Stack>
  ) : null;
};

export default Breadcrumbs;
