import { StyledStatelessComponent } from 'create-emotion-styled/types/react';
import React from 'react';

import styled from '@tanium/react-emotion-9';
import { Link } from '@tanium/react-link';

const UnstyledLink: StyledStatelessComponent<
  React.ComponentPropsWithoutRef<typeof Link>,
  {},
  {}
> = styled(Link)({
  /* bootstrap override */
  '&&': {
    color: 'inherit',
    textDecoration: 'none',
  },
});

export default UnstyledLink;
