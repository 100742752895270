import React, { useCallback } from 'react';

import { css } from '@tanium/react-emotion-9';
import { useUsingMouse } from '@tanium/react-mouse-interaction-context';
import { useControlledProp } from '@tanium/react-use-controlled-prop';

export interface OnToggleCollapsedEventArgs {
  isCollapsed: boolean;
}

export interface OnToggleCollapsedEventHandler {
  (e: OnToggleCollapsedEventArgs): void;
}

/**
 * Props for a component that is collapsible.
 */
export interface CollapsedProps {
  /**
   * Whether the component is collapsed. If this prop is specified, the collapsed state will be
   * treated as controlled.
   */
  isCollapsed?: boolean;
  /**
   * Whether the component should be initially collapsed. If this prop is specified, the collapsed
   * state will be treated as uncontrolled.
   */
  defaultCollapsed?: boolean;
  /**
   * A callback to be invoked when the collapsed state should be changed.
   */
  onToggleCollapsed?: OnToggleCollapsedEventHandler;
}

/**
 * Props for a component that is optionally collapsible.
 */
export interface CollapsedCollapsibleProps extends CollapsedProps {
  /**
   * Whether the component should act as collapsible or not. If `false`, the component will always
   * act as expanded regardless of the values of `isCollapsed` and `defaultCollapsed`.
   */
  isCollapsible?: boolean;
}

export interface UseCollapsedReturnValue {
  /**
   * The current collapsed state.
   */
  isCollapsed: boolean;
  /**
   * A function to set the collapsed state.
   */
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * Props to spread to the DOM element controlling the collapsed state.
   */
  collapseControlProps: {};
}

const noOutlineClassName = css({ ':focus': { outline: 'none' } });

/**
 * A hook for managing collapsed state of a collapsible component and props for the element
 * controlling that state.
 *
 * @see https://www.w3.org/TR/wai-aria-practices/#disclosure
 */
const useCollapsed = <T extends CollapsedCollapsibleProps = CollapsedCollapsibleProps>(
  {
    isCollapsed: isCollapsedProp,
    defaultCollapsed = false,
    onToggleCollapsed,
    isCollapsible = true,
  }: T,
  componentName: string,
): UseCollapsedReturnValue => {
  const [isCollapsedState, setIsCollapsedState] = useControlledProp({
    componentName,
    onUpdate: ({ value }) => {
      if (onToggleCollapsed) {
        onToggleCollapsed({ isCollapsed: !!value });
      }
    },
    propName: 'isCollapsed',
    value: isCollapsedProp,
    defaultValue: defaultCollapsed,
  });

  const usingMouse = useUsingMouse();

  const isCollapsed = isCollapsedState && isCollapsible;

  const toggleCollapsedState = useCallback(() => {
    setIsCollapsedState((v) => !v);
  }, [setIsCollapsedState]);

  const collapseControlProps: Partial<React.HTMLProps<HTMLElement>> = isCollapsible
    ? {
        role: 'button',
        tabIndex: 0,
        'aria-expanded': !isCollapsed,
        onClick: () => {
          toggleCollapsedState();
        },
        onKeyPress: (e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggleCollapsedState();
            e.preventDefault();
          }
        },
        className: usingMouse ? noOutlineClassName : undefined,
      }
    : {};

  return { isCollapsed, collapseControlProps, setIsCollapsed: setIsCollapsedState };
};

export default useCollapsed;
