import React, { useCallback, useMemo, useRef } from 'react';

import { LinkBase } from '@tanium/react-link-base';
import { useRouter } from '@tanium/react-router';
import { useAggregatedRef } from '@tanium/react-use-aggregated-ref';

type Anchor = JSX.IntrinsicElements['a'];
type ButtonVariant = 'link' | 'primary' | 'secondary';
type ButtonSize = 'sm' | 'lg';

export interface Props extends Omit<Anchor, 'href' | 'css'> {
  /**
   * URL to navigate to. If the path is not absolute, the navigation will be handled within the
   * router (so there is no need to prefix urls with a "#"). See examples in the component
   * documentation.
   */
  href?: string;
  /**
   * Optional parameter for specifying Button styling for the Link. The default behavior is
   * the same as that of passing the prop `buttonVariant: 'link'`: it will appear as a normal link. `primary`
   * and `secondary` for the buttonVariant type refer to the types of button that the link could look like.
   */
  buttonVariant?: ButtonVariant;

  /**
   * Optional parameter for specifying the size for a Button-styled Link. The buttonSize is an optional
   * parameter that specifies the size of the component (values are `sm` and `lg`). This parameter is
   * only effectual if a `buttonVariant` is provided.
   */
  buttonSize?: ButtonSize;
}

/**
 * A link component for navigating using the Tanium router. Pass in an href and the router takes
 * care of managing history on click.
 * @example
 * <Link href="https://www.tanium.com">Link to another site</Link>
 *
 * @example
 * <Link href="/some/other/workbench">Link to some Tanium page</Link>
 *
 * @example
 * <Link href={expandUrl({path: '/workbench/test/:id', params: {id: 1}})}>Link to a workbench page</Link>
 */
const Link = React.forwardRef<HTMLAnchorElement, Props>(
  ({ onClick, href: hrefProp, ...rest }, ref) => {
    const { changeRoute, createHref, isInternalRoute } = useRouter();

    const href = hrefProp ? createHref(hrefProp) : undefined;

    const isHrefInternal = typeof hrefProp === 'string' && isInternalRoute(hrefProp);
    const isTargetBlank = rest.target === '_blank';

    const wouldRouteInternally = isHrefInternal && !isTargetBlank;

    // Angular registers a global click listener that will cause this link to be followed if it
    // points to a non-absolute URL. React won't have a chance to process the synthetic click event
    // before the native event reaches Angular's handler, at which point it will trigger the
    // navigation unless preventDefault is called.
    //
    // To work around, we grab a ref to the DOM element to register a native event handler that
    // calls preventDefault (which Angular respects). Then we invoke the desired behavior (or
    // refrain from doing so, per any supplied onClick handler) in the React onClick handler.

    /**
     * Tracks whether the original native event had `preventDefault` called on it or not.
     */
    const wasOriginalDefaultPrevented = useRef(false);

    // TODO: if useCallback ever forgets this callback, the event listener will end up being
    // re-added without being cleaned up. This seems unlikely and harmless, but it's a theoretical
    // possibility.
    const innerRef = useCallback(
      (node: HTMLAnchorElement) => {
        if (node !== null) {
          node.addEventListener('click', (e) => {
            wasOriginalDefaultPrevented.current = e.defaultPrevented;

            if (wouldRouteInternally) {
              e.preventDefault();
            }
          });
        }
      },
      [wouldRouteInternally],
    );

    const refs = useMemo(() => [ref, innerRef], [ref, innerRef]);
    const aggregateRef = useAggregatedRef(refs);

    return (
      <LinkBase
        // The typings for forwardRef are notoriously difficult with styled components, doubly
        // complicated by the fact that Links can take a component param to define the underlying
        // tag for the node.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={aggregateRef as any}
        href={href}
        {...rest}
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
          e.defaultPrevented = wasOriginalDefaultPrevented.current;

          if (onClick) {
            onClick(e);
          }

          // Links should route (at all) if they have a non-empty href prop and did not call preventDefault
          const shouldRoute = hrefProp && !e.defaultPrevented;

          // Links should prevent default if they shouldn't route (at all), or if routing will be
          // handled internally via changeRoute
          const shouldPreventDefault = !shouldRoute || wouldRouteInternally;

          if (shouldRoute && wouldRouteInternally) {
            changeRoute(hrefProp);
          }
          if (shouldPreventDefault) {
            e.preventDefault();
          }
        }}
      />
    );
  },
);

export default Link;
