import React, { useMemo } from 'react';

import { OmitStrict } from '@tanium/coreui-utils';

import NavBarBase, { NavBarBaseProps } from './NavBarBase';
import {
  NavSection,
  NavSectionDetailed,
  NavSectionLeaf,
  NavSectionLeafDetailed,
  NavSectionParent,
  NavSectionParentDetailed,
} from './types';

/**
 * Type guard function that returns true if the given section is a parent section with subsections.
 */
const isNavSectionParentPartial = (section: NavSection): section is NavSectionParent =>
  'subSections' in section;

/**
 * Returns true if this leaf's route matches the renderedRoute
 * and should be rendered as "selected".
 */
const isNavSectionLeafSelected = (section: NavSectionLeaf, renderedRoute: string): boolean => {
  const { route } = section;
  return Array.isArray(route) ? route.includes(renderedRoute) : route === renderedRoute;
};

/**
 * Returns true if this section contains a subSection that matches the renderedRoute
 * and should be rendered as "selected".
 */
const isNavSectionParentSelected = (section: NavSectionParent, renderedRoute: string): boolean =>
  section.subSections.some((s) => isNavSectionLeafSelected(s, renderedRoute));

/**
 * Convert a consumer-supplied nav section leaf into the equivalent that NavBarBase accepts.
 */
const mapNavSectionLeaf = (
  section: NavSectionLeaf,
  renderedRoute: string | undefined,
  last: boolean,
  nested: boolean,
): NavSectionLeafDetailed => {
  const { route, name, url } = section;
  const selected = !!renderedRoute && isNavSectionLeafSelected(section, renderedRoute);
  return {
    last,
    name,
    nested,
    route,
    selected,
    url,
  };
};

/**
 * Convert a consumer-supplied nav section parent into the equivalent that NavBarBase accepts.
 */
const mapNavSectionParent = (
  section: NavSectionParent,
  renderedRoute: string | undefined,
): NavSectionParentDetailed => {
  const { name, sectionId, subSections } = section;
  const selected = !!renderedRoute && isNavSectionParentSelected(section, renderedRoute);

  // Clicking a parent section should go to the URL from the first subSection (leaf).
  const { url } = subSections[0];
  return {
    expanded: !!section.expanded,
    name,
    sectionId,
    selected,
    // Map each leaf section
    subSections: subSections.map((subSection, leafIndex, arr) =>
      mapNavSectionLeaf(subSection, renderedRoute, leafIndex === arr.length - 1, true),
    ),
    url,
  };
};

export interface NavBarProps extends OmitStrict<NavBarBaseProps, 'sections'> {
  /**
   * The currently rendered route which will be highlighted in nav bar UI.
   * If omitted, no section will be highlighted.
   */
  renderedRoute?: string;
  /**
   * A plain JavaScript representation of the sections in this NavBar.
   */
  sections: NavSection[];
}

/**
 * Converts simplified consumer-supplied representations of nav sections into a detailed format that NavBarBase
 * and its subcomponents can use.
 */
const NavBar = ({
  renderedRoute,
  sections,
  state,
  onMouseEnter,
  onMouseLeave,
  onToggleSectionExpanded,
}: NavBarProps) => {
  const sectionsMap: NavSectionDetailed[] = useMemo(
    () =>
      sections.map((section) =>
        isNavSectionParentPartial(section)
          ? mapNavSectionParent(section, renderedRoute)
          : mapNavSectionLeaf(section, renderedRoute, false, false),
      ),
    [renderedRoute, sections],
  );

  return (
    <NavBarBase
      sections={sectionsMap}
      state={state}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onToggleSectionExpanded={onToggleSectionExpanded}
    />
  );
};

export default NavBar;
