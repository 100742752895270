const ThemeSpace = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  bordered: {
    0: 'bordered.0',
    1: 'bordered.1',
    2: 'bordered.2',
    3: 'bordered.3',
    4: 'bordered.4',
    5: 'bordered.5',
    6: 'bordered.6',
    7: 'bordered.7',
    8: 'bordered.8',
    minor: {
      0: 'bordered.minor.0',
      1: 'bordered.minor.1',
      2: 'bordered.minor.2',
      3: 'bordered.minor.3',
      4: 'bordered.minor.4',
      5: 'bordered.minor.5',
      6: 'bordered.minor.6',
      7: 'bordered.minor.7',
      8: 'bordered.minor.8',
    },
  },
  minor: {
    0: 'minor.0',
    1: 'minor.1',
    2: 'minor.2',
    3: 'minor.3',
    4: 'minor.4',
    5: 'minor.5',
    6: 'minor.6',
    7: 'minor.7',
    8: 'minor.8',
  },
} as const;

export default ThemeSpace;
