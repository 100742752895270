import { useContext } from 'react';

import WorkbenchLocaleContext from './WorkbenchLocaleContext';

/**
 * A hook to use this in workbench code, components that can be used in workbenches,
 * or storybook to determine the current supported locale.
 */
const useWorkbenchLocale = () => {
  const { workbenchLocale } = useContext(WorkbenchLocaleContext);

  return workbenchLocale;
};

export default useWorkbenchLocale;
