import { compose, styleFn } from 'styled-system';

const composeWrapper = (...parsers: styleFn[]) => {
  const composedParser = compose(...parsers);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function parserWrapper(props: Record<string, any>) {
    // Use the style props defined by the parsers to determine
    // which of the props are style props
    const stylePropKeys = parsers
      .map((parser) => Object.keys(parser.config ?? {}))
      .reduce((acc, keys) => acc.concat(keys), []);

    const keysToProcess = Object.keys(props).filter((propKey) => stylePropKeys.includes(propKey));

    const processedThemeDefinitionProps = keysToProcess.reduce((acc, prop) => {
      if (typeof props[prop] === 'function') {
        acc[prop] = props[prop](props.theme);
      }

      return acc;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as Record<string, any>);

    return composedParser({ ...props, ...processedThemeDefinitionProps });
  }
  // styled system parsers are hybrid types (function and object)
  Object.assign(parserWrapper, composedParser);

  return parserWrapper;
};

export default composeWrapper;
