import * as React from 'react';

function SvgChevronRight(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 16 16" ref={svgRef} {...props}>
      <path
        d="M4.171 2.1A1.263 1.263 0 016.072.431l5.653 6.46a1.685 1.685 0 010 2.218l-5.653 6.46a1.263 1.263 0 01-1.9-1.664l4.682-5.35a.843.843 0 000-1.11z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChevronRight);
export default ForwardRef;
