import React, { PropsWithoutRef } from 'react';

import { OmitStrict } from '@tanium/coreui-utils';
import {
  ButtonSize,
  ButtonVariant,
  createBaseButtonCss,
  sizeVariant,
  VARIANT_PRIMARY,
  VARIANT_PRIMARY_SUBDUED,
  VARIANT_SECONDARY,
  VARIANT_SECONDARY_SUBDUED,
  variant as variantCss,
} from '@tanium/react-button-base';
import styled from '@tanium/react-emotion-9';
import { useUsingMouse } from '@tanium/react-mouse-interaction-context';
import { useRouter } from '@tanium/react-router';
import { css } from '@tanium/style-system';
import { ThemeFontWeights } from '@tanium/theme-data';

import {
  LinkHoverText,
  LinkText,
  PrimaryButtonText,
  SecondaryButtonText,
} from './themeDefinitions';

const noOutlineStyle = { ':focus': { outline: 'none' } };

type Anchor = JSX.IntrinsicElements['a'];
type ButtonType =
  | typeof VARIANT_PRIMARY
  | typeof VARIANT_PRIMARY_SUBDUED
  | typeof VARIANT_SECONDARY
  | typeof VARIANT_SECONDARY_SUBDUED;

// create-emotion types injects support for the css prop, but only certain TS environments (CLI)
// seem to realize this. Thus we use Omit instead of OmitStrict here to prevent the css prop from
// showing up in any generated type declaration files but not cause IDEs (which don't otherwise
// think the css prop exists) to complain.
export interface Props extends Omit<PropsWithoutRef<Anchor>, 'css'> {
  component?: React.ReactType;
  innerRef: Anchor['ref'];
  buttonVariant?: ButtonVariant;
  buttonSize?: ButtonSize;
}

interface WrapperProps extends OmitStrict<Props, 'innerRef'> {}

const InnerComponent = React.forwardRef(({ component: C = 'a', ...props }: WrapperProps, ref) => (
  <C ref={ref} {...props} />
));

/**
 * A styled link component.
 */
const Link = styled(InnerComponent, {
  shouldForwardProp: (p) => p !== 'usingMouse' && p !== 'innerRef',
})<Props & { usingMouse: boolean }>(
  // TODO: share or consolidate with same styles in coreui-mixins
  css({
    '&&': {
      color: LinkText,
    },
    textDecoration: 'none',
    cursor: 'pointer',
    ':hover': {
      color: LinkHoverText,
      textDecoration: 'underline',
    },
  }),
  ({ usingMouse }) => usingMouse && noOutlineStyle,
);

const ButtonStyledLink = styled(InnerComponent, {
  shouldForwardProp: (p) =>
    p !== 'usingMouse' && p !== 'innerRef' && p !== 'buttonVariant' && p !== 'buttonSize',
})<
  Props & {
    usingMouse: boolean;
    buttonVariant: ButtonType;
    disabled?: boolean;
  }
>(
  createBaseButtonCss,
  ({ buttonVariant }) =>
    css({
      fontWeight: ThemeFontWeights.Bold,
      '&&': {
        color: buttonVariant === 'primary' ? PrimaryButtonText : SecondaryButtonText,
        textDecoration: 'none',
      },
    }),
  ({ usingMouse }) => usingMouse && noOutlineStyle,
  ({ buttonVariant }) => variantCss(buttonVariant),
  ({ buttonSize }) => sizeVariant(buttonSize),
);

export default React.forwardRef<HTMLAnchorElement, OmitStrict<Props, 'innerRef'>>(
  ({ buttonVariant, buttonSize, component = 'a', ...props }, ref) => {
    const { isInternalRoute } = useRouter();
    const usingMouse = useUsingMouse();

    let { tabIndex } = props;
    if (typeof props.tabIndex !== 'number') {
      // If the consumer isn't overriding the tabIndex via Link prop, and
      // if this link lacks an href and is actually being rendered with an anchor tag, set tabIndex
      // explicitly to preserve the tabbable behavior of links with hrefs
      tabIndex = component !== 'a' || props.href ? undefined : 0;
    }

    let rel: string | undefined;
    if (props.target === '_blank') {
      const isHrefInternal = typeof props.href === 'string' && isInternalRoute(props.href);
      rel = isHrefInternal ? 'opener' : 'noreferrer noopener';
    }

    const linkAttributes = {
      tabIndex,
      rel,
    };

    if (!buttonVariant || buttonVariant === 'link') {
      return (
        <Link
          component={component}
          innerRef={ref}
          usingMouse={usingMouse}
          {...props}
          {...linkAttributes}
        />
      );
    }

    return (
      <ButtonStyledLink
        {...props}
        buttonVariant={buttonVariant}
        buttonSize={buttonSize}
        usingMouse={usingMouse}
        innerRef={ref}
        {...linkAttributes}
      />
    );
  },
);
