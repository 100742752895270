import React, { useCallback } from 'react';

type RefObjectToMutableRefObject<T> = T extends React.RefObject<infer U>
  ? React.MutableRefObject<U>
  : never;

/**
 * Combines multiple refs into a single aggregated ref to be supplied to an element.
 *
 * Some refs are declared as functions rather than an object that gets `current` set to the element reference.  When
 * you need to combine them into a single ref to supply to an element (which is the most an element accepts), aggregate
 * them with this hook.
 */
const useAggregatedRef = <T extends unknown>(refs: React.Ref<T>[]): React.RefCallback<T> => {
  const aggregatedRef = useCallback(
    (instance: T) => {
      refs.forEach((ref) => {
        if (typeof ref === 'function') {
          ref(instance);
        } else if (ref && 'current' in ref) {
          (ref as RefObjectToMutableRefObject<typeof ref>).current = instance;
        }
      });
    },
    [refs],
  );
  return aggregatedRef;
};

export default useAggregatedRef;
