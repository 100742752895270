import { keyframes } from '@tanium/react-emotion-9';

const spin = keyframes({
  from: {
    transform: 'rotate(0)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
});

export default `${spin} 720ms linear infinite`;
