import React, { useMemo } from 'react';

import ThemeContext from './ThemeContext';
import { ThemeContextType } from './types';

interface ThemeProviderProps extends ThemeContextType {
  children?: React.ReactNode;
}

/**
 * A component that can be used to provide theme information to other components in the
 * component tree.
 */
const ThemeProvider = ({ children, theme }: ThemeProviderProps) => {
  const contextValue = useMemo<ThemeContextType>(() => ({ theme }), [theme]);

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;
