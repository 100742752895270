import React from 'react';

import styled from '@tanium/react-emotion-9';

import { NavBarBaseProps } from '../NavBarBase';
import { isNavSectionParent } from '../types';
import NavBarLeafItem from './NavBarLeafItem';
import NavBarParentItem from './NavBarParentItem';

type Props = Pick<NavBarBaseProps, 'sections'>;

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
});

/**
 * "Open" NavBarSections do not support expansion.
 */
const NavBarSections = ({ sections }: Props) => (
  <List>
    {sections.map((section) => {
      const { name } = section;
      return (
        <li key={name}>
          {isNavSectionParent(section) ? (
            <>
              <NavBarParentItem {...section} />
              <NavBarSections sections={section.subSections} />
            </>
          ) : (
            <NavBarLeafItem {...section} />
          )}
        </li>
      );
    })}
  </List>
);

export default NavBarSections;
