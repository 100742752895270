import { Properties } from 'csstype';
import { layout } from 'styled-system';
import { SizesValue } from '@tanium/theme-data';
import { AnyStringLiteral } from './AnyStringLiteral';
import { CoreUiStyleType } from './types';

// Some props will map numbers in [0, 1) to a percentage. Unfortunately, there's no good way to
// allow this and restrict the use of numbers outside the allowed range, so we just type this
// permissively where applicable.
type SizesOrNumberValue = SizesValue | number;

export interface LayoutStyleProps {
  display?: CoreUiStyleType<Properties['display']>;
  height?: CoreUiStyleType<SizesOrNumberValue | AnyStringLiteral>;
  maxHeight?: CoreUiStyleType<SizesOrNumberValue | AnyStringLiteral>;
  maxWidth?: CoreUiStyleType<SizesOrNumberValue | AnyStringLiteral>;
  minHeight?: CoreUiStyleType<SizesOrNumberValue | AnyStringLiteral>;
  minWidth?: CoreUiStyleType<SizesOrNumberValue | AnyStringLiteral>;
  overflow?: CoreUiStyleType<Properties['overflow']>;
  overflowX?: CoreUiStyleType<Properties['overflowX']>;
  overflowY?: CoreUiStyleType<Properties['overflowY']>;
  size?: CoreUiStyleType<SizesOrNumberValue | AnyStringLiteral>;
  verticalAlign?: CoreUiStyleType<Properties['verticalAlign']>;
  width?: CoreUiStyleType<SizesOrNumberValue | AnyStringLiteral>;
}

export const layoutStylePropNames: (keyof LayoutStyleProps)[] = [
  'display',
  'height',
  'maxHeight',
  'maxWidth',
  'minHeight',
  'minWidth',
  'overflow',
  'overflowX',
  'overflowY',
  'size',
  'verticalAlign',
  'width',
];

export { layout };
