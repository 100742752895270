import { Properties } from 'csstype';
import { typography } from 'styled-system';

import { FontSizesValue, FontsValue, FontWeightsValue, LineHeightsValue } from '@tanium/theme-data';

import AnyStringLiteral from './AnyStringLiteral';
import { CoreUiStyleType } from './types';

export interface TypographyStyleProps {
  fontFamily?: CoreUiStyleType<FontsValue | AnyStringLiteral>;
  // TODO: remove number from fontSize when font sizes are added to theme object
  fontSize?: CoreUiStyleType<FontSizesValue | AnyStringLiteral | number>;
  fontStyle?: CoreUiStyleType<Properties['fontStyle']>;
  fontWeight?: CoreUiStyleType<FontWeightsValue | AnyStringLiteral>;
  letterSpacing?: CoreUiStyleType<Properties['letterSpacing']>;
  lineHeight?: CoreUiStyleType<LineHeightsValue | AnyStringLiteral>;
  textAlign?: CoreUiStyleType<Properties['textAlign']>;
}

export const typographyStylePropNames: (keyof TypographyStyleProps)[] = [
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'letterSpacing',
  'lineHeight',
  'textAlign',
];

export default typography;
