import * as React from 'react';

function SvgPin(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 436 436" ref={svgRef} {...props}>
      <path d="M0 0h436v436H0z" fill="none" />
      <path
        d="M143.62 243.315l50.035 49.3L14.927 434.052a9.025 9.025 0 01-12.668-12.688zM322.541 3.77a20.986 20.986 0 012.959 2.492L429.622 111.68a21.059 21.059 0 01-3.274 32.3l-106.834 71.465a138.112 138.112 0 01-42.632 137.507L82.3 158.368a138.114 138.114 0 01136.857-42.795L293.232 9.038a21.057 21.057 0 0129.309-5.268z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPin);
export default ForwardRef;
