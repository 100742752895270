import * as React from 'react';

function SvgTaniumWordmarkThemeAware(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg width={62} height={8} ref={svgRef} {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path d="M.27.447h7.734V2.21H5.15v5.33h-2.03V2.21H.27V.447M11.019 4.757h2.605l-1.32-2.664-1.285 2.664zm-.037-4.31h2.651l3.652 7.092H15.02l-.633-1.254h-4.13L9.662 7.54H7.385L10.982.447zM17.904.447h3.241l3.93 5.153h.018V.447h2.092v7.092h-3.241l-3.93-5.153h-.018V7.54h-2.092V.447M28.423 7.54h2.165V.446h-2.165zM31.826.447h2.033v4.05c0 .891.302 1.24 1.136 1.24h2.015c.833 0 1.136-.349 1.136-1.24V.447h2.033v4.515c0 1.744-.852 2.577-2.72 2.577h-2.913c-1.868 0-2.72-.833-2.72-2.577V.447M44.624.447l2.361 4.565L49.346.447h3.208v7.092h-2.032V2.445L47.766 7.54h-1.562l-2.737-5.094-.019 5.094h-2.032V.447zM56.936 3V.621h-.85V.137h2.275V.62h-.847V3h-.578zm1.79 0V.137h.866l.52 1.953.513-1.953h.867V3h-.537V.746L60.387 3h-.557L59.264.746V3h-.537z" />
      </g>
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTaniumWordmarkThemeAware);
export default ForwardRef;
