import { shadow } from 'styled-system';
import { ShadowsValue } from '@tanium/theme-data';
import { AnyStringLiteral } from './AnyStringLiteral';
import { CoreUiStyleType } from './types';

export interface ShadowStyleProps {
  boxShadow?: CoreUiStyleType<ShadowsValue | AnyStringLiteral>;
  textShadow?: CoreUiStyleType<ShadowsValue | AnyStringLiteral>;
}

export const shadowStylePropNames: (keyof ShadowStyleProps)[] = ['boxShadow', 'textShadow'];

export { shadow };
