import { i18n, StringMap, TOptions, WithT } from 'i18next';
import React, { useEffect } from 'react';
import { Namespace, Trans, useTranslation } from 'react-i18next';

import { useLocale, useWorkbenchLocale } from '@tanium/react-locale-context';

import { getI18nInstance, ResourceObject } from './i18nFactory';

// TFunction type that claims to always return a string, which instances with fallbackLng do.
export type TFunction = (key: string, options?: TOptions<StringMap> | string) => string;

// Cannot use the proper TransProps from react-i18next until we get to TypeScript 4.1
export interface TransProps<E extends Element = HTMLDivElement>
  extends React.HTMLProps<E>,
    Partial<WithT> {
  children?: React.ReactNode;
  components?: readonly React.ReactNode[] | { readonly [tagName: string]: React.ReactNode };
  count?: number;
  defaults?: string;
  i18n?: i18n;
  i18nKey?: string;
  ns?: Namespace;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parent?: string | React.ComponentType<any> | null; // used in React.createElement if not null
  tOptions?: {};
  values?: {};
  t?: TFunction;
}

export const generateTranslationTools = (packageName: string, resources: ResourceObject) => {
  // This state is stored here, outside a react component, the first time an @tanium package is used.
  // That way, we have 'global space per package', so i18n can create/init only once per package.
  const i18nInstance: i18n = getI18nInstance(packageName, resources);
  const translatedLocales = Object.keys(resources);

  const useTranslationTools = () => {
    const workbenchLocale = useWorkbenchLocale();

    // useTranslation has a useEffect which sets a listener on languageChanged to refresh the t function
    // So this line must appear before the useEffect below, since useEffects execute in line order.
    // eslint-disable-next-line @tanium/i18next-parser/translation-namespaces-are-string-literals
    const tools = useTranslation(packageName, { i18n: i18nInstance });

    useEffect(() => {
      // Bug in react-i18next's listeners --- on the initial render useEffect,
      // only the component that changes the language refreshes its t-function (should be all of them).
      // So in lieu of changing language x N rendered components,
      // compensate for the bug by just announcing that it changed.
      if (i18nInstance.language === workbenchLocale) {
        i18nInstance.emit('languageChanged');
        return;
      }
      i18nInstance.changeLanguage(workbenchLocale).catch((err) =>
        // eslint-disable-next-line no-console
        console.error(
          `Failed to change i18n language to ${workbenchLocale} in ${packageName}: `,
          err,
        ),
      );
    }, [workbenchLocale]);

    // In case there is something wrong with the architectural approach here, let's find out quickly.
    if (!i18nInstance.isInitialized) {
      throw new Error('i18n instance not initialized (translation is not working)');
    }

    // Pretend t always returns a string. Which it is type-guaranteed to do, because the i18nInstance has a fallbackLng.
    return {
      t: tools.t as TFunction,
      ready: tools.ready,
    };
  };

  const TransWrapper = ({ children, ...props }: TransProps) => {
    useTranslationTools();
    return (
      <Trans i18n={i18nInstance} {...props}>
        {children}
      </Trans>
    );
  };
  TransWrapper.displayName = 'TransWrapper';

  return {
    Trans: TransWrapper,

    useTranslation: useTranslationTools,

    useCalculateWorkbenchLocale: () => {
      const rawPreferredLocale = useLocale();
      const available = translatedLocales.includes(rawPreferredLocale as string);
      return (available ? rawPreferredLocale : 'en-US') as string;
    },
  };
};
