import React from 'react';

import { CoreUITypedComponent } from './types/CoreUITypedComponent';

export default function withCoreUIType<TCoreUIType extends string>(coreuiType: TCoreUIType) {
  return <TComponentProps, TRefType>(Component: React.ComponentType<TComponentProps>) => {
    const InnerComponent = (React.forwardRef<TRefType, TComponentProps>((props, ref) => (
      <Component ref={ref} {...props} />
    )) as unknown) as CoreUITypedComponent<TComponentProps, TCoreUIType>;

    InnerComponent.coreuiType = coreuiType;
    InnerComponent.displayName = `CoreUIType(${coreuiType})`;

    return InnerComponent;
  };
}
