import React, { useMemo } from 'react';

import ToastNotificationsContext from './ToastNotificationsContext';
import { ToastNotificationsContextType } from './types';

interface ToastNotificationsProviderProps extends ToastNotificationsContextType {
  children?: React.ReactNode;
}

/**
 * A component that can be used to provide toast notifications to other components in the
 * component tree.
 */
const ToastNotificationsProvider = ({
  children,
  notifications,
}: ToastNotificationsProviderProps) => {
  const contextValue = useMemo<ToastNotificationsContextType>(() => ({ notifications }), [
    notifications,
  ]);

  return (
    <ToastNotificationsContext.Provider value={contextValue}>
      {children}
    </ToastNotificationsContext.Provider>
  );
};

export default ToastNotificationsProvider;
