import React, { useMemo } from 'react';

import { WorkbenchDetailsContextType } from './types';
import WorkbenchDetailsContext from './WorkbenchDetailsContext';

interface WorkbenchDetailsProviderProps extends WorkbenchDetailsContextType {
  children?: React.ReactNode;
}

/**
 * A component that can be used to provide workbench details to other components in the
 * component tree.
 */
const WorkbenchDetailsProvider = ({
  children,
  homeUrl,
  icon,
  productName,
}: WorkbenchDetailsProviderProps) => {
  const contextValue = useMemo<WorkbenchDetailsContextType>(
    () => ({ homeUrl, icon, productName }),
    [homeUrl, icon, productName],
  );

  return (
    <WorkbenchDetailsContext.Provider value={contextValue}>
      {children}
    </WorkbenchDetailsContext.Provider>
  );
};

export default WorkbenchDetailsProvider;
