import mapValues from 'lodash/mapValues';

export type DateFormat =
  | 'formatHourOnly'
  | 'formatHourOnlyUTC'
  | 'formatDateOnly'
  | 'formatMonthOnly'
  | 'formatYearOnly'
  | 'formatShortDate'
  | 'formatShortTime'
  | 'formatShortDateAndTime'
  | 'formatShortDateAndMediumTime'
  | 'formatLongDateAndShortTime';
export type DateFormatters = Record<DateFormat, (date?: number | Date | undefined) => string>;

const buildIntlFormatter = (
  lng: string | string[] | undefined,
  options: Intl.DateTimeFormatOptions,
) => {
  const formatter = new Intl.DateTimeFormat(lng, options);
  return formatter.format.bind(formatter);
};

const intlFormatOptions: Record<DateFormat, Intl.DateTimeFormatOptions> = {
  formatHourOnly: { hour: 'numeric' },
  formatHourOnlyUTC: { hour: 'numeric', timeZone: 'UTC' },
  formatDateOnly: { dateStyle: 'medium' },
  formatMonthOnly: { month: 'long' },
  formatYearOnly: { year: 'numeric' },
  formatShortDate: { year: 'numeric', month: '2-digit', day: '2-digit' },
  formatShortTime: { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' },
  formatShortDateAndTime: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  },
  formatShortDateAndMediumTime: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  },
  formatLongDateAndShortTime: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  },
};

const getDateFormatters = (lng: string | string[] | undefined): DateFormatters =>
  mapValues(intlFormatOptions, (options) => buildIntlFormatter(lng, options));

export default getDateFormatters;
