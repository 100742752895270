import React, { useMemo } from 'react';

import HistoryContext from './HistoryContext';
import { HistoryContextType } from './types';

interface HistoryProviderProps extends HistoryContextType {
  children?: React.ReactNode;
}

/**
 * A component that can be used to provide history to other components in the
 * component tree.
 */
const HistoryProvider = ({ children, history }: HistoryProviderProps) => {
  const contextValue = useMemo<HistoryContextType>(() => ({ history }), [history]);

  return <HistoryContext.Provider value={contextValue}>{children}</HistoryContext.Provider>;
};

export default HistoryProvider;
