/**
 * @deprecated Use react-theme-definition instead
 */
enum ThemeShadows {
  InputFocus = 'InputFocus',
  Notification = 'Notification',
  OverflowVertical = 'OverflowVertical',
  Overlay1 = 'Overlay1',
  Overlay2 = 'Overlay2',
  Overlay3 = 'Overlay3',
  Overlay4 = 'Overlay4',
  PageScroll = 'PageScroll',
  Primary1 = 'Primary1',
  Primary3 = 'Primary3',

  // Enums only used in blue star theme
  Inner1 = 'Inner1',
  Inner2 = 'Inner2',
  Primary2 = 'Primary2',
  Primary4 = 'Primary4',
  NotificationPositive = 'NotificationPositive',
  NotificationNegative = 'NotificationNegative',
}

export default ThemeShadows;
