import { useContext } from 'react';

import MouseInteractionContext from './MouseInteractionContext';

/**
 * A hook to retrieve whether the user is currently using the mouse (as opposed to, e.g., a
 * keyboard).
 */
const useUsingMouse = () => {
  const { usingMouse } = useContext(MouseInteractionContext);

  return usingMouse;
};

export default useUsingMouse;
