import { getRgbString } from '@tanium/coreui-utils';

import { THEME_BLUESTAR } from '../../constants';
import generateRadii from '../../helpers/generateRadii';
import generateSpace from '../../helpers/generateSpace';
import ThemeColors from '../../ThemeColors';
import ThemeFonts from '../../ThemeFonts';
import ThemeFontWeights from '../../ThemeFontWeights';
import ThemeRadii from '../../ThemeRadii';
import ThemeShadows from '../../ThemeShadows';
import ThemeZIndices from '../../ThemeZIndices';
import { Theme } from '../../types';

// These z-indices are based on bootstrap values, used by the angular-based Tanium console.
const ZINDEX_MODAL_OVERLAY = 1040;
const ZINDEX_MODAL_CONTENT = ZINDEX_MODAL_OVERLAY + 10;
const ZINDEX_POPOVER = 1060;
const ZINDEX_NAVBAR_POPOVER = ZINDEX_POPOVER + 10;
const ZINDEX_WORKBENCH_HEADER = 5;

// Blue Star colors
const ACTIVE_PRIMARY = '#477FBA';
const ACTIVE_HOVER = '#3B93EE';
const FILL_BORDER_LEVEL_4 = '#CED7E2';
const FILL_BORDER_LEVEL_5 = '#ADB5C7';
const CONTENT_1 = '#292D38';
const STATUS_POSITIVE = '#1EB785';
const STATUS_NEGATIVE = '#E6444E';

/**
 * The Bluestar (Visual Refresh) Tanium theme.
 */
const bluestar: Theme = {
  name: THEME_BLUESTAR,
  colors: {
    // Blue Star and future values:
    [ThemeColors.ActivePrimary]: ACTIVE_PRIMARY,
    [ThemeColors.ActiveHover]: ACTIVE_HOVER,
    [ThemeColors.ActiveLight]: '#A4BEE1',
    [ThemeColors.Active2]: '#ECF4FF',
    [ThemeColors.Active3]: '#3E4B68',
    [ThemeColors.Content1]: CONTENT_1,
    [ThemeColors.Content2]: '#646A7E',

    [ThemeColors.Light0]: '#FFF',
    [ThemeColors.FillLevel1]: '#FCFDFE',
    [ThemeColors.FillLevel2]: '#F2F6FA',
    [ThemeColors.FillLevel3]: '#E2E8F0',
    [ThemeColors.FillBorderLevel4]: FILL_BORDER_LEVEL_4,
    [ThemeColors.FillBorderLevel5]: FILL_BORDER_LEVEL_5,
    [ThemeColors.FillBorderLevel6]: '#7F869D',

    [ThemeColors.ModuleIcon]: '#EB3330',

    [ThemeColors.StatusPositive]: STATUS_POSITIVE,
    [ThemeColors.StatusNegative]: STATUS_NEGATIVE,
    [ThemeColors.StatusWarning]: '#FA9529',
    [ThemeColors.StatusModerate]: '#FFC44F',
    [ThemeColors.StatusPending]: '#AB5DB0',

    [ThemeColors.WarningFill]: '#FFFCEE',
    [ThemeColors.SuccessFill]: '#E7F7F2',
    [ThemeColors.ErrorFill]: '#FDEDEE',

    [ThemeColors.HeaderLinkHover]: '#90C3E3',
    [ThemeColors.Dark0]: '#000',
    [ThemeColors.HeaderHighlight]: '#FB5C67',
    [ThemeColors.HeaderTier2]: '#333744',
    [ThemeColors.HeaderTier3]: '#393D4C',
    [ThemeColors.HeaderTier3Row]: '#424551',

    chart: {
      StatusTrue: '#1EB785',
      StatusFalse: '#E6444E',
      StatusYes: '#01A7EB',
      StatusNo: '#E6444E',
      StatusPositive: '#1EB785',
      StatusModerate: '#FFC852',
      StatusWarning: '#FA9529',
      StatusCritical: '#E6444E',
      ModerateFill: '#FFC44F',
      WarningFill: '#FA9529',
      CriticalFill: '#E6444E',
      StatusOptimized: '#0E966A',
      StatusAboveAverage: '#1EB785',
      StatusAverage: '#FFC852',
      StatusBelowAverage: '#FA9529',
      StatusNeedsImprovement: '#E6444E',
      StatusNeutral: '#4C5061',
      StatusPending: '#AB5DB0',
      StatusInformational: '#01A7EB',
      StatusOptimizedBackground: '#E7FCF5',
      StatusAboveAverageBackground: '#F4FFFB',
      StatusAverageBackground: '#FEFCF7',
      StatusBelowAverageBackground: '#FEF4EA',
      StatusNeedsImprovementBackground: '#FFF7F7',
      StatusNeutralBackground: '#F9FAFF',
      StatusPendingBackground: '#FBF4FC',
      StatusInformationalBackground: '#EFFAFF',
      C01: '#78D5FF',
      C02: '#3A95E0',
      C03: '#81D3C1',
      C04: '#30B99B',
      C05: '#8387FC',
      C06: '#514CA9',
      C07: '#FCDB70',
      C08: '#F2BB0F',
      C09: '#4C7291',
      C10: '#3B4F66',
      C11: '#F1726E',
      C12: '#B2403D',
      C13: '#4A9A8D',
      C14: '#0E4E44',
      C15: '#8B4EB6',
      C16: '#3F1A74',
      C17: '#91B7DD',
      C18: '#0B69A8',
      C19: '#E6B2A6',
      C20: '#8B450D',
      C21: '#F496B5',
      C22: '#A10035',
      C23: '#D7DD77',
      C24: '#998432',
      CoverageError: '#8E9294',
      CoverageInitializing: '#FA9529',
      CoverageNeedsAttention: '#E6444E',
      CoverageOptimal: '#1EB785',
      CoverageUnsupported: '#4C5061',
    },

    // Legacy values:
    [ThemeColors.Accent]: '#3E4B68',
    [ThemeColors.Action]: '#477FBA',
    [ThemeColors.ActionDisabled]: '#ADB5C7',
    [ThemeColors.ActionHover]: '#3B93EE',
    [ThemeColors.ActionPressed]: '#3B93EE',
    [ThemeColors.ActionSecondaryHover]: '#ECF4FF',
    [ThemeColors.ActionSecondaryPressed]: '#ECF4FF',
    [ThemeColors.Background]: '#FCFDFE',
    [ThemeColors.BrandBlack]: CONTENT_1,
    [ThemeColors.BrandRed]: '#EB3330',
    [ThemeColors.Border]: FILL_BORDER_LEVEL_4,
    [ThemeColors.DataGridRowHighlightColor]: '#FFFCEE',
    [ThemeColors.DataGridRowSelectedColor]: '#ECF4FF',
    [ThemeColors.Error]: STATUS_NEGATIVE,
    [ThemeColors.HeaderLinkBackground]: '#363948',
    [ThemeColors.InputBorder]: FILL_BORDER_LEVEL_4,
    [ThemeColors.InputLabel]: '#646A7E',
    [ThemeColors.InputText]: CONTENT_1,
    [ThemeColors.ListRowHighlighted]: '#FFFCEE',
    [ThemeColors.ListRowSelected]: '#ECF4FF',
    [ThemeColors.Pending]: '#AB5DB0',
    [ThemeColors.PrimaryButtonText]: '#FFF',
    [ThemeColors.Success]: STATUS_POSITIVE,
    [ThemeColors.Subtle]: '#646A7E',
    [ThemeColors.Subdued]: '#ECF4FF',
    [ThemeColors.Text]: CONTENT_1,
    [ThemeColors.TextPrimary]: CONTENT_1,
    [ThemeColors.TextSecondary]: '#646A7E',
    [ThemeColors.Tint1]: '#FCFDFE',
    [ThemeColors.Tint2]: '#F2F6FA',
    [ThemeColors.Tint3]: '#E2E8F0',
    [ThemeColors.Tint4]: FILL_BORDER_LEVEL_4,
    [ThemeColors.Warning]: '#FA9529',
  },
  radii: generateRadii([0, 4], { [ThemeRadii.Checkbox]: 2 }),
  space: generateSpace([0, 8, 16, 24, 32, 40, 48, 56, 64], 8),
  zIndices: {
    [ThemeZIndices.ModalContent]: ZINDEX_MODAL_CONTENT,
    [ThemeZIndices.ModalOverlay]: ZINDEX_MODAL_OVERLAY,
    [ThemeZIndices.Popover]: ZINDEX_POPOVER,
    [ThemeZIndices.NavBarPopover]: ZINDEX_NAVBAR_POPOVER,
    [ThemeZIndices.WorkbenchHeader]: ZINDEX_WORKBENCH_HEADER,
  },
  borderWidths: [0, 1],
  fontSizes: [], // [0, 10, 12, 14, 15, 16, 18, 20, 22, 28, 32, 36], // TODO
  fontWeights: {
    [ThemeFontWeights.Normal]: 400,
    [ThemeFontWeights.Medium]: 500,
    [ThemeFontWeights.Bold]: 700,
  },
  fonts: { [ThemeFonts.Main]: 'Inter, Arial, sans-serif' },
  lineHeights: [0, 1], // TODO should this correspond to fontSizes instead?
  sizes: [0, 8, 16, 24, 32, 40, 48, 56, 64],
  borders: ['none', '1px solid'],

  shadows: {
    [ThemeShadows.Inner1]: `inset 0 1px 3px ${ACTIVE_PRIMARY}`,
    [ThemeShadows.Inner2]: `inset 0 1px 3px ${ACTIVE_HOVER}`,
    [ThemeShadows.InputFocus]: `0 0 8px ${ACTIVE_PRIMARY}`,
    [ThemeShadows.Notification]: `0 2px 0 ${FILL_BORDER_LEVEL_4}`,
    [ThemeShadows.NotificationNegative]: `0 2px 0 ${getRgbString(STATUS_NEGATIVE, '25%')}`,
    [ThemeShadows.NotificationPositive]: `0 2px 0 ${getRgbString(STATUS_POSITIVE, '30%')}`,
    [ThemeShadows.OverflowVertical]: `inset 0 -7px 4px -4px  ${getRgbString(CONTENT_1, '19%')}`,
    [ThemeShadows.Overlay1]: `0 1px 3px ${FILL_BORDER_LEVEL_5}`,
    [ThemeShadows.Overlay2]: `0 4px 10px ${getRgbString(CONTENT_1, '30%')}`,
    [ThemeShadows.Overlay3]: `0 2px 3px ${getRgbString(CONTENT_1, '30%')}`,
    [ThemeShadows.Overlay4]: `0 4px 8px ${getRgbString(CONTENT_1, '10%')}`,
    [ThemeShadows.PageScroll]: `0 1px 1px ${getRgbString(FILL_BORDER_LEVEL_4, '30%')}`,
    [ThemeShadows.Primary1]: `0 1px 1px ${getRgbString(FILL_BORDER_LEVEL_4, '75%')}`,
    [ThemeShadows.Primary2]: `0 2px 1px ${getRgbString(FILL_BORDER_LEVEL_4, '75%')}`,
    [ThemeShadows.Primary3]: `0 2px 1px -1px ${getRgbString(FILL_BORDER_LEVEL_4, '75%')}`,
    [ThemeShadows.Primary4]: `0 1px 0 ${FILL_BORDER_LEVEL_4}`,
  },
};

export default bluestar;
