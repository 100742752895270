import { Properties } from 'csstype';
import { background } from 'styled-system';
import { CoreUiStyleType } from './types';

export interface BackgroundStyleProps {
  background?: CoreUiStyleType<Properties['background']>;
  backgroundImage?: CoreUiStyleType<Properties['backgroundImage']>;
  backgroundPosition?: CoreUiStyleType<Properties['backgroundPosition']>;
  backgroundRepeat?: CoreUiStyleType<Properties['backgroundRepeat']>;
  backgroundSize?: CoreUiStyleType<Properties['backgroundSize']>;
}

export const backgroundStylePropNames: (keyof BackgroundStyleProps)[] = [
  'background',
  'backgroundImage',
  'backgroundPosition',
  'backgroundRepeat',
  'backgroundSize',
];

export { background };
