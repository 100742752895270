import React from 'react';

import { NeverChildren } from '@tanium/coreui-utils';
import { Box } from '@tanium/react-box';

import { NavBarHoverFill, NavBarHoverShadow } from '../../themeDefinitions';
import { NavSectionDetailed } from '../types';
import NavBarSections from './NavBarSections';

interface Props extends NeverChildren {
  /**
   * The sections (i.e., items and optional subsections) to display in the nav
   * bar.
   */
  sections: NavSectionDetailed[];
}

const NavBarOpen = ({ sections }: Props) => (
  <Box
    bg={NavBarHoverFill}
    borderRadius={1}
    boxShadow={NavBarHoverShadow}
    maxHeight="445px"
    maxWidth="300px"
    minWidth="160px"
    overflowY="auto"
  >
    <NavBarSections sections={sections} />
  </Box>
);

export default NavBarOpen;
