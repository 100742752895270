/**
 * @deprecated Use react-theme-definition instead
 */
enum ThemeColors {
  // Blue Star and future enums:
  ActivePrimary = 'ActivePrimary',
  ActiveHover = 'ActiveHover',
  ActiveLight = 'ActiveLight',
  Active2 = 'Active2',
  Active3 = 'Active3',
  Content1 = 'Content1',
  Content2 = 'Content2',

  Light0 = 'Light0',
  FillLevel1 = 'FillLevel1',
  FillLevel2 = 'FillLevel2',
  FillLevel3 = 'FillLevel3',
  FillBorderLevel4 = 'FillBorderLevel4',
  FillBorderLevel5 = 'FillBorderLevel5',
  FillBorderLevel6 = 'FillBorderLevel6',

  ModuleIcon = 'ModuleIcon',

  StatusPositive = 'StatusPositive',
  StatusNegative = 'StatusNegative',
  StatusWarning = 'StatusWarning',
  StatusModerate = 'StatusModerate',
  StatusPending = 'StatusPending',

  WarningFill = 'WarningFill',
  SuccessFill = 'SuccessFill',
  ErrorFill = 'ErrorFill',

  HeaderLinkHover = 'HeaderLinkHover',
  Dark0 = 'Dark0',
  HeaderHighlight = 'HeaderHighlight',
  HeaderTier2 = 'HeaderTier2',
  HeaderTier3 = 'HeaderTier3',
  HeaderTier3Row = 'HeaderTier3Row',

  // Legacy color enums; see colorMap.ts of coreui-codemods for
  // approximate mapping to new enums.
  Accent = 'accent',
  Action = 'action',
  ActionDisabled = 'actionDisabled',
  ActionHover = 'actionHover',
  ActionPressed = 'actionPressed',
  ActionSecondaryHover = 'actionSecondaryHover',
  ActionSecondaryPressed = 'actionSecondaryPressed',
  Background = 'background',
  Border = 'border',
  BrandBlack = 'brandBlack',
  BrandRed = 'brandRed',
  /**
   * @deprecated Use ListRowHighlighted instead
   */
  DataGridRowHighlightColor = 'dataGridRowHighlightColor',
  /**
   * @deprecated Use ListRowSelected instead
   */
  DataGridRowSelectedColor = 'dataGridRowSelectedColor',
  Error = 'error',
  HeaderLinkBackground = 'HeaderLinkBackground',
  InputBorder = 'inputBorder',
  InputLabel = 'inputLabel',
  InputText = 'inputText',
  ListRowHighlighted = 'listRowHighlighted',
  ListRowSelected = 'listRowSelected',
  Pending = 'pending',
  PrimaryButtonText = 'PrimaryButtonText',
  Success = 'success',
  Subtle = 'subtle',
  Subdued = 'subdued',
  Text = 'text',
  TextPrimary = 'TextPrimary',
  TextSecondary = 'TextSecondary',
  Tint1 = 'tint1',
  Tint2 = 'tint2',
  Tint3 = 'tint3',
  Tint4 = 'tint4',
  Warning = 'warning',
}

export default ThemeColors;
