import { useContext } from 'react';

import WorkbenchDetailsContext from './WorkbenchDetailsContext';

/**
 * A hook to retrieve workbench details.
 */
const useWorkbenchDetails = () => {
  const workbenchDetails = useContext(WorkbenchDetailsContext);

  return workbenchDetails;
};

export default useWorkbenchDetails;
