import styled from '@tanium/react-emotion-9';

export default styled('div', { shouldForwardProp: (p) => !['fill', 'innerRef'].includes(p) })<{
  fill: boolean;
}>(
  ({ fill }) =>
    fill && {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
);
