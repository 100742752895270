import { SpaceArray, SpaceType } from '../types';

/**
 * Generates the `space` component of a Theme given a set of initial (major) space values and the
 * base multiple underlying the theme's spacing.
 */
export default (spaceValues: SpaceArray, multiple: number): SpaceType => {
  const spaceValuesMinor = spaceValues.map((v) => v / 2) as SpaceArray;
  return {
    ...spaceValues,
    multiple,
    minor: spaceValuesMinor,
    bordered: {
      ...(spaceValues.map((v) => Math.max(0, v - 1)) as SpaceArray),
      minor: spaceValuesMinor.map((v) => Math.max(0, v - 1)) as SpaceArray,
    },
  } as SpaceType;
};
