import React, { useMemo } from 'react';

import ToastNotificationsControlContext from './ToastNotificationsControlContext';
import { ToastNotificationsControlContextType } from './types';

interface ToastNotificationsControlProviderProps extends ToastNotificationsControlContextType {
  children?: React.ReactNode;
}

/**
 * A component that can be used to provide toast notifications control to other components in the
 * component tree.
 */
const ToastNotificationsControlProvider = ({
  children,
  showNotification,
}: ToastNotificationsControlProviderProps) => {
  const contextValue = useMemo<ToastNotificationsControlContextType>(() => ({ showNotification }), [
    showNotification,
  ]);

  return (
    <ToastNotificationsControlContext.Provider value={contextValue}>
      {children}
    </ToastNotificationsControlContext.Provider>
  );
};

export default ToastNotificationsControlProvider;
