import { FC } from 'react';

import { AnyProps, devWarning, withCoreUIType } from '@tanium/coreui-utils';
import { ThemeDefinition } from '@tanium/react-theme-definition';

export const STACK_CHILD_COREUITYPE = 'StackChild';

export type Overflow = 'hidden' | 'visible';

/**
 * Props that can be set on a `StackChild` component directly that don't have
 * `item*` equivalent default props on the `Stack` component. (Props like
 * `alignItems` that correspond to CSS properties only, but don't actually
 * affect the props set on rendered child component, don't count.)
 */
export interface StackChildOnlyProps {
  /**
   * The `align-self` value for this child.
   */
  alignSelf?: string;
}

/**
 * Props that apply to `StackChild` components and are inherited by default from
 * `item*` props on a parent `Stack` component.
 */
export interface StackChildInheritableProps {
  /**
   * The `flex-basis` for this child.
   */
  basis?: number | string;
  /**
   * The component to use to render this child. Any unrecognized props are
   * spread to this component.
   */
  component?: React.ReactType;
  /**
   * Whether this item should be followed by a divider or not. Note that the
   * last child never has a divider.
   */
  divider?: boolean;
  /**
   * The color of the divider following this item, if a divider is rendered. This
   * prop is either a ThemeDefinition to support multiple theme colors, or just a string literal.
   */
  dividerColor?: ThemeDefinition | string;
  /**
   * The `flex-grow` value for this child.
   */
  grow?: number;
  /**
   * The `overflow` value for this child.
   */
  overflow?: Overflow;
  /**
   * The `flex-shrink` value for this child.
   */
  shrink?: number;
}

/**
 * Props that apply to `Stack` children that are determined (or calculated) by
 * the `Stack` component, and aren't directly set by consumers of `StackChild`.
 */
export interface StackChildInternalProps {
  spacing: number;
  spacingSide: 'Right' | 'Bottom';
}

/**
 * Props that can be set by consumers of the `StackChild` component.
 */
export interface Props extends StackChildInheritableProps, StackChildOnlyProps, AnyProps {}

/**
 * A component that modifies the behavior of a immediate child of a `Stack`
 * component. This component should only be used as an immediate child of a
 * `Stack` component.
 */
const StackChild: FC<Props> = () => {
  devWarning(
    false,
    'StackChild component is being rendered unexpectedly. StackChild components should only be used as direct children of a Stack component.',
  );
  return null;
};

export default withCoreUIType(STACK_CHILD_COREUITYPE)(StackChild);
