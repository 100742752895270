import { Properties } from 'csstype';
import { flexbox } from 'styled-system';
import { CoreUiStyleType } from './types';

export interface FlexboxStyleProps {
  alignContent?: CoreUiStyleType<Properties['alignContent']>;
  alignItems?: CoreUiStyleType<Properties['alignItems']>;
  alignSelf?: CoreUiStyleType<Properties['alignSelf']>;
  flex?: CoreUiStyleType<Properties['flex']>;
  flexDirection?: CoreUiStyleType<Properties['flexDirection']>;
  flexGrow?: CoreUiStyleType<Properties['flexGrow']>;
  flexShrink?: CoreUiStyleType<Properties['flexShrink']>;
  flexWrap?: CoreUiStyleType<Properties['flexWrap']>;
  justifyContent?: CoreUiStyleType<Properties['justifyContent']>;
  order?: CoreUiStyleType<Properties['order']>;
}

export const flexboxStylePropNames: (keyof FlexboxStyleProps)[] = [
  'alignContent',
  'alignItems',
  'alignSelf',
  'flex',
  'flexDirection',
  'flexGrow',
  'flexShrink',
  'flexWrap',
  'justifyContent',
  'order',
];

export { flexbox };
