import { defineVariant } from '@tanium/style-system';
import { ThemeSpace } from '@tanium/theme-data';

import { ButtonSize } from './types';

export default defineVariant<ButtonSize>(
  {
    sm: {
      fontSize: 12,
      py: ThemeSpace.bordered.minor[1],
      px: ThemeSpace.bordered[1],
    },
    lg: {
      fontSize: 14,
      p: ThemeSpace.bordered[1],
    },
  },
  { defaultVariant: 'lg' },
);
