import { generateTranslationTools } from '@tanium/react-i18n';

import german from './static/locales/de-DE/react-workbench.json';
import english from './static/locales/en-US/react-workbench.json';
import french from './static/locales/fr-FR/react-workbench.json';
import japanese from './static/locales/ja-JP/react-workbench.json';
import korean from './static/locales/ko-KR/react-workbench.json';

export const { Trans, useTranslation } = generateTranslationTools('react-workbench', {
  'de-DE': { 'react-workbench': german },
  'en-US': { 'react-workbench': english },
  'fr-FR': { 'react-workbench': french },
  'ja-JP': { 'react-workbench': japanese },
  'ko-KR': { 'react-workbench': korean },
});
