import throttle from 'lodash/throttle';
import uniqueId from 'lodash/uniqueId';
import React, {
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { animated, useTransition } from 'react-spring';

import {
  ToastNotificationsControlProvider,
  ToastNotificationsProvider,
  ToastNotificationType,
} from '@tanium/react-toast-notifications-context';
import {
  NOTIFICATION_REMOVAL_THROTTLE_INTERVAL,
  ToastNotification,
} from '@tanium/react-toast-notifications-display';
import { useFocusInOut } from '@tanium/react-use-focus-in-out';

import useRefCount from './useRefCount';

export interface ToastNotification extends ToastNotificationType {
  key: string;
}

interface ManagerState {
  notifications: readonly ToastNotification[];
  removalQueue: readonly ToastNotification['key'][];
}

type ActionDefinition<T, P = undefined> = {
  type: T;
} & (P extends undefined
  ? {}
  : {
      payload: P;
    });

type Action =
  | ActionDefinition<'ADD_NOTIFICATION', ToastNotification>
  | ActionDefinition<'REMOVE_NOTIFICATION', ToastNotification['key']>
  | ActionDefinition<'QUEUE_REMOVE_NOTIFICATION', ToastNotification['key']>
  | ActionDefinition<'CLEAR_REMOVAL_QUEUE'>
  | ActionDefinition<'PROCESS_REMOVAL_QUEUE'>;

const initialState = {
  notifications: [],
  removalQueue: [],
};

const reducer: Reducer<ManagerState, Action> = (state, action) => {
  switch (action.type) {
    case 'ADD_NOTIFICATION':
      return { ...state, notifications: [...state.notifications, action.payload] };
    case 'REMOVE_NOTIFICATION':
      return {
        ...state,
        notifications: state.notifications.filter((x) => x.key !== action.payload),
      };
    case 'QUEUE_REMOVE_NOTIFICATION':
      if (
        !state.removalQueue.some((key) => key === action.payload) &&
        state.notifications.some((x) => x.key === action.payload)
      ) {
        return { ...state, removalQueue: state.removalQueue.concat(action.payload) };
      }
      return state;
    case 'CLEAR_REMOVAL_QUEUE':
      return { ...state, removalQueue: [] };
    case 'PROCESS_REMOVAL_QUEUE':
      if (state.removalQueue.length > 0) {
        const keyToRemove = state.removalQueue[0];
        return {
          ...state,
          notifications: state.notifications.filter((x) => x.key !== keyToRemove),
          removalQueue: state.removalQueue.slice(1),
        };
      }
      return state;
    default:
      return state;
  }
};

const ToastNotificationsWrapper = ({ children }: { children?: React.ReactNode }) => {
  const [{ notifications, removalQueue }, dispatch] = useReducer(reducer, initialState);
  const refMap = useRef(new WeakMap());

  const [isTimerRunning, setIsTimerRunning] = useState(true);

  const transitions = useTransition(notifications, (n) => n.key, {
    from: { opacity: 0, paddingBottom: 8, transform: 'translate3d(0,0,0)' },
    enter: { opacity: 1 },
    update: (item) => ({ height: refMap.current.get(item).offsetHeight }),
    leave: (item) => ({
      opacity: 0,
      paddingBottom: 0,
      height: 0,
      transform: `translate3d(0,-${refMap.current.get(item).offsetHeight}px,0)`,
    }),
  });

  const showNotification = useCallback((notification: ToastNotificationType) => {
    dispatch({ type: 'ADD_NOTIFICATION', payload: { ...notification, key: uniqueId('toast') } });
  }, []);

  const queueRemoveNotification = (key: ToastNotification['key']) => {
    dispatch({ type: 'QUEUE_REMOVE_NOTIFICATION', payload: key });
  };

  const throttledProcessRemovalQueue = useRef(
    throttle(
      () => {
        dispatch({ type: 'PROCESS_REMOVAL_QUEUE' });
      },
      NOTIFICATION_REMOVAL_THROTTLE_INTERVAL,
      // Disable leading-edge call. lodash throttle seems to have a bug where the throttled function
      // is called more often than expected if both leading- and trailing-edge calls are enabled. We
      // can mitigate this issue by disabling leading-edge calls and reducing the timer for
      // notifications by the throttle interval, which should result in the same effective lifetime.
      // see: https://github.com/lodash/lodash/issues/3051
      { leading: false },
    ),
  );

  useEffect(() => {
    if (removalQueue.length) {
      throttledProcessRemovalQueue.current();
    }
  }, [removalQueue]);

  const notificationComponents = transitions.map(({ props, item: notification, key }) => (
    <animated.div
      ref={(ref) => {
        if (ref) {
          refMap.current.set(notification, ref);
        }
      }}
      key={key}
      style={{ ...props }}
    >
      <ToastNotification
        type={notification.type}
        isTimerRunning={isTimerRunning}
        onTimerExpired={() => {
          queueRemoveNotification(notification.key);
        }}
        onDismiss={() => {
          dispatch({ type: 'REMOVE_NOTIFICATION', payload: notification.key });
        }}
        data-test-id={notification.testId}
        nestedProps={{
          notificationDismissButton: {
            'data-test-id': notification.dismissButtonTestId,
          },
        }}
      >
        {notification.contents}
      </ToastNotification>
    </animated.div>
  ));

  const pauseTimers = useCallback(() => {
    setIsTimerRunning(false);
    throttledProcessRemovalQueue.current.cancel();
    dispatch({ type: 'CLEAR_REMOVAL_QUEUE' });
  }, []);

  const resumeTimers = useCallback(() => {
    setIsTimerRunning(true);
  }, []);

  const { increment: requestPause, decrement: requestResume } = useRefCount({
    onCountZero: resumeTimers,
    onCountExceedZero: pauseTimers,
  });

  const wrapperRef = useFocusInOut({
    handleFocusIn: requestPause,
    handleFocusOut: requestResume,
  });

  const wrappedNotifications = useMemo(
    () => (
      <div ref={wrapperRef} onMouseEnter={requestPause} onMouseLeave={requestResume}>
        {notificationComponents}
      </div>
    ),
    [notificationComponents, requestPause, requestResume, wrapperRef],
  );

  return (
    <ToastNotificationsProvider notifications={wrappedNotifications}>
      <ToastNotificationsControlProvider showNotification={showNotification}>
        {children}
      </ToastNotificationsControlProvider>
    </ToastNotificationsProvider>
  );
};

export default ToastNotificationsWrapper;
