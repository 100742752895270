import createEmotion from 'create-emotion';
import createEmotionStyled from 'create-emotion-styled';
import React from 'react';

const generateRandomString = () => {
  const letters = 'abcdefghijklmnopqrstuvwxyz';

  let randomString = '';
  for (let i = 0; i < 8; i += 1) {
    randomString += letters[Math.floor(Math.random() * letters.length)];
  }

  return randomString;
};

// Note: emotion disallows non-letters (except for `-`) in its key
const randomKey = generateRandomString();

// See:
// https://5bb1495273f2cf57a2cf39cc--emotion.netlify.com/docs/create-emotion#multiple-instances-in-a-single-app-example
const customEmotion = createEmotion(
  {},
  {
    key: randomKey,
  },
);

export default createEmotionStyled(customEmotion, React);

// eslint-disable-next-line @typescript-eslint/unbound-method
export const {
  flush,
  hydrate,
  cx,
  merge,
  getRegisteredStyles,
  injectGlobal,
  keyframes,
  css,
  sheet,
  caches,
} = customEmotion;
