import React from 'react';

import { NeverChildren } from '@tanium/coreui-utils';
import styled from '@tanium/react-emotion-9';
import { useRandomId } from '@tanium/react-use-random-id';

export interface Props extends NeverChildren {
  className?: string;
  minOpacity?: number;
  size?: number;
  strokeColor?: string;
  strokeWidth?: number;
}

const Svg = styled('svg')({ display: 'block' });

const SpinnerRing = ({
  className,
  minOpacity = 0.2,
  size = 32,
  strokeColor = 'currentColor',
  strokeWidth = Math.log2(size / 2),
}: Props) => {
  const clipPathId = useRandomId();
  const gradientId = useRandomId();

  const radius = (size - strokeWidth) / 2;

  return (
    <Svg width={size} height={size} className={className}>
      <defs>
        <clipPath id={clipPathId}>
          <rect x={0} y={size / 2} width={size} height={size / 2} />
        </clipPath>
        <linearGradient id={gradientId}>
          <stop offset={0} stopColor={strokeColor} />
          <stop offset={(size - strokeWidth) / size} stopColor={strokeColor} stopOpacity={0} />
        </linearGradient>
      </defs>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        fill="none"
        opacity={minOpacity}
      />
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke={`url(#${gradientId})`}
        strokeWidth={strokeWidth}
        fill="none"
        clipPath={`url(#${clipPathId})`}
      />
    </Svg>
  );
};

export default SpinnerRing;
