import isDate from 'lodash/isDate';

import getDateFormatters, { DateFormatters } from './dateFormatters';
import getListFormatters, { ListFormatters } from './listFormatters';
import getNumericFormatters, { NumericFormatters } from './numericFormatters';

let numericFormatters: NumericFormatters;
let listFormatters: ListFormatters;
let dateFormatters: DateFormatters;
let formattersLocale: string;

export const setFormattersLocale = (lng: string) => {
  numericFormatters = getNumericFormatters(lng);
  listFormatters = getListFormatters(lng);
  dateFormatters = getDateFormatters(lng);
  formattersLocale = lng;
};

export const formatter = (value: unknown, format: string | undefined, lng: string | undefined) => {
  if (lng === undefined)
    throw new Error(
      'Current locale must be specified as the language for interpolation formatting',
    );
  if (lng !== formattersLocale) {
    setFormattersLocale(lng);
  }
  switch (format) {
    case 'INTEGER':
      if (typeof value !== 'number') throw new Error(`Can't apply a numeric format to ${value}`);
      return numericFormatters.formatInteger(value);
    case 'MILLISECONDS':
      if (typeof value !== 'number') throw new Error(`Can't apply a numeric format to ${value}`);
      return numericFormatters.formatMilliseconds(value);
    case 'DECIMAL_TWO_DIGITS':
      if (typeof value !== 'number') throw new Error(`Can't apply a numeric format to ${value}`);
      return numericFormatters.formatDecimalTwoDigits(value);
    case 'BYTES':
      if (typeof value !== 'number') throw new Error(`Can't apply a numeric format to ${value}`);
      return formatBytes(value);
    case 'SHORT_UNIT_LIST': // Example: ['foo', 'bar', 'baz'] => 'foo, bar, baz'
      if (!Array.isArray(value)) throw new Error(`Can't apply an array format to ${value}`);
      return listFormatters.formatShortUnitList(value);
    case 'NARROW_UNIT_LIST': // Example: ['foo', 'bar', 'baz'] => 'foo bar baz'
      if (!Array.isArray(value)) throw new Error(`Can't apply an array format to ${value}`);
      return listFormatters.formatNarrowUnitList(value);
    case 'LONG_AND_LIST': // Example: ['foo', 'bar', 'baz'] => 'foo, bar, and baz'
      if (!Array.isArray(value)) throw new Error(`Can't apply an array format to ${value}`);
      return listFormatters.formatLongAndList(value);
    case 'LONG_OR_LIST': // Example: ['foo, 'bar', 'baz'] => 'foo, bar, or baz'
      if (!Array.isArray(value)) throw new Error(`Can't apply an array format to ${value}`);
      return listFormatters.formatLongOrList(value);
    // Date examples all use
    // -- a new Date() taken at Wed Nov 24 2021 12:33:08 GMT-0500 (Eastern Standard Time)
    // -- workbenchLocale en-US
    case 'HOUR_ONLY': // Example: '12 PM'
      if (!isDate(value)) throw new Error(`Can't apply a date format to ${value}`);
      return dateFormatters.formatHourOnly(value);
    case 'HOUR_ONLY_UTC': // Example: '5 PM'
      if (!isDate(value)) throw new Error(`Can't apply a date format to ${value}`);
      return dateFormatters.formatHourOnlyUTC(value);
    case 'MONTH_ONLY': // Example: 'December'
      if (!isDate(value)) throw new Error(`Can't apply a date format to ${value}`);
      return dateFormatters.formatMonthOnly(value);
    case 'YEAR_ONLY': // Example: '2021'
      if (!isDate(value)) throw new Error(`Can't apply a date format to ${value}`);
      return dateFormatters.formatYearOnly(value);
    case 'DATE_ONLY': // Example: 'Nov 24, 2021'
      if (!isDate(value)) throw new Error(`Can't apply a date format to ${value}`);
      return dateFormatters.formatDateOnly(value);
    case 'SHORT_DATE': // Example: '11/24/2021'
      if (!isDate(value)) throw new Error(`Can't apply a date format to ${value}`);
      return dateFormatters.formatShortDate(value);
    case 'SHORT_TIME': // Example: '12:33 PM EST'
      if (!isDate(value)) throw new Error(`Can't apply a date format to ${value}`);
      return dateFormatters.formatShortTime(value);
    case 'SHORT_DATE_AND_TIME': // Example: '11/24/2021, 12:33 PM EST'
      if (!isDate(value)) throw new Error(`Can't apply a date format to ${value}`);
      return dateFormatters.formatShortDateAndTime(value);
    case 'SHORT_DATE_AND_MEDIUM_TIME': // Example: '11/24/2021, 12:33:08 PM'
      if (!isDate(value)) throw new Error(`Can't apply a date format to ${value}`);
      return dateFormatters.formatShortDateAndMediumTime(value);
    case 'LONG_DATE_AND_SHORT_TIME': // Example: 'September 24, 2021, 12:33 PM'
      if (!isDate(value)) throw new Error(`Can't apply a date format to ${value}`);
      return dateFormatters.formatLongDateAndShortTime(value);
    default:
      if (format !== undefined) throw new Error(`The format '${format}' does not exist`);
      if (isDate(value))
        throw new Error(`Interpolated dates must have a format specified: ${value}`);
      if (typeof value === 'number')
        throw new Error(`Interpolated numbers must have a format specified: ${value}`);
      if (typeof value !== 'string')
        throw new Error(
          `Only strings can be interpolated when there is no format specified. Received: ${value}`,
        );
      return String(value);
  }
};

const formatBytes = (bytes: number) => {
  if (!bytes) return numericFormatters.formatBytes(0);

  const k = 1024;
  const m = Math.min(5, Math.floor(Math.log(bytes) / Math.log(k)));
  const n = Math.round((bytes / k ** m) * 100) / 100;
  switch (m) {
    case 0:
      return numericFormatters.formatBytes(n);
    case 1:
      return numericFormatters.formatKilobytes(n);
    case 2:
      return numericFormatters.formatMegabytes(n);
    case 3:
      return numericFormatters.formatGigabytes(n);
    case 4:
      return numericFormatters.formatTerabytes(n);
    // Intl only supports up to Petabytes for byte units
    // so all larger values will be represented as Petabytes
    default: {
      return numericFormatters.formatPetabytes(n);
    }
  }
};
