import * as React from 'react';

function SvgTanium(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 16 16" ref={svgRef} {...props}>
      <path
        d="M4.957 6.8v2.5h4.212v6.622A8.159 8.159 0 018.045 16 8 8 0 1116 6.8zM12 15.334v-6h4a8.625 8.625 0 01-4 6z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTanium);
export default ForwardRef;
