import { useContext } from 'react';

import ThemeContext from './ThemeContext';

/**
 * A hook to retrieve the current theme.
 */
const useTheme = () => {
  const { theme } = useContext(ThemeContext);

  return theme;
};

export default useTheme;
