import { THEME_DARKMODE } from '../../constants';
import { Theme } from '../../types';
import bluestar from '../bluestar';

const darkmode: Theme = {
  ...bluestar,
  name: THEME_DARKMODE,
};

export default darkmode;
