import sxShouldForwardProp from '@styled-system/should-forward-prop';
import React, { FC } from 'react';
import { createAnyComponent } from '@tanium/coreui-utils';
import styled from '@tanium/react-emotion-9';
import { useUsingMouse } from '@tanium/react-mouse-interaction-context';
import { css } from '@tanium/style-system';
import { sizeVariant } from './sizeVariant';
import { ButtonBaseProps } from './types';

const noOutlineStyle = { ':focus': { outline: 'none' } };

/**
 * Creates the base button CSS shared across all styled buttons
 */
export const createBaseButtonCss = css({
  verticalAlign: 'middle',
  touchAction: 'manipulation',
  whiteSpace: 'nowrap',
  userSelect: 'none',
  lineHeight: '16px',
  display: 'inline-block',
  m: 0,
  textAlign: 'center',
  border: 1,
  borderColor: 'transparent',
  borderRadius: 1,
});

/**
 * Creates a styled component of `renderType` that derives its style
 * from `createBaseButtonCss`
 *
 * @param renderType - React component or HTML element to be rendered
 */
export const createStyledButtonBase = (renderType: React.ElementType) =>
  styled(createAnyComponent(renderType), {
    shouldForwardProp: (p) => sxShouldForwardProp(p) || p === 'as',
  })<ButtonBaseProps & { usingMouse: boolean }>(
    createBaseButtonCss,
    ({ size }) => sizeVariant(size),
    ({ usingMouse }) => usingMouse && noOutlineStyle,
    ({ disabled }) =>
      disabled
        ? {
            // bootstrap override
            '&&': { cursor: 'not-allowed' },
          }
        : {
            cursor: 'pointer',
          },
  );

const StyledButton = createStyledButtonBase('button');

const ButtonBaseInner = React.forwardRef<HTMLElement, ButtonBaseProps>(
  ({ as = 'button', size = 'lg', disabled = false, ...props }, ref) => {
    const usingMouse = useUsingMouse();

    return (
      <StyledButton
        innerRef={ref}
        as={as}
        disabled={disabled}
        {...props}
        usingMouse={usingMouse}
        size={size}
      />
    );
  },
);

// This type cast provides better typings and Intellisense for consumers
export const ButtonBase = ButtonBaseInner as FC<ButtonBaseProps & { ref?: React.Ref<HTMLElement> }>;
