import React, { isValidElement, ReactChildren } from 'react';
import { AnyProps, devWarning, isValidElementOfType } from '@tanium/coreui-utils';
import { Box } from '@tanium/react-box';
import { css, cx } from '@tanium/react-emotion-9';
import { useTheme } from '@tanium/react-theme-context';
import { ThemeDefinition } from '@tanium/react-theme-definition';
import { Theme } from '@tanium/theme-data';
import {
  Overflow,
  STACK_CHILD_COREUITYPE,
  StackChild,
  StackChildInheritableProps,
  StackChildInternalProps,
  StackChildProcessedProps,
  Props as StackChildProps,
} from './StackChild';
import { BORDER_WIDTH, getMargin, getPadding, getStackChildStyle } from './style';

export type FlexDirection = 'column' | 'row';
type ReactChildrenArrayItem = ReturnType<ReactChildren['toArray']>[number];

export interface Props extends AnyProps {
  /**
   * The `align-items` value.
   */
  alignItems?: string;
  /**
   * Class to apply to the root element.
   */
  className?: string;
  /**
   * Component to render as the root element. Defaults to `Box`.
   */
  component?: React.ReactType;
  /**
   * The `flex-direction` value. Defaults to `row`; `column` is also supported.
   */
  direction?: FlexDirection;
  /**
   * Whether the contents should be automatically sized to fill the available
   * space, giving each child equal space. This is done by setting the default
   * `flex-basis` for each child; if you want more control or flexibility, set
   * the basis (or grow value) for each child individually.
   *
   * If set to `true`, each child is sized equally based on the total number of
   * children (determined automatically). To override the number of children
   * used for this calculation, pass a number for this prop instead.
   *
   * If set, this prop takes priority over `itemBasis`.
   */
  fill?: boolean | number;
  /**
   * Whether the component should be rendered with `display: inline-flex`
   * instead of `display: flex`.
   */
  inline?: boolean;
  /**
   * The default `flex-basis` for each child. This value is ignored if `fill` is
   * set.
   */
  itemBasis?: number | string;
  /**
   * The default component used to render each child. This component will be
   * used to wrap each child. Defaults to `Box'.
   */
  itemComponent?: React.ReactType;
  /**
   * The color to use for item dividers, if item dividers are rendered. This prop is either a
   * ThemeDefinition to support multiple theme colors, or just a string literal.
   */
  itemDividersColor?: ThemeDefinition | string;
  /**
   * Whether to draw dividers between child elements. Note that this prop does not affect the total
   * spacing between items; i.e., items will still be spaced the same distance from each other with
   * dividers shown as they would be if dividers were not shown.
   */
  itemDividers?: boolean;
  /**
   * The default `flex-grow` value for each child. Defaults to 0.
   */
  itemGrow?: number;
  /**
   * The default `overflow` value for each child.
   */
  itemOverflow?: Overflow;
  /**
   * The default `flex-shrink` value for each child. Defaults to 1.
   */
  itemShrink?: number;
  /**
   * The amount of space to render between children. This value is multiplied by the prevailing
   * pixel grid size (e.g., 8px). Defaults to 0.
   */
  itemSpacing?: number;
  /**
   * The `justify-content` value.
   */
  justifyContent?: string;
  /**
   * The height of the flexible element.
   */
  height?: string | number;
}

const getAdjustedBasis = (itemCount: number, itemSpacing: number, isLastItem: boolean) => {
  const margin = getMargin(itemSpacing);
  const paddingAndBorder = getPadding(itemSpacing) + BORDER_WIDTH;

  // Total space considers margin for each child (except the last child, which
  // has none) because unlike padding/border it's not accounted for as part of
  // width, given the use of border-box.
  //
  // The last child has no padding or border, which would otherwise eat into
  // width (as it does for every other child); to ensure it ultimately has the
  // same content width as all other children, its basis will be reduced by the
  // would-be padding value off the top, so remove it from the total considered
  // here.
  const totalSpace = margin * (itemCount - 1) - paddingAndBorder;

  const pixelAdjustment = totalSpace / itemCount + (isLastItem ? paddingAndBorder : 0);

  return `calc(${100 / itemCount}% - ${pixelAdjustment}px)`;
};

const isStackChildElement = (
  child: ReactChildrenArrayItem,
): child is React.ReactElement<StackChildProps> =>
  isValidElementOfType(child, STACK_CHILD_COREUITYPE);

const extractStackChildProps = (child: ReactChildrenArrayItem) => {
  if (!isStackChildElement(child)) {
    return {} as Partial<StackChildProps>;
  }

  return child.props;
};

interface GetStackChildArgs extends StackChildInternalProps, StackChildInheritableProps {
  fill: Props['fill'];
}

const getStackChild = (
  child: ReactChildrenArrayItem,
  index: number,
  childrenLength: number,
  theme: Theme,
  {
    basis: defaultBasis,
    component: defaultComponent = Box,
    divider: defaultDivider,
    dividerColor: defaultDividerColor,
    fill,
    grow: defaultGrow,
    overflow: defaultOverflow,
    shrink: defaultShrink,
    spacing: defaultSpacing,
    spacingSide,
  }: GetStackChildArgs,
) => {
  const {
    alignSelf,
    basis,
    children,
    className: innerClassName,
    component: innerComponent,
    divider,
    dividerColor,
    grow,
    overflow,
    shrink,
    ...otherItemProps
  } = extractStackChildProps(child);

  const component = innerComponent || defaultComponent;

  const isLast = index === childrenLength - 1;

  const thisItemBasis = fill
    ? getAdjustedBasis(
        typeof fill === 'number' ? fill : childrenLength,
        defaultSpacing * theme.space.multiple,
        isLast,
      )
    : defaultBasis;

  const stackChildStyle = getStackChildStyle(theme, {
    alignSelf,
    isLast,
    spacingSide,
    basis: basis ?? thisItemBasis,
    divider: divider ?? defaultDivider,
    dividerColor: dividerColor ?? defaultDividerColor,
    grow: grow ?? defaultGrow,
    overflow: overflow ?? defaultOverflow,
    shrink: shrink ?? defaultShrink,
    spacing: defaultSpacing,
  });

  const props: StackChildProcessedProps = {
    ...otherItemProps,
    __isStackChild: true,
    className: cx(innerClassName, stackChildStyle),
    component,
  };

  if (isStackChildElement(child)) {
    // Cloning preserves key and ref.
    return React.cloneElement(child, props, children);
  }

  // In practice, expect React.Children.toArray to set a reasonable key on elements that were
  // specified as a series of children (e.g., multiple sibling JSX elements). Non-elements (e.g.,
  // strings) still require a generated key.
  const key = isValidElement(child) ? child.key : `stack-child-key-idx-${index}`;
  return (
    <StackChild key={key} {...props}>
      {child}
    </StackChild>
  );
};

/**
 * A simple abstraction around a subset of CSS flexbox, with the ability to
 * automatically add spacing and optional dividers between child items.
 */
export const Stack = React.forwardRef<HTMLElement, Props>(
  (
    {
      alignItems,
      children,
      className,
      component: Component = Box,
      direction,
      itemDividersColor,
      itemDividers = false,
      fill = false,
      inline = false,
      itemBasis,
      itemComponent,
      itemGrow = 0,
      itemOverflow,
      itemShrink = 1,
      itemSpacing = 0,
      justifyContent,
      height,
      ...others
    },
    ref,
  ) => {
    devWarning(
      !fill || itemBasis === undefined,
      'itemBasis has no effect in a Stack component when fill is set',
    );

    devWarning(
      !itemDividers || itemSpacing % 2 === 0,
      `itemSpacing should be a multiple of 2 when used with dividers, got ${itemSpacing} instead`,
    );

    const childrenArray = React.Children.toArray(children);

    const spacingSide = direction === 'column' ? 'Bottom' : 'Right';

    const theme = useTheme();

    const stackChildren = childrenArray.map((c, i) =>
      getStackChild(c, i, childrenArray.length, theme, {
        fill,
        spacingSide,
        basis: itemBasis,
        component: itemComponent,
        divider: itemDividers,
        dividerColor: itemDividersColor,
        grow: itemGrow,
        overflow: itemOverflow,
        shrink: itemShrink,
        spacing: itemSpacing,
      }),
    );

    const flexStyle = css(
      { display: inline ? 'inline-flex' : 'flex' },
      direction && {
        flexDirection: direction,
      },
      direction === 'column' &&
        fill && {
          height: '100%',
        },
      alignItems && {
        alignItems,
      },
      justifyContent && {
        justifyContent,
      },
      height !== undefined && {
        height,
      },
    );

    return (
      <Component ref={ref} className={cx(className, flexStyle)} {...others}>
        {stackChildren}
      </Component>
    );
  },
);

Stack.displayName = 'Stack';
