// Note: These colors should always be in sync with:

// Blue star:
// https://tanium.invisionapp.com/share/ZCXBIORXVUY#/screens/419365139
// Dark mode:
// https://tanium.invisionapp.com/share/HEXZC8A78P5#/screens/426019612_ColorPalette

/**
 * Blue star: general
 * Dark mode: dark blue
 */
export const HEX_477FBA = '#477FBA';

/**
 * Blue star: general
 * Dark mode: dark blue
 */
export const HEX_3B93EE = '#3B93EE';

/**
 * Blue star: general
 * Dark mode: dark gray
 */
export const HEX_3E4B68 = '#3E4B68';

/**
 * Blue star: general
 * Dark mode: dark gray
 */
export const HEX_292D38 = '#292D38';

/**
 * Blue star: general
 * Dark mode: light gray
 */
export const HEX_FFFFFF = '#FFF';

/**
 * Blue star: general
 * Dark mode: light gray
 */
export const HEX_FCFDFE = '#FCFDFE';

/**
 * Blue star: general
 * Dark mode: light gray
 */
export const HEX_E2E8F0 = '#E2E8F0';

/**
 * Blue star: general
 * Dark mode: light gray
 */
export const HEX_ADB5C7 = '#ADB5C7';

/**
 * Blue star: general
 * Dark mode: dark gray
 */
export const HEX_7F869D = '#7F869D';

/**
 * Blue star: module icon
 * Dark mode: stoplight
 */
export const HEX_EB3330 = '#EB3330';

/**
 * Blue star: stoplight
 * Dark mode: stoplight
 */
export const HEX_1EB785 = '#1EB785';

/**
 * Blue star: stoplight
 * Dark mode: stoplight
 */
export const HEX_E6444E = '#E6444E';

/**
 * Blue star: stoplight
 * Dark mode: stoplight
 */
export const HEX_FA9529 = '#FA9529';

/**
 * Blue star: stoplight
 * Dark mode: stoplight
 */
export const HEX_AB5DB0 = '#AB5DB0';

/**
 * Blue star: header/nav
 * Dark mode: dark blue
 */
export const HEX_000000 = '#000';

/**
 * Blue star: header/nav
 * Dark mode: stoplight
 */
export const HEX_FB5C67 = '#FB5C67';

/**
 * Blue star:
 * Dark mode: stoplight
 */
export const HEX_CF2A2B = '#CF2A2B';

/**
 * Blue star: general
 */
export const HEX_ECF4FF = '#ECF4FF';

/**
 * Blue star: general
 */
export const HEX_646A7E = '#646A7E';

/**
 * Blue star: general
 */
export const HEX_F2F6FA = '#F2F6FA';

/**
 * Blue star: general
 */
export const HEX_CED7E2 = '#CED7E2';

/**
 * Blue star: stoplight
 */
export const HEX_FFC44F = '#FFC44F';

/**
 * Blue star: stoplight
 */
export const HEX_FFFCEE = '#FFFCEE';

/**
 * Blue star: stoplight
 */
export const HEX_E7F7F2 = '#E7F7F2';

/**
 * Blue star: stoplight
 */
export const HEX_FDEDEE = '#FDEDEE';

/**
 * Blue star: header/nav
 * @deprecated Use {@link HEX_8FC2E5} instead
 */
export const HEX_90C3E3 = '#90C3E3';

/**
 * Blue star: header/nav
 */
export const HEX_333744 = '#333744';

/**
 * Blue star: header/nav
 */
export const HEX_393D4C = '#393D4C';

/**
 * Blue star: header/nav
 */
export const HEX_424551 = '#424551';

/**
 * Dark mode: dark blue
 */
export const HEX_0C1017 = '#0C1017';

/**
 * Dark mode: dark blue
 */
export const HEX_121820 = '#121820';

/**
 * Dark mode: dark blue
 * @deprecated Use {@link HEX_121820} instead
 */
export const HEX_1B2026 = '#1B2026';

/**
 * Dark mode: dark blue
 */
export const HEX_18232F = '#18232F';

/**
 * Dark mode: dark blue
 * @deprecated Use another approved color instead
 */
export const HEX_1B242F = '#1B242F';

/**
 * Dark mode: dark blue
 */
export const HEX_1D2939 = '#1D2939';

/**
 * Dark mode: dark blue
 */
export const HEX_212F41 = '#212F41';

/**
 * Dark mode: dark blue
 */
export const HEX_253548 = '#253548';

/**
 * Dark mode: dark blue
 */
export const HEX_273D58 = '#273D58';

/**
 * Dark mode: dark blue
 */
export const HEX_2D3B4D = '#2D3B4D';

/**
 * Dark mode: dark blue
 */
export const HEX_233C57 = '#233C57';

/**
 * Dark mode: dark blue
 */
export const HEX_2F5278 = '#2F5278';

/**
 * Dark mode: dark blue
 */
export const HEX_335880 = '#335880';

/**
 * Dark mode: dark blue
 */
export const HEX_4973AA = '#4973AA';

/**
 * Dark mode: dark blue
 */
export const HEX_66AAFF = '#66AAFF';

/**
 * Dark mode: dark blue
 */
export const HEX_8FC2E5 = '#8FC2E5';

/**
 * Dark mode: dark gray
 */
export const HEX_343F4E = '#343F4E';

/**
 * Dark mode: dark gray
 */
export const HEX_3D526B = '#3D526B';

/**
 * Dark mode: dark gray
 */
export const HEX_454F5B = '#454F5B';

/**
 * Dark mode: dark gray
 */
export const HEX_455262 = '#455262';

/**
 * Dark mode: dark gray
 */
export const HEX_545A61 = '#545A61';

/**
 * Dark mode: dark gray
 */
export const HEX_585858 = '#585858';

/**
 * Dark mode: dark gray
 */
export const HEX_68727F = '#68727F';

/**
 * Dark mode: dark gray
 */
export const HEX_656F7B = '#656F7B';

/**
 * Dark mode: dark gray
 */
export const HEX_959EB0 = '#959EB0';

/**
 * Dark mode: dark gray
 */
export const HEX_9BA4B6 = '#9BA4B6';

/**
 * Dark mode: light gray
 */
export const HEX_A4A9B3 = '#A4A9B3';

/**
 * Dark mode: light gray
 */
export const HEX_AFBECB = '#AFBECB';

/**
 * Dark mode: light gray
 */
export const HEX_B8C7D5 = '#B8C7D5';

/**
 * Dark mode: light gray
 */
export const HEX_DCDFE6 = '#DCDFE6';

/**
 * Dark mode: light gray
 */
export const HEX_E2E5EB = '#E2E5EB';

/**
 * Dark mode: stoplight
 */
export const HEX_6A3539 = '#6A3539';

/**
 * Dark mode: stoplight
 */
export const HEX_894442 = '#894442';

/**
 * Dark mode: stoplight
 */
export const HEX_FD7D87 = '#FD7D87';

/**
 * Dark mode: stoplight
 */
export const HEX_154D40 = '#154D40';

/**
 * Dark mode: stoplight
 */
export const HEX_FFC63D = '#FFC63D';

// TODO: these colors exist in specs for components, but are not in Invision Color Index
/**
 * Blue star:
 * @deprecated Use {@link HEX_8FC2E5} instead
 */
export const HEX_8EC3E5 = '#8EC3E5';

/**
 * Blue star:
 * @deprecated Use another approved color instead
 */
export const HEX_1D2029 = '#1D2029';

/**
 * Blue star:
 * @deprecated Use {@link HEX_292D38} instead
 */
export const HEX_292D39 = '#292D39';

/**
 * Blue star:
 * @deprecated Use {@link HEX_646A7E} instead
 */
export const HEX_636A7F = '#636A7F';

/**
 * Blue star:
 */
export const HEX_E02020 = '#E02020';

/**
 * Blue star:
 */
export const HEX_FFA900 = '#FFA900';

/**
 * Dark mode: very dark grayish blue
 */
export const HEX_364353 = '#364353';

/**
 * Dark mode: warning notification bg
 */
export const HEX_444444 = '#444444';

/** @deprecated Use a supported constant instead */
export const HEX_6B5D3D = '#6B5D3D';

/** @deprecated Use a `HEX_*` constant instead */
export const WHITE = '#fff'; // PrimaryButtonText

/** @deprecated Use a `HEX_*` constant instead */
export const LIGHTGRAY1 = '#fbfbfc'; // FillLevel1
/** @deprecated Use a `HEX_*` constant instead */
export const LIGHTGRAY2 = '#f4f7ff'; // FillLevel2
/** @deprecated Use a `HEX_*` constant instead */
export const LIGHTGRAY3 = '#e7ebf5'; // FillLevel3
/** @deprecated Use a `HEX_*` constant instead */
export const LIGHTGRAY4 = '#d4d7e3'; // FillBorderLevel4
/** @deprecated Use a `HEX_*` constant instead */
export const LIGHTGRAY5 = '#cfd2d9'; // FillBorderLevel5
/** @deprecated Use a `HEX_*` constant instead */
export const LIGHTGRAY6 = '#b4b6c1'; // FillBorderLevel5
/** @deprecated Use a `HEX_*` constant instead */
export const LIGHTGRAY7 = '#efefef'; // FillLevel3
/** @deprecated Use a `HEX_*` constant instead */
export const LIGHTGRAY8 = '#c9cbd5'; // FillBorderLevel5

/** @deprecated Use a `HEX_*` constant instead */
export const BRANDBLACK = '#101517'; // TextPrimary

/** @deprecated Use a `HEX_*` constant instead */
export const DARKGRAY1 = '#393939'; // HeaderTier2
/** @deprecated Use a `HEX_*` constant instead */
export const DARKGRAY2 = '#444'; // TextPrimary
/** @deprecated Use a `HEX_*` constant instead */
export const DARKGRAY3 = '#8b8b8b'; // FillBorderLevel6
/** @deprecated Use a `HEX_*` constant instead */
export const DARKGRAY4 = '#687284'; // HeaderTier3

/** @deprecated Use a `HEX_*` constant instead */
export const BRANDRED = '#ec3430'; // ModuleIcon

/** @deprecated Use a `HEX_*` constant instead */
export const LIGHTBLUE1 = '#e1f0fa'; // Active2
/** @deprecated Use a `HEX_*` constant instead */
export const LIGHTBLUE2 = '#dbe6ff'; // Active2
/** @deprecated Use a `HEX_*` constant instead */
export const LIGHTBLUE3 = '#d2e7f4'; // Active2
/** @deprecated Use a `HEX_*` constant instead */
export const LIGHTBLUE4 = '#90c3e3'; // Active2

/** @deprecated Use a `HEX_*` constant instead */
export const DARKBLUE1 = '#1970a7'; // ActivePrimary
/** @deprecated Use a `HEX_*` constant instead */
export const DARKBLUE2 = '#1e85c6'; // ActivePrimary

/** @deprecated Use a `HEX_*` constant instead */
export const PALEYELLOW1 = '#fff0bc'; // WarningFill
/** @deprecated Use a `HEX_*` constant instead */
export const PALEYELLOW2 = '#eee0af'; // WarningFill
/** @deprecated Use a `HEX_*` constant instead */
export const PALEYELLOW3 = '#debe80'; // WarningFill
/** @deprecated Use a `HEX_*` constant instead */
export const PALEYELLOW4 = '#fef7e1'; // WarningFill

/** @deprecated Use a `HEX_*` constant instead */
export const PALEGREEN = '#e8f6e8'; // SuccessFill
/** @deprecated Use a `HEX_*` constant instead */
export const PALERED = '#fbd6d6'; // ErrorFill

/** @deprecated Use a `HEX_*` constant instead */
export const GREEN = '#1d8147'; // StatusPositive
/** @deprecated Use a `HEX_*` constant instead */
export const RED = BRANDRED; // ModuleIcon
/** @deprecated Use a `HEX_*` constant instead */
export const YELLOW = '#ffa900'; // StatusWarning
/** @deprecated Use a `HEX_*` constant instead */
export const PURPLE = '#884f9b'; // StatusPending
