import { useContext } from 'react';

import LocaleContext from './LocaleContext';

/**
 * A hook to retrieve locale information.
 */
const useLocale = () => {
  const { locale } = useContext(LocaleContext);

  return locale;
};

export default useLocale;
