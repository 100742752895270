import React from 'react';

import { boldTextWidth } from '@tanium/coreui-utils';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';

import { NavBarHoverPageText, NavBarHoverPageTextHover } from '../../themeDefinitions';
import { NavBarParentItemProps } from '../types';
import UnstyledLink from '../UnstyledLink';

const Wrapper = styled('div')(
  css({
    color: NavBarHoverPageText,
    cursor: 'pointer',
    ':hover': {
      color: NavBarHoverPageTextHover,
    },
    fontSize: 12,
    lineHeight: '16px',
  }),
);

const NavLink = styled(UnstyledLink)(
  css({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
    p: 1,
    borderLeft: '4px solid transparent',
  }),
);

/**
 * A parent item is one that has subsections.
 */
const NavBarParentItem = ({ name, url }: NavBarParentItemProps) => (
  <Wrapper>
    <NavLink href={url} className={boldTextWidth(name)}>
      {name}
    </NavLink>
  </Wrapper>
);

export default NavBarParentItem;
