import React from 'react';

import { NeverChildren } from '@tanium/coreui-utils';
import styled from '@tanium/react-emotion-9';
import { TaniumIcon } from '@tanium/react-graphics';
import { animation, SpinnerRing } from '@tanium/react-spinner';
import { css } from '@tanium/style-system';

import { OuterSpinnerStroke, TaniumIconFill } from './themeDefinitions';

interface Props extends NeverChildren {
  size?: number;
}

const Container = styled('div', { shouldForwardProp: (p) => p !== 'size' })<{ size: number }>(
  {
    position: 'relative',
  },
  ({ size }) => ({ height: size, width: size }),
);

const OuterSpinner = styled(SpinnerRing)(
  {
    animation,
    position: 'absolute',
  },
  css({
    color: OuterSpinnerStroke,
  }),
);

const Spinner = styled(TaniumIcon, { shouldForwardProp: (p) => p !== 'margin' })<{
  margin: number;
}>(
  css({
    position: 'absolute',
    color: TaniumIconFill,
  }),
  ({ margin }) => ({
    margin,
  }),
);

/**
 * A loading spinner graphic with a Tanium logo.
 */
const SpinnerGraphic = ({ size = 96 }: Props) => {
  const outerSize = size;
  const innerSize = (size * 2) / 3;

  return (
    <Container size={outerSize}>
      <OuterSpinner size={outerSize} strokeWidth={1} />
      <Spinner height={innerSize} width={innerSize} margin={(outerSize - innerSize) / 2} />
    </Container>
  );
};

export default SpinnerGraphic;
