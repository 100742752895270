import { ThemeProvider } from 'emotion-theming';
import React, { Suspense } from 'react';

import {
  PersistentNotificationsContextType,
  PersistentNotificationsProvider,
} from '@tanium/react-persistent-notifications-context';
import { TaniumSpinner } from '@tanium/react-tanium-spinner';
import { useTheme } from '@tanium/react-theme-context';
import {
  WorkbenchDetailsContextType,
  WorkbenchDetailsProvider,
} from '@tanium/react-workbench-details-context';

import ErrorBoundary from './ErrorBoundary';
import WorkbenchSkeleton, { SkeletonProps } from './WorkbenchSkeleton';

export type Props = SkeletonProps &
  WorkbenchDetailsContextType &
  PersistentNotificationsContextType;

const Workbench = ({
  children,
  productName,
  icon,
  homeUrl,
  notifications,
  ...skeletonProps
}: Props) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <WorkbenchDetailsProvider productName={productName} icon={icon} homeUrl={homeUrl}>
          <PersistentNotificationsProvider notifications={notifications}>
            <WorkbenchSkeleton {...skeletonProps}>
              <Suspense fallback={<TaniumSpinner fill />}>{children}</Suspense>
            </WorkbenchSkeleton>
          </PersistentNotificationsProvider>
        </WorkbenchDetailsProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default Workbench;
