import React, { useCallback, useEffect } from 'react';

import { OmitStrict } from '@tanium/coreui-utils';
import styled from '@tanium/react-emotion-9';
import { NotificationBase, NotificationBaseProps } from '@tanium/react-notifications-base';
import { useTimer } from '@tanium/react-use-timer';
import { css } from '@tanium/style-system';

import { NOTIFICATION_LIFESPAN, NOTIFICATION_REMOVAL_THROTTLE_INTERVAL } from './constants';

interface Props extends OmitStrict<NotificationBaseProps, 'className' | 'role'> {
  isTimerRunning: boolean;
  onTimerExpired: () => void;
}

const WrappedNotification = styled(NotificationBase)(
  css({
    width: 500,
    overflow: 'hidden',
  }),
);

/**
 * Temporary notification to alert the user of transient information.
 */
const ToastNotification = ({ isTimerRunning, onTimerExpired, ...props }: Props) => {
  const { start, stop } = useTimer(
    useCallback(() => {
      onTimerExpired();
    }, [onTimerExpired]),
    // assume that this notification will always have to wait for the throttle interval before it is
    // actually hidden
    NOTIFICATION_LIFESPAN - NOTIFICATION_REMOVAL_THROTTLE_INTERVAL,
  );

  useEffect(() => {
    if (isTimerRunning) {
      start();
      return () => {
        stop();
      };
    }

    return undefined;
  }, [isTimerRunning, start, stop]);

  return <WrappedNotification role="status" {...props} />;
};

export default ToastNotification;
