import React from 'react';

import { boldTextWidth } from '@tanium/coreui-utils';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { ThemeFontWeights } from '@tanium/theme-data';

import {
  NavBarPinnedFill,
  NavBarPinnedPageHoverFill,
  NavBarPinnedPageSelectedBorderLeft,
  NavBarPinnedPageSelectedFill,
  NavBarPinnedPageText,
} from '../../themeDefinitions';
import { NavBarLeafItemProps } from '../types';
import UnstyledLink from '../UnstyledLink';

const NavLink = styled(UnstyledLink, {
  shouldForwardProp: (p) => p !== 'selected' && p !== 'nested',
})<{ selected: boolean; nested: boolean }>(
  css({
    borderLeft: '4px solid transparent',
    // override inherited color
    '&&': { color: NavBarPinnedPageText },
    cursor: 'pointer',
    display: 'block',
    fontSize: 12,
    lineHeight: '16px',
    paddingLeft: 2,
    paddingRight: 1,
    py: 1,
  }),
  ({ selected }) =>
    css(
      selected
        ? {
            bg: NavBarPinnedPageSelectedFill,
            fontWeight: ThemeFontWeights.Bold,
            borderLeftColor: NavBarPinnedPageSelectedBorderLeft,
          }
        : {
            bg: NavBarPinnedFill,
            ':hover': {
              bg: NavBarPinnedPageHoverFill,
            },
          },
    ),
  ({ nested }) =>
    nested &&
    css({
      paddingLeft: 3,
    }),
);

interface PinnedNavBarLeafItemProps extends NavBarLeafItemProps {
  /**
   * Because we animate expand/collapse of pinned nav bar sections, items are always in the DOM
   * whether they are visible or not. This prop indicates whether the item should be considered
   * hidden.
   */
  hidden: boolean;
}

/**
 * A leaf item is one that has no subsections. It may or may not be nested within a section.
 */
const NavBarLeafItem = ({ hidden, nested, name, selected, url }: PinnedNavBarLeafItemProps) => (
  <NavLink
    tabIndex={hidden ? -1 : undefined}
    selected={selected}
    nested={nested}
    href={url}
    className={boldTextWidth(name)}
  >
    {name}
  </NavLink>
);

export default NavBarLeafItem;
