import { css } from '@tanium/react-emotion-9';
import { Theme } from '@tanium/theme-data';

import {
  StackChildInheritableProps,
  StackChildInternalProps,
  StackChildOnlyProps,
} from './StackChild';
import { Divider } from './themeDefinitions';

interface StackChildStyleProps
  extends StackChildOnlyProps,
    StackChildInheritableProps,
    StackChildInternalProps {
  isLast: boolean;
}

export const getMargin = (itemSpacing: number) => itemSpacing / 2 - 1;
export const getPadding = (itemSpacing: number) => itemSpacing / 2;
export const BORDER_WIDTH = 1;

export const getStackChildStyle = (
  theme: Theme,
  {
    alignSelf,
    basis,
    divider,
    dividerColor = Divider(theme),
    grow,
    isLast,
    overflow,
    shrink,
    spacing,
    spacingSide,
  }: StackChildStyleProps,
) => {
  const dividerColorString: string =
    typeof dividerColor === 'function' ? dividerColor(theme) : dividerColor;
  return css(
    {
      [`padding${spacingSide}`]: isLast ? 0 : (spacing * theme.space.multiple) / 2,
      [`margin${spacingSide}`]: isLast ? 0 : (spacing * theme.space.multiple) / 2 - 1,
      [`border${spacingSide}Width`]: isLast ? 0 : 1,
      [`border${spacingSide}Style`]: 'solid',
      [`border${spacingSide}Color`]: divider ? dividerColorString : 'transparent',
    },
    overflow && {
      overflow,
    },
    grow !== undefined && {
      flexGrow: grow,
    },
    shrink !== undefined && {
      flexShrink: shrink,
    },
    basis !== undefined && {
      flexBasis: basis,
      // Set maxWidth/maxHeight for IE 11. See:
      // https://github.com/philipwalton/flexbugs/issues/3#issuecomment-69036362
      ...(basis !== 'auto' &&
        (spacingSide === 'Right'
          ? {
              maxWidth: basis,
            }
          : {
              maxHeight: basis,
            })),
    },
    alignSelf && {
      alignSelf,
    },
  );
};
