import React from 'react';
import {
  AsComponentProp,
  createAnyComponent,
  ElementRefPropType,
  WithElementRef,
} from '@tanium/coreui-utils';
import styled from '@tanium/react-emotion-9';
import {
  background,
  backgroundStylePropNames,
  BackgroundStyleProps,
  border,
  borderRadius,
  borderRadiusStylePropNames,
  BorderRadiusStyleProps,
  borderStylePropNames,
  BorderStyleProps,
  color,
  colorStylePropNames,
  ColorStyleProps,
  flexbox,
  flexboxStylePropNames,
  FlexboxStyleProps,
  layout,
  layoutStylePropNames,
  LayoutStyleProps,
  position,
  positionStylePropNames,
  PositionStyleProps,
  shadow,
  shadowStylePropNames,
  ShadowStyleProps,
  space,
  spaceStylePropNames,
  SpaceStyleProps,
  sxCompose,
  typography,
  typographyStylePropNames,
  TypographyStyleProps,
} from '@tanium/style-system';

export interface BoxOwnProps
  extends BackgroundStyleProps,
    BorderRadiusStyleProps,
    BorderStyleProps,
    ColorStyleProps,
    FlexboxStyleProps,
    LayoutStyleProps,
    PositionStyleProps,
    ShadowStyleProps,
    SpaceStyleProps,
    TypographyStyleProps {}

// TODO - UX-1578 This is basically AnyComponentProps in utils plus the Omit
// for specific React.ComponentProps. Investigating combining this with that
// type.
type AnyComponentProps<TElementType> = TElementType extends React.ElementType
  ? AsComponentProp<TElementType> &
      Omit<
        React.ComponentProps<TElementType>,
        // TODO - UX-1578 This Omit is similar to the one in css() in that it is
        // necessary to avoid intersections where theme definition function types
        // get &ed with `string` and are impossible to match.
        // For now we have this short list, but we need to support theme
        // definitions for all style properties.
        | 'backgroundColor'
        | 'color'
        | 'borderColor'
        | 'borderBottomColor'
        | 'borderLeftColor'
        | 'borderRightColor'
        | 'borderTopColor'
        | 'boxShadow'
      >
  : AsComponentProp<TElementType>;

type BoxPropsWithAs<TElementType> = BoxOwnProps & AnyComponentProps<TElementType>;

export type BoxProps<TElementType> = WithElementRef<BoxPropsWithAs<TElementType>, TElementType> & {
  /**
   * @deprecated Use `ref` instead.
   */
  innerRef?: ElementRefPropType<TElementType>;
};

/**
 * Box component.
 */
const BoxInner = styled(createAnyComponent(), {
  shouldForwardProp: (p) =>
    ([] as string[])
      .concat(
        backgroundStylePropNames,
        borderRadiusStylePropNames,
        borderStylePropNames,
        colorStylePropNames,
        flexboxStylePropNames,
        layoutStylePropNames,
        positionStylePropNames,
        shadowStylePropNames,
        spaceStylePropNames,
        typographyStylePropNames,
        ['innerRef'],
      )
      .indexOf(p) === -1,
})(
  sxCompose(
    space,
    color,
    typography,
    layout,
    flexbox,
    background,
    border,
    position,
    shadow,
    borderRadius,
  ),
);

// This type cast provides better typings and Intellisense for consumers
export const Box = React.forwardRef<HTMLElement, BoxOwnProps>((props, ref) => (
  <BoxInner innerRef={ref} {...props} />
)) as <TElementType extends unknown = 'div'>(props: BoxProps<TElementType>) => React.ReactElement;
