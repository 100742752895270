import { HEX_18232F, HEX_3D526B, HEX_68727F } from '@tanium/colors';
import { getScrollbarSize } from '@tanium/coreui-utils';
import { isDarkMode, Text1 } from '@tanium/react-theme-definition';
import { css } from '@tanium/style-system';
import { Theme, ThemeFonts } from '@tanium/theme-data';

import linkStyle from './linkStyle';

interface StyledFunctionArgs {
  theme: Theme;
}

// Following same approach as css() from @tanium/style-system here. The args for this
// function are "deliberately non-specific due to conflicts between it and the typings
// for other libraries, specifically emotion".
// The assumption is that it also will be passed directly to styled().
const getGlobalStyles = (props?: Theme | StyledFunctionArgs) => {
  const { theme } = (props as unknown) as StyledFunctionArgs;

  const globalStyles = [linkStyle, css({ fontFamily: ThemeFonts.Main }), css({ color: Text1 })];

  const webkitScrollbarStyles = {
    ['::-webkit-scrollbar' as string]: {
      background: HEX_18232F,
    },
    ['::-webkit-scrollbar-thumb' as string]: {
      backgroundColor: HEX_3D526B,
      borderRadius: '9px',
      backgroundClip: 'content-box',
      border: 'solid 4px transparent',
    },
    ['::-webkit-scrollbar-thumb:vertical' as string]: {
      minHeight: '24px',
    },
    ['::-webkit-scrollbar-thumb:horizontal' as string]: {
      minWidth: '24px',
    },
    ['::-webkit-scrollbar-thumb:vertical:hover' as string]: {
      backgroundColor: HEX_68727F,
    },
    ['::-webkit-scrollbar-thumb:horizontal:hover' as string]: {
      backgroundColor: HEX_68727F,
    },

    ['::-webkit-scrollbar-corner' as string]: {
      backgroundColor: HEX_18232F,
    },
  };

  // Setting -webkit-scrollbar will force scrollbars to show even if
  // you have say scrollbars "hidden unless scrolling" via user preference.
  // Only applying styles if scrollbars are already visible to avoid forcing
  // them to always be visible.
  if (isDarkMode(theme)) {
    const scrollbarSize = getScrollbarSize();
    if (scrollbarSize > 0) {
      globalStyles.push(
        css({ scrollbarColor: `${HEX_3D526B} ${HEX_18232F}` }),
        css(webkitScrollbarStyles),
        css({
          ['*' as string]: webkitScrollbarStyles,
        }),
      );
    }
  }

  const colorScheme = isDarkMode(theme) ? 'dark' : 'light';
  globalStyles.push(css({ colorScheme }));

  return globalStyles;
};

export default getGlobalStyles;
