import { css } from './css';

export type VariantDefinition = Parameters<typeof css>[0];

interface DefineVariantOptions<TKey extends string> {
  /**
   * The variant to use as a default if no variant was explicitly given, or if the specified variant
   * did not match any defined variant.
   */
  defaultVariant?: TKey;
}

export function defineVariant<TKey extends string>(
  variants: Record<TKey, VariantDefinition>,
  { defaultVariant }: DefineVariantOptions<TKey> = {},
) {
  const variantCss = Object.keys(variants)
    .map((k) => [k, css(variants[k as keyof typeof variants])] as const)
    .reduce((acc, [k, variant]) => ({ ...acc, [k]: variant }), {}) as Record<
    TKey,
    ReturnType<typeof css>
  >;

  return (variant?: TKey) => {
    if (variant && variant in variantCss) {
      return variantCss[variant];
    }
    if (defaultVariant) {
      return variantCss[defaultVariant];
    }
    return {};
  };
}
