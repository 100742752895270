import { useEffect, useMemo, useRef } from 'react';

export interface UseTimerReturnValue {
  start: () => void;
  stop: () => void;
}

/**
 * A timer which is canceled when stopped or when the component is removed. The timer is not started
 * by default.
 * @example
 * const MyComponent = () => {
 *   const { start, stop } = useTimer(() => {
 *     alert('Hello, world!');
 *   }, 1000);
 *   return (
 *     <>
 *       <button onClick={start}>Start timer</button>
 *       <button onClick={stop}>Stop timer</button>
 *     </>
 *   );
 * };
 *
 */
export default (callbackProp: Function, timeoutProp: number): UseTimerReturnValue => {
  const callback = useRef<Function>(callbackProp);
  callback.current = callbackProp;

  const timeout = useRef(timeoutProp);
  timeout.current = timeoutProp;

  const timerId = useRef<number>();

  const startTimer = useRef(() => {
    if (!timerId.current) {
      timerId.current = window.setTimeout(() => {
        callback.current();
      }, timeout.current);
    }
  });

  const cancelTimer = useRef(() => {
    if (timerId.current) {
      window.clearTimeout(timerId.current);
      timerId.current = undefined;
    }
  });

  useEffect(() => {
    const cancelTimerValue = cancelTimer.current;
    return () => {
      // When unmounting, cancel the timer. Use a ref to ensure referential consistency.
      cancelTimerValue();
    };
  }, []);

  return useMemo(
    () => ({
      start: () => {
        startTimer.current();
      },
      stop: () => {
        cancelTimer.current();
      },
    }),
    [],
  );
};
