import React from 'react';

import { Button } from '@tanium/react-button';
import styled from '@tanium/react-emotion-9';
import { Stack } from '@tanium/react-stack';
import { css } from '@tanium/style-system';

import { Trans } from './localization';
import {
  ErrorBoundaryFill,
  ErrorBoundaryHeading,
  ErrorBoundaryStackText,
  ErrorBoundaryText,
} from './themeDefinitions';

interface Props {
  children?: React.ReactNode;
}

interface State {
  error?: Error;
  info?: React.ErrorInfo;
  prodError?: boolean;
}

const Container = styled('div')(
  css({
    color: ErrorBoundaryText,
    padding: 3,
  }),
);

const Heading = styled('h2')(css({ color: ErrorBoundaryHeading }));
const ErrorContainer = styled('pre')(
  css({
    color: ErrorBoundaryStackText,
    backgroundColor: ErrorBoundaryFill,
    border: 'none',
  }),
);

export default class ErrorBoundary extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      error: undefined,
      info: undefined,
    };
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    // Display fallback UI
    this.setState({ error, info });
  }

  private reset = () => {
    this.setState({
      error: undefined,
      info: undefined,
    });
  };

  private setProdError = () => {
    this.setState({ prodError: true });
  };

  public render() {
    if (this.state.error && this.state.info) {
      if (process.env.NODE_ENV !== 'production' && !this.state.prodError) {
        return (
          <Container>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            <Heading>Development mode runtime error</Heading>
            <Stack itemSpacing={1}>
              {/* eslint-disable-next-line i18next/no-literal-string */}
              <Button variant="secondary" onClick={this.reset}>
                Retry
              </Button>
              {/* eslint-disable-next-line i18next/no-literal-string */}
              <Button variant="secondary" onClick={this.setProdError}>
                View production error
              </Button>
            </Stack>
            <h3>
              {this.state.error.name}: {this.state.error.message}
            </h3>
            <ErrorContainer>{this.state.error.stack}</ErrorContainer>
            {this.state.info.componentStack && (
              <>
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <h4>Component stack trace</h4>
                <ErrorContainer>{this.state.info.componentStack}</ErrorContainer>
              </>
            )}
            <p>
              {/* eslint-disable-next-line i18next/no-literal-string */}
              <em>
                This message is only shown in development mode. In production mode, this error will
                tell the user to refresh the page and contact Tanium Support if the error persists.
              </em>
            </p>
          </Container>
        );
      }
      return (
        <Container>
          <Trans>
            <Heading>Error</Heading>
            <p>
              An unexpected error occurred while loading the page. Refresh the page. If the problem
              continues, contact Tanium Support.
            </p>
          </Trans>
        </Container>
      );
    }

    return this.props.children;
  }
}
