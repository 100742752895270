declare const process: { env: { NODE_ENV: 'production' | 'test' | 'development' } };

/**
 * Issues a message to console.error in development if the condition is not met. This method accepts
 * functions so that expensive boolean conditions do not have performance implications in production.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- parameter passed to console.error
export default function devWarning(condition: boolean | (() => boolean), message?: any) {
  if (process.env.NODE_ENV === 'production') {
    return;
  }

  const result = typeof condition === 'function' ? condition() : condition;

  if (!result) {
    // Some headless browsers lack consoles
    if (typeof console !== 'undefined') {
      // eslint-disable-next-line no-console
      console.error('WARNING:', message);
    }
    try {
      // This is thrown so you can debug from here.
      throw Error(message);
    } catch (x) {
      // no-op
    }
  }
}
