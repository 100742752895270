import {
  HEX_233C57,
  HEX_253548,
  HEX_335880,
  HEX_343F4E,
  HEX_3B93EE,
  HEX_477FBA,
  HEX_4973AA,
  HEX_656F7B,
  HEX_66AAFF,
  HEX_ADB5C7,
  HEX_CED7E2,
  HEX_ECF4FF,
  HEX_FCFDFE,
  HEX_FFFFFF,
} from '@tanium/colors';
import { getRgbString } from '@tanium/coreui-utils';
import {
  Background2,
  defineColor,
  Foreground1,
  ForegroundState1,
  ForegroundState3,
  Inner1,
  Inner2,
} from '@tanium/react-theme-definition';

export const PrimaryFill = defineColor([HEX_477FBA, HEX_335880]);
export const PrimaryText = Foreground1;

export const PrimaryHoverFill = ForegroundState3;
export const PrimaryHoverText = Foreground1;

export const PrimaryActiveBorder = defineColor(['transparent', HEX_253548]);
export const PrimaryActiveShadow = Inner1;

export const PrimaryDisabledFill = defineColor([HEX_ADB5C7, HEX_343F4E]);
export const PrimaryDisabledText = defineColor([HEX_FFFFFF, HEX_656F7B]);

export const PrimarySubduedFill = defineColor([HEX_ECF4FF, HEX_233C57]);
export const PrimarySubduedText = defineColor([HEX_477FBA, HEX_4973AA]);

export const SecondaryFill = Background2;
export const SecondaryText = defineColor([HEX_477FBA, HEX_66AAFF]);

export const SecondaryBorder = defineColor([HEX_477FBA, HEX_335880]);

export const SecondaryHoverBorder = defineColor([HEX_3B93EE, HEX_335880]);
export const SecondaryHoverFill = defineColor([HEX_ECF4FF, HEX_233C57]);
export const SecondaryHoverText = ForegroundState1;
export const SecondaryActiveShadow = Inner2;

export const SecondaryDisabledBorder = defineColor([HEX_CED7E2, HEX_343F4E]);
export const SecondaryDisabledFill = defineColor([HEX_FCFDFE, HEX_253548]);
export const SecondaryDisabledText = defineColor([HEX_CED7E2, HEX_656F7B]);

export const SecondarySubduedFill = Background2;
export const SecondarySubduedText = defineColor([getRgbString(HEX_477FBA, '0.7'), HEX_4973AA]);
export const SecondarySubduedBorder = defineColor([getRgbString(HEX_3B93EE, '0.7'), HEX_335880]);
