import React, { FC } from 'react';

import { ZINDEX_NOTIFICATIONS } from '@tanium/coreui-utils';
import styled from '@tanium/react-emotion-9';
import { Stack } from '@tanium/react-stack';
import { useToastNotifications } from '@tanium/react-toast-notifications-context';

const Container = styled('div')({
  position: 'fixed',
  width: '100%',
  left: '50%',
  transform: 'translateX(-50%)',
  marginTop: 16,
  pointerEvents: 'none',
  zIndex: ZINDEX_NOTIFICATIONS,
});

const ItemContainer = styled('div')({ pointerEvents: 'auto' });

/**
 * Displays toast notifications in a well-spaced fashion. Usually this will be used with
 * ToastNotificationsWrapper, which is responsible for the governing logic.
 */
const ToastNotificationsDisplay: FC = () => {
  const { notifications } = useToastNotifications();
  return (
    <Stack
      component={Container}
      itemComponent={ItemContainer}
      direction="column"
      alignItems="center"
    >
      {notifications}
    </Stack>
  );
};

export default ToastNotificationsDisplay;
