import { Theme } from '@tanium/theme-data';

import isDarkMode from './isDarkMode';
import { ThemeDefinition } from './useThemeDefinition';

type ThemeDefinitionFactoryParams<T> = [lightModeValue: T, darkModeValue: T];

export type ThemeDefinitionFactory<T> = (
  values: Readonly<ThemeDefinitionFactoryParams<T>>,
) => ThemeDefinition<T>;

/**
 * Create a theme definition
 *
 * @param themeDependentValue A tuple where the first element represents a bluestar value and the second is the dark mode value
 */
const defineThemeValue = <T>([lightModeValue, darkModeValue]: Readonly<
  ThemeDefinitionFactoryParams<T>
>) => (theme: Theme) => (isDarkMode(theme) ? darkModeValue : lightModeValue);

export default defineThemeValue;
