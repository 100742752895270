import { useCallback, useLayoutEffect, useState } from 'react';
import ResizeObserverPolyfill from 'resize-observer-polyfill';

export interface ResizeObserverEntry {
  readonly target: Element;
  readonly contentRect: Omit<DOMRectReadOnly, 'toJSON'>;
}

/**
 * Hook for setting up a ResizeObserver (or a polyfill) on a given element.
 * @param callback A callback to be invoked when the element is resized.
 *
 * @returns A callback ref to be set on the element to be observed.
 */
const useResizeObserver = <TElement extends Element>(
  callback: (entry: ResizeObserverEntry) => void,
) => {
  const [element, setElement] = useState<TElement | null>(null);

  useLayoutEffect(() => {
    if (element) {
      const resizeObserver = new ResizeObserverPolyfill(([entry]) => {
        callback(entry);
      });

      resizeObserver.observe(element);

      return () => {
        resizeObserver.disconnect();
      };
    }
    return undefined;
  }, [element, callback]);

  return useCallback((node: TElement | null) => {
    setElement(node);
  }, []);
};

export default useResizeObserver;
