import { OmitStrict } from '@tanium/coreui-utils';

export type ItemKey = string | number;

/**
 * The possible states of Nav Bar:
 * - "open" when hovering
 * - "closed" when moused out
 * - "pinned" (persistent) after the user has clicked the show button
 */
export type NavBarState = 'open' | 'closed' | 'pinned';

export interface NavSectionLeaf {
  /**
   * This leaf's unique route value(s).
   * This can be a single value, or it can be an array of values if
   * you need to map multiple routes to the same leaf.
   */
  route: string | string[];
  /**
   * The label to display for the nav item.
   */
  name: string;
  /**
   * The path to navigate to when this item is clicked.
   */
  url: string;
}

/**
 * A nav section parent contains multiple leaves within it and may be expandable.
 */
export interface NavSectionParent {
  /**
   * Whether the item should be expanded to show its subsections.
   * When omitted, this is treated as false.
   */
  expanded?: boolean;
  /**
   * The label to display for the nav item.
   */
  name: string;
  /**
   * Unique identifier for this section.
   */
  sectionId: ItemKey;
  /**
   * The subsections (i.e., children) for this item.
   */
  subSections: NavSectionLeaf[];
}

export type NavSection = NavSectionLeaf | NavSectionParent;

export interface NavSectionLeafDetailed extends NavSectionLeaf {
  /**
   * Whether the item is the last item in its (sub)section.
   */
  last: boolean;
  /**
   * Whether the item should be styled as though it is nested.
   */
  nested: boolean;
  /**
   * Whether the item should appear to be selected.
   */
  selected: boolean;
}

/**
 * A section contains multiple leaves within it and may be expandable.
 */
export interface NavSectionParentDetailed
  extends OmitStrict<NavSectionParent, 'expanded' | 'subSections'> {
  /**
   * Whether the item should be expanded to show its subsections.
   * Unlike in `NavSectionParent`, `expanded` is required here.
   */
  expanded: boolean;
  /**
   * Whether this parent section should appear to be selected.
   */
  selected: boolean;
  /**
   * The subsections (i.e., children) for this item.
   * Unlike in `NavSectionParent`, these are "detailed" leaves.
   */
  subSections: NavSectionLeafDetailed[];
  /**
   * The path to navigate to when this parent section is clicked.
   */
  url: string;
}

// These types are "Detailed" because they require every prop that the NavBarBase uses.
export type NavSectionDetailed = NavSectionLeafDetailed | NavSectionParentDetailed;

export const isNavSectionLeaf = (section: NavSection): section is NavSectionLeaf =>
  'route' in section;

export const isNavSectionParent = (section: NavSection): section is NavSectionParent =>
  'subSections' in section;

export interface ExpansionToggleEvent {
  /**
   * The key of the section whose expansion should be toggled.
   */
  sectionId: ItemKey;
  /**
   * Whether the item should be expanded to show its subsections.
   */
  expanded: boolean;
}

export interface NavBarParentItemProps extends NavSectionParentDetailed {
  /**
   * Callback invoked when a user triggers an expansion change.
   * The parent of this component is in charge of deriving the item
   * key and the new expanded state.
   */
  onToggleExpanded?: () => void;
}

export type NavBarLeafItemProps = NavSectionLeafDetailed;
