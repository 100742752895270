import * as React from 'react';

function SvgBreadcrumbCaretThemeAware(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) {
  return (
    <svg height={8} width={8} ref={svgRef} {...props}>
      <path
        d="M6.805 4.453L3.173 7.82c-.26.241-.682.241-.943 0s-.26-.632 0-.874l3.16-2.929-3.195-2.961a.586.586 0 010-.874.704.704 0 01.943 0l3.667 3.398c.26.242.26.633 0 .874z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBreadcrumbCaretThemeAware);
export default ForwardRef;
