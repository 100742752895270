import React from 'react';

import { NeverChildren } from '@tanium/coreui-utils';
import { FillableContainer } from '@tanium/react-spinner';

import SpinnerGraphic from './SpinnerGraphic';

export interface Props extends NeverChildren {
  /**
   * Whether the spinner should fill its container. Defaults to `true`.
   */
  fill?: boolean;
}

const SPINNER_OUTER_SIZE = 96;

/**
 * A loading spinner with a Tanium logo.
 */
const TaniumSpinner = React.forwardRef<HTMLDivElement, Props>(({ fill = true }, ref) => (
  <FillableContainer innerRef={ref} fill={fill}>
    <SpinnerGraphic size={SPINNER_OUTER_SIZE} />
  </FillableContainer>
));

export default TaniumSpinner;
