import { Link } from '@tanium/react-theme-definition';
import { css } from '@tanium/style-system';

/**
 * Workbench-global link styles, mostly required to defeat bootstrap.
 */
// TODO: share or consolidate with same styles in react-link-base
const linkStyle = css({
  ['a' as string]: {
    color: Link,
    textDecoration: 'none',
  },
});

export default linkStyle;
