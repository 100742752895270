import React, { useCallback, useEffect, useMemo } from 'react';

import { devWarning, TestIdProps } from '@tanium/coreui-utils';
import { Box } from '@tanium/react-box';
import styled from '@tanium/react-emotion-9';
import {
  CircleCheckIcon,
  Icon,
  InfoRailIcon_legacy as InfoRailIcon,
  StateCriticalIcon,
  StateWarningIcon,
} from '@tanium/react-graphics';
import { Stack, StackChild } from '@tanium/react-stack';
import { useTheme } from '@tanium/react-theme-context';
import { ThemeDefinition } from '@tanium/react-theme-definition';
import { css } from '@tanium/style-system';
import { ThemeSpace } from '@tanium/theme-data';

import DismissButton from './DismissButton';
import {
  ErrorShadow,
  IconError,
  IconErrorFill,
  IconInfo,
  IconInfoFill,
  IconSuccess,
  IconSuccessFill,
  IconWarning,
  IconWarningFill,
  InfoShadow,
  SuccessShadow,
  Text,
  WarningShadow,
} from './themeDefinitions';

export type NotificationType = 'success' | 'error' | 'warning' | 'info';

export interface Props extends TestIdProps {
  /**
   * Contents to display in the Notification body. This cannot contain any `div`s. Use `span` instead. (For `<Stack>`, use `<Stack as="span" itemComponent="span">`.)
   * @see https://pages.git.corp.tanium.com/tanium/coreui-site/branch/master/latestStable/docs/?path=/story/components-notificationbase--accessibility
   */
  children: NonNullable<React.ReactNode>;
  /**
   * ClassName for overriding base style.
   */
  className?: string;
  /**
   * Define the ARIA Role for use with screen readers. Must be one of 'note', 'status' or 'alert'.
   * @see https://pages.git.corp.tanium.com/tanium/coreui-site/branch/master/latestStable/docs/?path=/story/components-notificationbase--accessibility
   */
  role: React.HTMLAttributes<HTMLDivElement>['role'];
  /**
   * Fired when the notification is dismissed. If this prop is absent, the component is not dismissable.
   */
  onDismiss?: () => void;
  /**
   * What variant of notification to show.
   */
  type: NotificationType;
  /**
   * Style to pass through to the notification. Useful for animations or frequently changing
   * properties where you may not want a static stylename.
   */
  style?: React.CSSProperties;
  /**
   * Props to pass through to nested components.
   */
  nestedProps?: {
    /**
     * Props to pass through to the notification dismiss button.
     */
    notificationDismissButton?: TestIdProps;
  };
}

const getPalette = (notificationType: NotificationType) => {
  switch (notificationType) {
    case 'success':
      return { iconColor: IconSuccess, bgColor: IconSuccessFill };
    case 'error':
      return { iconColor: IconError, bgColor: IconErrorFill };
    case 'warning':
      return { iconColor: IconWarning, bgColor: IconWarningFill };
    case 'info':
    default:
      return { iconColor: IconInfo, bgColor: IconInfoFill };
  }
};

const commonIconProps = {
  style: { display: 'block' },
  height: 16,
  width: 16,
};

const iconMap: Record<NotificationType, Icon> = {
  error: StateCriticalIcon,
  info: InfoRailIcon,
  success: CircleCheckIcon,
  warning: StateWarningIcon,
};

const iconAriaLabelMap: Record<NotificationType, string> = {
  error: 'Error',
  info: 'Info',
  success: 'Success',
  warning: 'Warning',
};

const getIcon = (notificationType: NotificationType) => {
  const IconComponent = notificationType in iconMap ? iconMap[notificationType] : InfoRailIcon;
  const iconAriaLabel =
    notificationType in iconAriaLabelMap ? iconAriaLabelMap[notificationType] : 'Info';
  return (
    <IconComponent {...commonIconProps} role="img" aria-label={iconAriaLabel} focusable="false" />
  );
};

const IconContainer = styled('span')(
  css({
    height: 5,
    display: 'flex',
    alignItems: 'center',
  }),
);

const NotificationIconContainer = styled(IconContainer, {
  shouldForwardProp: (p) => p !== 'color',
})<{
  color: string;
}>(({ color }) =>
  css({
    color,
  }),
);

const shadows: Record<NotificationType, ThemeDefinition> = {
  error: ErrorShadow,
  info: InfoShadow,
  success: SuccessShadow,
  warning: WarningShadow,
};

const NotificationBase = ({
  children,
  type,
  onDismiss,
  className,
  role,
  style,
  'data-test-id': testId,
  nestedProps: { notificationDismissButton = {} } = {},
}: Props) => {
  if (process.env.NODE_ENV !== 'production') {
    // The above condition is invariant
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      devWarning(
        !!role,
        'Missing attribute `role` on `Notification`. For details, check https://pages.git.corp.tanium.com/tanium/coreui-site/branch/master/latestStable/docs/?path=/story/components-persistent-notifications--notification',
      );
    }, [role]);
  }

  const theme = useTheme();
  const { bgColor, iconColor: IconFill } = getPalette(type);
  const iconColor = IconFill(theme);
  const shadow = type in shadows ? shadows[type] : InfoShadow;
  const icon = useMemo(() => getIcon(type), [type]);

  const countDivsRef = useCallback((node: HTMLElement) => {
    if (process.env.NODE_ENV !== 'production' && node !== null) {
      const divsInMessage = node.querySelectorAll('div').length;
      devWarning(
        divsInMessage === 0,
        `Cannot use 'div' within the children of 'Notification' for accessibility reasons. Your message '${node.innerText}' has ${divsInMessage} divs. Please use spans instead. For examples on how to use Stack and StackChild, see: https://pages.git.corp.tanium.com/tanium/coreui-site/branch/master/latestStable/docs/?path=/story/components-persistent-notifications--overview`,
      );
    }
  }, []);

  return (
    <Stack
      itemSpacing={1}
      px={1}
      bg={bgColor}
      boxShadow={shadow}
      borderRadius={1}
      className={className}
      role={role}
      style={style}
      data-test-id={testId}
      ref={countDivsRef}
    >
      <StackChild shrink={0} component={NotificationIconContainer} color={iconColor}>
        {icon}
      </StackChild>
      <StackChild
        grow={1}
        component={Box}
        as="span"
        py={ThemeSpace.minor[3]}
        color={Text}
        fontSize="13px"
        lineHeight="16px"
        style={{ wordBreak: 'break-word' }}
      >
        {children}
      </StackChild>
      {onDismiss && (
        <StackChild shrink={0} component={IconContainer}>
          <DismissButton
            onClick={onDismiss}
            data-test-id={notificationDismissButton['data-test-id']}
          />
        </StackChild>
      )}
    </Stack>
  );
};

export default NotificationBase;
