import { space } from 'styled-system';
import { SpaceStyleType } from './types';

export interface SpaceStyleProps {
  m?: SpaceStyleType;
  margin?: SpaceStyleType;
  mt?: SpaceStyleType;
  marginTop?: SpaceStyleType;
  mb?: SpaceStyleType;
  marginBottom?: SpaceStyleType;
  ml?: SpaceStyleType;
  marginLeft?: SpaceStyleType;
  mr?: SpaceStyleType;
  marginRight?: SpaceStyleType;
  mx?: SpaceStyleType;
  my?: SpaceStyleType;
  p?: SpaceStyleType;
  padding?: SpaceStyleType;
  pt?: SpaceStyleType;
  paddingTop?: SpaceStyleType;
  pb?: SpaceStyleType;
  paddingBottom?: SpaceStyleType;
  pl?: SpaceStyleType;
  paddingLeft?: SpaceStyleType;
  pr?: SpaceStyleType;
  paddingRight?: SpaceStyleType;
  px?: SpaceStyleType;
  py?: SpaceStyleType;
}

export const spaceStylePropNames: (keyof SpaceStyleProps)[] = [
  'm',
  'margin',
  'mt',
  'marginTop',
  'mb',
  'marginBottom',
  'ml',
  'marginLeft',
  'mr',
  'marginRight',
  'mx',
  'my',
  'p',
  'padding',
  'pt',
  'paddingTop',
  'pb',
  'paddingBottom',
  'pl',
  'paddingLeft',
  'pr',
  'paddingRight',
  'px',
  'py',
];

export { space };
