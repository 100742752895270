import { Properties } from 'csstype';
import { color } from 'styled-system';
import { ColorsValue } from '@tanium/theme-data';
import { AnyStringLiteral } from './AnyStringLiteral';
import { CoreUiStyleType } from './types';

export interface ColorStyleProps {
  backgroundColor?: CoreUiStyleType<ColorsValue | AnyStringLiteral>;
  bg?: CoreUiStyleType<ColorsValue | AnyStringLiteral>;
  color?: CoreUiStyleType<ColorsValue | AnyStringLiteral>;
  opacity?: CoreUiStyleType<Properties['opacity']>;
}

export const colorStylePropNames: (keyof ColorStyleProps)[] = [
  'backgroundColor',
  'bg',
  'color',
  'opacity',
];

export { color };
