import mapValues from 'lodash/mapValues';

export type NumericFormat =
  | 'formatInteger'
  | 'formatMilliseconds'
  | 'formatDecimalTwoDigits'
  | 'formatBytes'
  | 'formatKilobytes'
  | 'formatMegabytes'
  | 'formatGigabytes'
  | 'formatTerabytes'
  | 'formatPetabytes';
export type NumericFormatters = Record<NumericFormat, (value: number) => string>;

const buildIntlFormatter = (
  lng: string | string[] | undefined,
  options: Intl.NumberFormatOptions,
) => {
  const formatter = new Intl.NumberFormat(lng, options);
  return formatter.format.bind(formatter);
};

const intlFormatOptions: Record<NumericFormat, Intl.NumberFormatOptions> = {
  formatInteger: { maximumFractionDigits: 0 },
  formatMilliseconds: { style: 'unit', unit: 'millisecond' },
  formatDecimalTwoDigits: { maximumFractionDigits: 2 },
  formatBytes: { style: 'unit', unit: 'byte', unitDisplay: 'long' },
  formatKilobytes: { style: 'unit', unit: 'kilobyte', unitDisplay: 'short' },
  formatMegabytes: { style: 'unit', unit: 'megabyte', unitDisplay: 'short' },
  formatGigabytes: { style: 'unit', unit: 'gigabyte', unitDisplay: 'short' },
  formatTerabytes: { style: 'unit', unit: 'terabyte', unitDisplay: 'short' },
  formatPetabytes: { style: 'unit', unit: 'petabyte', unitDisplay: 'short' },
};

const getNumericFormatters = (lng: string | string[] | undefined): NumericFormatters =>
  mapValues(intlFormatOptions, (options) => buildIntlFormatter(lng, options));

export default getNumericFormatters;
