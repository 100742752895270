import {
  HEX_0C1017,
  HEX_121820,
  HEX_154D40,
  HEX_18232F,
  HEX_1D2939,
  HEX_1EB785,
  HEX_212F41,
  HEX_253548,
  HEX_292D38,
  HEX_335880,
  HEX_3B93EE,
  HEX_3D526B,
  HEX_3E4B68,
  HEX_444444,
  HEX_455262,
  HEX_477FBA,
  HEX_545A61,
  HEX_646A7E,
  HEX_66AAFF,
  HEX_68727F,
  HEX_6A3539,
  HEX_6B5D3D,
  HEX_AB5DB0,
  HEX_ADB5C7,
  HEX_CED7E2,
  HEX_DCDFE6,
  HEX_E2E5EB,
  HEX_E2E8F0,
  HEX_E6444E,
  HEX_E7F7F2,
  HEX_EB3330,
  HEX_F2F6FA,
  HEX_FA9529,
  HEX_FCFDFE,
  HEX_FD7D87,
  HEX_FDEDEE,
  HEX_FFC44F,
  HEX_FFC63D,
  HEX_FFFCEE,
  HEX_FFFFFF,
} from '@tanium/colors';
import { defineColor } from './defineColor';
import { ThemeDefinition } from './useThemeDefinition';

export const StatusPositive = () => HEX_1EB785;
export const StatusNegative = defineColor([HEX_E6444E, HEX_FD7D87]);
export const StatusWarning = defineColor([HEX_FA9529, HEX_FFC63D]);
export const StatusModerate = defineColor([HEX_FFC44F, HEX_FA9529]);
export const StatusPending = () => HEX_AB5DB0;
export const WarningFill = defineColor([HEX_FFFCEE, HEX_444444]);
export const SuccessFill = defineColor([HEX_E7F7F2, HEX_154D40]);
export const ErrorFill = defineColor([HEX_FDEDEE, HEX_6A3539]);

/**
 * Default color for links
 */
export const Link = defineColor([HEX_477FBA, HEX_66AAFF]);

/**
 * Color for links when there are multiple non-primary links
 */
export const LinkSecondary = (() => HEX_477FBA) as ThemeDefinition;

/**
 * Default color for text
 * @deprecated Use Text instead
 */
export const Text1 = defineColor([HEX_292D38, HEX_DCDFE6]);

/**
 * Alternate color for text
 */
export const Text2 = defineColor([HEX_292D38, HEX_FFFFFF]);

/**
 * Alternate color for text
 * @deprecated Use TextSecondary instead
 */
export const Text3 = defineColor([HEX_646A7E, HEX_ADB5C7]);

/**
 * Default color for text
 */
export const Text = Text1;

/**
 * Color for large text headings
 */
export const TextHeading = defineColor([HEX_292D38, HEX_E2E8F0]);

/**
 * Color for secondary content and labels
 */
export const TextSecondary = Text3;

/**
 * Accent color for text and icons
 */
export const Foreground1 = () => HEX_FFFFFF;

/**
 * Accent color for text and icons
 */
export const Foreground2 = () => HEX_477FBA;

/**
 * Accent color for text and icons
 */
export const Foreground3 = defineColor([HEX_292D38, HEX_E2E5EB]);

/**
 * Accent color for text and icons
 */
export const Foreground4 = defineColor([HEX_292D38, HEX_ADB5C7]);

/**
 * Used to indicate many hovers
 */
export const ForegroundState1 = defineColor([HEX_3B93EE, HEX_66AAFF]);

/**
 * Used to indicate active/focus state
 */
export const ForegroundState2 = defineColor([HEX_292D38, HEX_66AAFF]);

/**
 * Used to indicate hover/selected states
 */
export const ForegroundState3 = defineColor([HEX_3B93EE, HEX_477FBA]);

/**
 * Disabled text and horizontal lines
 */
export const ForegroundState4 = defineColor([HEX_CED7E2, HEX_455262]);

/**
 * Common border option
 */
export const Border1 = defineColor([HEX_CED7E2, HEX_1D2939]);

/**
 * Common border option
 */
export const Border2 = defineColor([HEX_CED7E2, HEX_0C1017]);

/**
 * Accent for vertical pipes
 */
export const Border3 = defineColor([HEX_CED7E2, HEX_68727F]);

/**
 * Panel border
 */
export const Border4 = defineColor([HEX_F2F6FA, HEX_1D2939]);

/**
 * Common background color option, used by Welcome and Menu
 */
export const Background1 = defineColor([HEX_FFFFFF, HEX_1D2939]);

/**
 * Common background color option used by Modal and Panel
 * @deprecated Use BackgroundLevel2 instead
 */
export const Background2 = defineColor([HEX_FFFFFF, HEX_253548]);

/**
 * Well background color
 * @deprecated Use BackgroundLevel1 instead
 */
export const Background3 = defineColor([HEX_F2F6FA, HEX_1D2939]);

/**
 * Page background color
 * @deprecated Use BackgroundLevel0 instead
 */
export const Background4 = defineColor([HEX_FCFDFE, HEX_121820]);

/**
 * Page background color
 */
export const BackgroundLevel0 = Background4;

/**
 * Well background color / background color for containers that nest in a Page/BackgroundLevel0 container
 */
export const BackgroundLevel1 = Background3;

/**
 * Background color for containers that nest in a Well/BackgroundLevel1/BackgroundLevel3 container
 */
export const BackgroundLevel2 = Background2;

/**
 * Background color for containers that nest in a BackgroundLevel2 container
 */
export const BackgroundLevel3 = defineColor([HEX_FCFDFE, HEX_212F41]);

/**
 * Border color used with BackgroundLevel2
 */
export const BorderLevel2 = defineColor([HEX_CED7E2, HEX_253548]);

/**
 * Border color used with BackgroundLevel3
 */
export const BorderLevel3 = defineColor([HEX_CED7E2, HEX_18232F]);

export const BackgroundFormInput = defineColor([HEX_FFFFFF, HEX_0C1017]);
export const BackgroundScrollbar = defineColor([HEX_CED7E2, HEX_3D526B]);

export const ModuleIcon = () => HEX_EB3330;

export const InputFill = BackgroundFormInput;
export const InputBorder = Border2;
export const InputSelectedFill = defineColor([HEX_477FBA, HEX_66AAFF]);
export const InputSelectedActiveFill = ForegroundState2;
export const InputHoverBorder = defineColor([HEX_3E4B68, HEX_477FBA]);
export const InputDisabledFill = defineColor([HEX_E2E8F0, HEX_545A61]);
export const InputDisabledBorder = defineColor([HEX_ADB5C7, HEX_545A61]);
export const InputInvalidBorder = StatusNegative;
export const InputActiveBorder = defineColor([HEX_292D38, HEX_0C1017]);
export const InputActiveFill = defineColor([HEX_F2F6FA, HEX_335880]);

export const ChartStatusOptimized = () => '#0E966A';
export const ChartStatusAboveAverage = () => '#1EB785';
export const ChartStatusAverage = () => '#FFC852';
export const ChartStatusBelowAverage = () => '#FA9529';
export const ChartStatusNeedsImprovement = () => '#E6444E';
export const ChartStatusNeutral = () => '#4C5061';
export const ChartStatusPending = () => '#AB5DB0';
export const ChartStatusInformational = () => '#01A7EB';
export const ChartStatusOptimizedBackground = defineColor(['#E7FCF5', HEX_154D40]);
export const ChartStatusAboveAverageBackground = defineColor(['#F4FFFB', '#334B38']);
export const ChartStatusAverageBackground = defineColor(['#FEFCF7', HEX_6B5D3D]);
export const ChartStatusBelowAverageBackground = defineColor(['#FEF4EA', '#51452A']);
export const ChartStatusNeedsImprovementBackground = defineColor(['#FFF7F7', HEX_6A3539]);
export const ChartStatusNeutralBackground = defineColor(['#F9FAFF', HEX_253548]);
export const ChartStatusPendingBackground = defineColor(['#FBF4FC', '#2F2F51']);
export const ChartStatusInformationalBackground = defineColor(['#EFFAFF', HEX_253548]);
export const ChartC01 = () => '#78D5FF';
export const ChartC02 = () => '#3A95E0';
export const ChartC03 = () => '#81D3C1';
export const ChartC04 = () => '#30B99B';
export const ChartC05 = () => '#8387FC';
export const ChartC06 = () => '#514CA9';
export const ChartC07 = () => '#FCDB70';
export const ChartC08 = () => '#F2BB0F';
export const ChartC09 = () => '#4C7291';
export const ChartC10 = () => '#3B4F66';
export const ChartC11 = () => '#F1726E';
export const ChartC12 = () => '#B2403D';
export const ChartC13 = () => '#4A9A8D';
export const ChartC14 = () => '#0E4E44';
export const ChartC15 = () => '#8B4EB6';
export const ChartC16 = () => '#3F1A74';
export const ChartC17 = () => '#91B7DD';
export const ChartC18 = () => '#0B69A8';
export const ChartC19 = () => '#E6B2A6';
export const ChartC20 = () => '#8B450D';
export const ChartC21 = () => '#F496B5';
export const ChartC22 = () => '#A10035';
export const ChartC23 = () => '#D7DD77';
export const ChartC24 = () => '#998432';
export const ChartCoverageError = () => '#8E9294';
export const ChartCoverageInitializing = () => HEX_FA9529;
export const ChartCoverageNeedsAttention = () => HEX_E6444E;
export const ChartCoverageOptimal = () => HEX_1EB785;
export const ChartCoverageUnsupported = () => '#4C5061';
export const ChartError = () => '#8E9294';
export const ChartStatusTrue = () => HEX_1EB785;
export const ChartStatusFalse = () => HEX_E6444E;
export const ChartStatusYes = () => '#01A7EB';
export const ChartStatusNo = () => HEX_E6444E;
