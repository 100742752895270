import { border } from 'styled-system';

import { BordersValue, BorderWidthsValue, ColorsValue, RadiiValue } from '@tanium/theme-data';

import AnyStringLiteral from './AnyStringLiteral';
import { CoreUiStyleType } from './types';

export interface BorderStyleProps {
  border?: CoreUiStyleType<BordersValue | AnyStringLiteral>;
  borderBottom?: CoreUiStyleType<BordersValue | AnyStringLiteral>;
  borderBottomColor?: CoreUiStyleType<ColorsValue | AnyStringLiteral>;
  borderColor?: CoreUiStyleType<ColorsValue | AnyStringLiteral>;
  borderLeft?: CoreUiStyleType<BordersValue | AnyStringLiteral>;
  borderLeftColor?: CoreUiStyleType<ColorsValue | AnyStringLiteral>;
  borderRadius?: CoreUiStyleType<RadiiValue | AnyStringLiteral>;
  borderRight?: CoreUiStyleType<BordersValue | AnyStringLiteral>;
  borderRightColor?: CoreUiStyleType<ColorsValue | AnyStringLiteral>;
  borderStyle?: CoreUiStyleType<number | AnyStringLiteral>; // TODO theme.borderStyles
  borderTop?: CoreUiStyleType<BordersValue | AnyStringLiteral>;
  borderTopColor?: CoreUiStyleType<ColorsValue | AnyStringLiteral>;
  borderWidth?: CoreUiStyleType<BorderWidthsValue | AnyStringLiteral>;
  borderX?: CoreUiStyleType<BordersValue | AnyStringLiteral>;
  borderY?: CoreUiStyleType<BordersValue | AnyStringLiteral>;
}

export const borderStylePropNames: (keyof BorderStyleProps)[] = [
  'border',
  'borderBottom',
  'borderBottomColor',
  'borderColor',
  'borderLeft',
  'borderLeftColor',
  'borderRadius',
  'borderRight',
  'borderRightColor',
  'borderStyle',
  'borderTop',
  'borderTopColor',
  'borderWidth',
  'borderX',
  'borderY',
];

export default border;
