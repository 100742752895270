/**
 * Utility function to get the width of a vertical scrollbar in the current browser.
 */
export default function getScrollbarSize(): number {
  const scrollMeasurer = document.createElement('div');
  const width = 100;
  try {
    scrollMeasurer.style.position = 'absolute';
    scrollMeasurer.style.top = '0';
    scrollMeasurer.style.left = '0';
    scrollMeasurer.style.width = `${width}px`;
    // IE needs some height in order to render scrollbars
    // Firefox has a bug where it does not render scrollbars if height is too short to fit the buttons.
    // https://bugzilla.mozilla.org/show_bug.cgi?id=292284
    scrollMeasurer.style.height = '15px';
    scrollMeasurer.style.overflowY = 'scroll';
    scrollMeasurer.style.zIndex = '-1';

    document.body.appendChild(scrollMeasurer);

    return width - scrollMeasurer.scrollWidth;
  } finally {
    document.body.removeChild(scrollMeasurer);
  }
}
