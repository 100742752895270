import styledSystemCss, { CSSProperties, ResponsiveStyleValue } from '@styled-system/css';
import { SimplePseudos as CSSTypeSimplePseudos } from 'csstype';
import {
  BorderProps,
  ColorProps,
  LayoutProps,
  ShadowProps,
  TypographyProps,
  ZIndexProps,
} from 'styled-system';
import { OmitStrict } from '@tanium/coreui-utils';
import { FontWeightsValue, Theme, ZIndicesValue } from '@tanium/theme-data';
import { LayoutStyleProps } from './layout';
import { SpaceStyleProps } from './space';

type SimplePseudos = CSSTypeSimplePseudos | '&&';

type CoreUiCSSPseudoSelectorProps = { [Key in SimplePseudos]?: CoreUiSystemStyleObject };

type CoreUiColorProps = ColorProps & {
  WebkitTextFillColor?: string;
};

type CoreUiLayoutProps = LayoutProps & {
  zIndex?: ZIndicesValue | ZIndexProps['zIndex'];
};

type CoreUiTypographyProps = OmitStrict<TypographyProps, 'fontWeight'> & {
  fontWeight?: FontWeightsValue | CSSProperties['fontWeight'];
};

type CoreUiAllStyleProps = CoreUiColorProps &
  SpaceStyleProps &
  CoreUiTypographyProps &
  BorderProps &
  ShadowProps &
  LayoutStyleProps &
  CoreUiLayoutProps;

type CoreUiAllStylesPropsWithThemeDefinitions = {
  [K in keyof CoreUiAllStyleProps]:
    | CoreUiAllStyleProps[K]
    | ((theme: Theme) => CoreUiAllStyleProps[K]);
};

type CoreUiSystemStyleObject = ResponsiveStyleValue<
  OmitStrict<
    CSSProperties,
    | 'zIndex'
    | 'fontWeight'
    | 'backgroundColor'
    | 'color'
    | 'borderColor'
    | 'borderBottomColor'
    | 'borderLeftColor'
    | 'borderRightColor'
    | 'borderTopColor'
    | 'boxShadow'
    | 'width'
    | 'height'
  >
> &
  CoreUiCSSPseudoSelectorProps &
  CoreUiAllStylesPropsWithThemeDefinitions;

// The return type for (the function returned by) this function is deliberately non-specific due to
// conflicts between it and the typings for other libraries, specifically emotion. We can consider
// making it more specific in the future, but the assumption is the return value would rarely be
// used directly by teams (and instead passed to a `styled` function directly), so the nominal lack
// of useful typing shouldn't be a practical issue.
type CoreUiCssFunctionType = (
  input?:
    | (CoreUiSystemStyleObject & { variant?: string })
    | ((theme: Theme) => CoreUiSystemStyleObject & { variant?: string }),
  // eslint-disable-next-line @typescript-eslint/ban-types -- using deliberately non-specific return type per above comment
) => (props?: Theme | { theme: Theme }) => {};

export const css: CoreUiCssFunctionType = styledSystemCss as unknown as CoreUiCssFunctionType;
