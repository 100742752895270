import { useContext } from 'react';

import ProductContext from './ProductContext';

/**
 * A hook to retrieve product information.
 */
const useProduct = () => {
  const { product } = useContext(ProductContext);

  return product;
};

export default useProduct;
