import React from 'react';

import { NeverChildren } from '@tanium/coreui-utils';
import { Box } from '@tanium/react-box';

import { NavBarPinnedBorder, NavBarPinnedFill } from '../../themeDefinitions';
import { NavBarBaseProps } from '../NavBarBase';
import NavBarSections from './NavBarSections';

interface Props
  extends NeverChildren,
    Pick<NavBarBaseProps, 'sections' | 'onToggleSectionExpanded'> {}

const NavBarPinned = ({ sections, onToggleSectionExpanded }: Props) => (
  <Box
    height="100%"
    overflowY="auto"
    bg={NavBarPinnedFill}
    borderRight={1}
    borderColor={NavBarPinnedBorder}
  >
    <NavBarSections sections={sections} onToggleSectionExpanded={onToggleSectionExpanded} />
  </Box>
);

export default NavBarPinned;
