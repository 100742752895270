import React, { useCallback, useState } from 'react';

import styled from '@tanium/react-emotion-9';
import { ResizeObserverEntry, useResizeObserver } from '@tanium/react-use-resize-observer';

import { NavBarBaseProps } from '../NavBarBase';
import { isNavSectionParent, ItemKey, NavSectionParentDetailed } from '../types';
import NavBarLeafItem from './NavBarLeafItem';
import NavBarParentItem from './NavBarParentItem';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
});

interface Props extends Pick<NavBarBaseProps, 'sections' | 'onToggleSectionExpanded'> {
  /**
   * This set of sections is "hidden" if the parent section is collapsed.
   * When omitted, it is treated as false.
   */
  hidden?: boolean;
}

// Animated wrapper to expand and collapse subsections.
const ExpandableWrapper = styled('div')({ transition: 'height .3s ease-out', overflow: 'hidden' });

interface SubSectionProps extends Pick<NavBarBaseProps, 'onToggleSectionExpanded'> {
  parent: NavSectionParentDetailed;
}

/**
 * Renders the subsections associated with a NavBarSection
 */
const SubSections = ({ parent, onToggleSectionExpanded }: SubSectionProps) => {
  const [subSectionsHeight, setSubSectionsHeight] = useState(0);
  const handleResizeSubSections = useCallback((e: ResizeObserverEntry) => {
    setSubSectionsHeight(e.target.clientHeight);
  }, []);
  const subSectionsRef = useResizeObserver(handleResizeSubSections);

  return (
    <ExpandableWrapper
      aria-hidden={!parent.expanded}
      style={{ height: parent.expanded ? subSectionsHeight : 0 }}
    >
      <div ref={subSectionsRef}>
        <NavBarSections
          hidden={!parent.expanded}
          sections={parent.subSections}
          onToggleSectionExpanded={onToggleSectionExpanded}
        />
      </div>
    </ExpandableWrapper>
  );
};

/**
 * "Pinned" NavBarSections support expansion.
 */
const NavBarSections = ({ hidden, sections, onToggleSectionExpanded }: Props) => {
  const handleOnToggleExpandedGetter = useCallback(
    (itemKey: ItemKey, currentExpanded: boolean) => () => {
      onToggleSectionExpanded?.({ sectionId: itemKey, expanded: !currentExpanded });
    },
    [onToggleSectionExpanded],
  );
  return (
    <List>
      {sections.map((section) => {
        const { name } = section;

        return (
          <li key={name}>
            {isNavSectionParent(section) ? (
              <>
                <NavBarParentItem
                  {...section}
                  onToggleExpanded={handleOnToggleExpandedGetter(
                    section.sectionId,
                    section.expanded,
                  )}
                />
                {section.subSections && (
                  <SubSections parent={section} onToggleSectionExpanded={onToggleSectionExpanded} />
                )}
              </>
            ) : (
              <NavBarLeafItem hidden={!!hidden} {...section} />
            )}
          </li>
        );
      })}
    </List>
  );
};

export default NavBarSections;
