import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { formatter } from './formatters';

const baseProperties: InitOptions = {
  fallbackLng: 'en-US',
  debug: false,
  ns: [],
  keySeparator: false,
  nsSeparator: false,
  interpolation: {
    alwaysFormat: true,
    escapeValue: false,
    formatSeparator: ',',
    format: formatter,
  },
  react: {
    useSuspense: false,
    transSupportBasicHtmlNodes: false,
    transKeepBasicHtmlNodesFor: [],
  },
};

export interface ResourceObject {
  [language: string]: {
    [schemaName: string]: {
      [word: string]: string;
    };
  };
}

export const getI18nInstance = (packageName: string, resources: ResourceObject) => {
  // TODO: enable multiple namespaces instead of just a single packageName: https://jira.corp.tanium.com/browse/UX-2538
  const properties = { ...baseProperties, ns: [packageName], defaultNS: packageName, resources };
  const localInstance = i18n.createInstance(properties);
  localInstance
    .use(initReactI18next)
    .init()
    .catch((err: Error) => {
      // eslint-disable-next-line no-console
      console.error(`Failed to initialize i18next for translation: ${err}`);
    });
  return localInstance;
};
