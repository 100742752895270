import React, { FC } from 'react';

import { ButtonBase, ButtonVariant, variant as variantCss } from '@tanium/react-button-base';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { ThemeFontWeights } from '@tanium/theme-data';

import ButtonLink from './ButtonLink';
import { ButtonProps } from './types';

const StyledButton = styled(ButtonBase)<ButtonProps & { variant: Exclude<ButtonVariant, 'link'> }>(
  css({
    fontWeight: ThemeFontWeights.Bold,
  }),
  ({ variant }) => variantCss(variant),
);

const Button = React.forwardRef<HTMLElement, ButtonProps>(
  ({ variant = 'primary', disabled = false, ...props }, ref) => {
    // We could just return an unaltered `Link` component (i.e. a styled anchor). The catch is that
    // links have no notion of `disabled`, and disabling every focus, blur, click, etc. handler is a
    // moving target that we could potentially get wrong. So we style a button as a link instead.
    if (variant === 'link') {
      const { color, size, ...buttonLinkProps } = props;
      return <ButtonLink ref={ref} disabled={disabled} {...buttonLinkProps} />;
    }
    return <StyledButton {...props} innerRef={ref} disabled={disabled} variant={variant} />;
  },
);

Button.displayName = 'Button';
// This type cast provides better typings and Intellisense for consumers
export default Button as FC<ButtonProps & { ref?: React.Ref<HTMLElement> }>;
