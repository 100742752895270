import * as React from 'react';

function SvgOptions(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 16 16" ref={svgRef} {...props}>
      <path
        d="M6.012 1.988A1.988 1.988 0 118 3.976a1.988 1.988 0 01-1.988-1.988zm0 6.012A1.988 1.988 0 118 9.988 1.988 1.988 0 016.012 8zm0 6.012A1.988 1.988 0 118 16a1.988 1.988 0 01-1.988-1.988z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgOptions);
export default ForwardRef;
