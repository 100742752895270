import React from 'react';

import isElementOfType from './isElementOfType';
import { CoreUITypedComponent } from './types/CoreUITypedComponent';

export default <TProps, TType extends string>(
  obj: {} | null | undefined,
  type: TType,
): obj is React.ReactElement<TProps, CoreUITypedComponent<TProps, TType>> =>
  React.isValidElement(obj) && isElementOfType(obj.type, type);
