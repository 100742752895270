import React from 'react';

import { css } from '@tanium/react-emotion-9';
import { Icon } from '@tanium/react-graphics';
import { ModuleIcon } from '@tanium/react-theme-definition';

export interface Props {
  /**
   * Icon or URL of the icon to display.
   */
  icon: string | Icon;
  /**
   * Product name. Used for alt text. Do not include "Tanium".
   */
  productName: string;
  /**
   * Height of the icon in px. Defaults to 32.
   */
  height?: number;
  /**
   * Width of the icon in px. Defaults to 32.
   */
  width?: number;
}

const ProductIcon = ({ productName, icon, height = 32, width = 32 }: Props) => {
  const altText = `Tanium ${productName}`;

  if (typeof icon === 'string') {
    return <img alt={altText} src={icon} className={css({ height, width })} />;
  }

  const IconComponent = icon;
  return (
    <IconComponent
      role="img"
      aria-label={altText}
      width={width}
      height={height}
      color={ModuleIcon()}
    />
  );
};

export default ProductIcon;
