import React from 'react';

import styled, { css as cssEmotion, cx } from '@tanium/react-emotion-9';
import { TaniumWordmarkThemeAware as TaniumWordmark } from '@tanium/react-graphics';
import { ProductIcon } from '@tanium/react-product-icon';
import { Stack, StackChild } from '@tanium/react-stack';
import { useTheme } from '@tanium/react-theme-context';
import { useWorkbenchDetails } from '@tanium/react-workbench-details-context';
import { css } from '@tanium/style-system';
import { ThemeFontWeights } from '@tanium/theme-data';

import { ProductNameText, TaniumWordmarkFill } from '../../themeDefinitions';
import { NavBarState } from '../types';

interface Props {
  collapsed?: boolean;
  state: NavBarState;
}

const taniumLogoStyle = cssEmotion({
  fontSize: 1,
  height: 8,
  width: 62,
  marginBottom: 4,
  position: 'relative',
  top: 0,
});

// We make the Tanium logo animate away by fading out and shrinking;
// that's why we style it here and don't just remove it from the DOM.
const taniumLogoSmallStyle = cssEmotion({
  height: 0,
  opacity: 0,
  margin: 0,
  top: -10,
});

const StyledHeader = styled('h1', {
  shouldForwardProp: (p) => !['isSmallMode'].includes(p),
})<{
  isSmallMode: boolean;
}>(({ isSmallMode }) =>
  css({
    margin: 0,
    fontWeight: ThemeFontWeights.Bold,
    fontSize: isSmallMode ? 14 : 28,
    color: ProductNameText,
  }),
);

/**
 * The icon + product name + "Tanium" that appears in the navbar header of the home page.
 */
const HomePageProductHeader = ({ collapsed = false, state }: Props) => {
  const { productName, icon } = useWorkbenchDetails();

  const theme = useTheme();
  const taniumWordmarkColor = TaniumWordmarkFill(theme);

  const isSmallMode = collapsed || state === 'pinned';

  return (
    <Stack component="header" alignItems="center" itemSpacing={1 / 2}>
      {/* lineHeight=0 to cancel the line-height that throws off vertical centering */}
      <StackChild grow={0} lineHeight={0}>
        <ProductIcon
          height={isSmallMode ? 20 : theme.space[5]}
          width={isSmallMode ? 20 : theme.space[5]}
          icon={icon}
          productName={productName}
        />
      </StackChild>
      <StackChild component={Stack} grow={1} shrink={0} direction="column">
        <div className={cx(taniumLogoStyle, isSmallMode && taniumLogoSmallStyle)}>
          <TaniumWordmark color={taniumWordmarkColor} />
        </div>
        <StackChild grow={1}>
          <StyledHeader isSmallMode={isSmallMode}>{productName}</StyledHeader>
        </StackChild>
      </StackChild>
    </Stack>
  );
};

export default HomePageProductHeader;
