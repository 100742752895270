import { useEffect, useState } from 'react';

import { devWarning } from '@tanium/coreui-utils';
import { usePreviousValue } from '@tanium/react-use-previous-value';

export interface UseRefCountArgs {
  /**
   * The starting ref count. Defaults to 0.
   */
  initialCount?: number;
  /**
   * Callback to invoke when the ref count drops to 0.
   */
  onCountZero: () => void;
  /**
   * Callback to invoke when the ref count rises above 0.
   */
  onCountExceedZero: () => void;
}

export interface UseRefCountReturnValue {
  /**
   * The current count.
   */
  count: number;
  /**
   * A function to increment the count by one.
   */
  increment: () => void;
  /**
   * A function to decrement the count by one.
   */
  decrement: () => void;
}

/**
 * Hook to maintain a ref count, firing specified callbacks when the value drops to or rises above
 * zero.
 */
const useRefCount = ({
  initialCount = 0,
  onCountZero,
  onCountExceedZero,
}: UseRefCountArgs): UseRefCountReturnValue => {
  const [count, setCount] = useState(initialCount);
  const previousCount = usePreviousValue(count);

  const increment = () => {
    setCount((c) => c + 1);
  };

  const decrement = () => {
    setCount((c) => c - 1);
  };

  useEffect(() => {
    devWarning(
      count >= 0,
      `useRefCount: ref count fell below 0 to ${count}; check your use of increment/decrement.`,
    );

    if (count > 0 && previousCount <= 0) {
      onCountExceedZero();
    } else if (count <= 0 && previousCount > 0) {
      onCountZero();
    }
  }, [count, onCountExceedZero, onCountZero, previousCount]);

  return {
    count,
    increment,
    decrement,
  };
};

export default useRefCount;
