/**
 * A lightweight route manager. It has two purposes:
 *
 *  - Manage the history API (so workbenches do not need to know if they are using a hash-based
 *    routing approach or an HTML5-based approach);
 *
 *  - Mapping Workbench routes (in our case, partial URLs) to states.
 */
import assign from 'lodash/assign';
import find from 'lodash/find';
import pathToRegexp, { Key } from 'path-to-regexp';
import queryString from 'query-string';

import { devWarning } from '@tanium/coreui-utils';
import { History } from '@tanium/history';

import isAbsolute from './isAbsolute';
import { RouteData, Router, WorkbenchRoute } from './types';

/**
 * InternalWorkbenchRoutes are used by the Router to match a given workbench's url to data.
 */
type InternalWorkbenchRoute = Pick<WorkbenchRoute, 'name' | 'key'> & {
  isMatch: (urlToTest?: string) => boolean;
  getRouteData: (url: string) => RouteData;
};

function buildInternalWorkbenchRoutes(routes: WorkbenchRoute[]): InternalWorkbenchRoute[] {
  function processRoute({
    name,
    key,
    url,
    additionalRouteData = (_) => _,
  }: WorkbenchRoute): InternalWorkbenchRoute {
    const keys: Key[] = [];
    const re = pathToRegexp(url, keys);

    return {
      name,
      key,
      isMatch: (urlToTest?: string) => !!(urlToTest && re.test(urlToTest)),
      getRouteData(currentUrl: string): RouteData {
        const { pathname, search } = getUrlParts(currentUrl);
        const queryParams = queryString.parse(search);

        const urlParameters = re.exec(pathname);
        if (!urlParameters) {
          throw Error(
            `Received ${pathname} and incorrectly attempted to match against pattern ${url}`,
          );
        }
        const params = urlParameters
          .slice(1)
          .reduce((acc, match, i) => assign(acc, { [keys[i].name]: match }), {});

        return {
          meta: {
            pathname,
            route: key,
            queryParams: queryParams || {},
          },
          routeData: additionalRouteData(params),
        };
      },
    };
  }
  return routes.map(processRoute);
}

function getUrlParts(url: string) {
  const splitIndex = url.indexOf('?');
  if (splitIndex === -1) {
    return { pathname: url, search: '' };
  }
  return {
    pathname: url.substring(0, splitIndex),
    search: url.substring(splitIndex),
  };
}

interface CreateRouterArgs {
  /**
   * An array of routes to support.
   */
  routes: WorkbenchRoute[];
  /**
   * The base URL, under which all of your routes appear. Should including a leading slash, but no
   * trailing slash.
   */
  baseUrl: string;
  /**
   * A history object to use for session management (e.g., from `@tanium/history` or equivalent).
   */
  history: History;
}

export default function createRouter({ routes, history, baseUrl }: CreateRouterArgs): Router {
  const internalWorkbenchRouteMap = buildInternalWorkbenchRoutes(routes);

  /**
   * Returns the dictionary containing all the information about a given URL.
   * This is just a traversal of the tree of routes that has been passed into the router.
   */
  function getDataForUrl(url: string): RouteData | null {
    const { pathname } = getUrlParts(url);
    const route = find(internalWorkbenchRouteMap, ({ isMatch }) => isMatch(pathname));
    if (route) {
      return route.getRouteData(url);
    }
    return null;
  }

  function getRoutes() {
    return routes;
  }

  function isInternalRoute(path: string) {
    return !isAbsolute(path);
  }

  return {
    getDataForUrl,
    getRoutes,
    isInternalRoute,
    getBaseUrl() {
      return baseUrl;
    },
    createHref(path) {
      if (isInternalRoute(path)) {
        return history.createHref({ pathname: path });
      }

      return path;
    },
    changeRoute(path) {
      devWarning(
        !!path,
        `Attempted to navigate to a blank route. Route changes must have a defined path. Received ${path}`,
      );
      if (isInternalRoute(path)) {
        history.push(path);
      } else {
        window.location.assign(path);
      }
    },
  };
}
