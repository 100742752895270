import { useMemo } from 'react';

import { useHistory } from '@tanium/react-history-context';
import { createRouter, Router } from '@tanium/workbench-router';

/**
 * Hook that returns a router object.
 */
const useRouter = (): Pick<Router, 'changeRoute' | 'createHref' | 'isInternalRoute'> => {
  const history = useHistory();

  // For now, we provide fake values for `routes` and `baseUrl`. In the future, this will depend on
  // a parent component that accepts any necessary props to create a router object, which would then
  // be propagated here via context.
  return useMemo(() => {
    const { changeRoute, createHref, isInternalRoute } = createRouter({
      history,
      routes: [],
      baseUrl: '',
    });
    return {
      changeRoute,
      createHref,
      isInternalRoute,
    };
  }, [history]);
};

export default useRouter;
