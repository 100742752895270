// Taken from https://stackoverflow.com/a/5624139
const hexToRgb = (hex: string) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const expandedHex = hex.replace(
    shorthandRegex,
    (m, r: string, g: string, b: string) => r + r + g + g + b + b,
  );

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(expandedHex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const getRgbString = (hex: string, alpha = '100%') => {
  const rgb = hexToRgb(hex);

  if (!rgb) {
    throw new Error(`Unable to convert hex:${hex} to rgb`);
  }

  const { r, g, b } = rgb;
  return `rgb(${r}, ${g}, ${b}, ${alpha})`;
};

export default getRgbString;
