import React from 'react';

import styled from '@tanium/react-emotion-9';
import { ChevronRightIcon } from '@tanium/react-graphics';
import { Stack, StackChild } from '@tanium/react-stack';
import { useCollapsed } from '@tanium/react-use-collapsed';
import { css } from '@tanium/style-system';
import { ThemeFontWeights } from '@tanium/theme-data';

import {
  NavBarPinnedFill,
  NavBarPinnedPageHoverFill,
  NavBarPinnedPageText,
  NavBarPinnedToggle,
} from '../../themeDefinitions';
import { NavBarParentItemProps } from '../types';

const Wrapper = styled('div', {
  shouldForwardProp: (p) => p !== 'selected',
})<{ selected: boolean }>(({ selected }) =>
  css({
    bg: NavBarPinnedFill,
    color: NavBarPinnedPageText,
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: selected ? ThemeFontWeights.Bold : ThemeFontWeights.Normal,
    lineHeight: '16px',
    borderLeft: '4px solid transparent',
    py: 1,
    paddingLeft: 2,
    paddingRight: 1,
    ':hover': {
      bg: NavBarPinnedPageHoverFill,
    },
  }),
);

const Disclosure = styled(ChevronRightIcon, { shouldForwardProp: (p) => p !== 'isCollapsed' })<{
  isCollapsed: boolean;
}>(
  css({
    color: NavBarPinnedToggle,
    transition: 'transform .3s ease-out',
  }),
  ({ isCollapsed }) =>
    !isCollapsed && {
      transform: 'rotate(90deg)',
    },
);

/**
 * A parent item is one that has subsections.
 * Clicking the parent item toggles its expanded state.
 */
const NavBarParentItem = ({
  expanded,
  name,
  selected,
  onToggleExpanded,
}: NavBarParentItemProps) => {
  const { collapseControlProps, isCollapsed } = useCollapsed(
    {
      defaultCollapsed: true,
      onToggleCollapsed: onToggleExpanded,
      isCollapsible: true,
      isCollapsed: !expanded,
    },
    'AdvancedFilters',
  );
  return (
    <Wrapper {...collapseControlProps} selected={selected}>
      <Stack itemSpacing={1} justifyContent="space-between">
        <StackChild>{name}</StackChild>
        <Disclosure isCollapsed={isCollapsed} width={8} height={8} />
      </Stack>
    </Wrapper>
  );
};

export default NavBarParentItem;
