import { Properties } from 'csstype';
import { position } from 'styled-system';

import { SpaceValue, ZIndicesValue } from '@tanium/theme-data';

import AnyStringLiteral from './AnyStringLiteral';
import { CoreUiStyleType } from './types';

export interface PositionStyleProps {
  bottom?: CoreUiStyleType<SpaceValue | AnyStringLiteral>;
  left?: CoreUiStyleType<SpaceValue | AnyStringLiteral>;
  position?: CoreUiStyleType<Properties['position']>;
  right?: CoreUiStyleType<SpaceValue | AnyStringLiteral>;
  top?: CoreUiStyleType<SpaceValue | AnyStringLiteral>;
  zIndex?: CoreUiStyleType<ZIndicesValue | Properties['zIndex']>;
}

export const positionStylePropNames: (keyof PositionStyleProps)[] = [
  'bottom',
  'left',
  'position',
  'right',
  'top',
  'zIndex',
];

export default position;
