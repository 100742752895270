import React from 'react';

import { boldTextWidth } from '@tanium/coreui-utils';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { ThemeFontWeights } from '@tanium/theme-data';

import {
  NavBarHoverLeafLines,
  NavBarHoverPageSelectedBorderLeft,
  NavBarHoverPageSelectedFill,
  NavBarHoverPageSelectedText,
  NavBarHoverPageText,
  NavBarHoverPageTextHover,
} from '../../themeDefinitions';
import { NavBarLeafItemProps } from '../types';
import UnstyledLink from '../UnstyledLink';

const Wrapper = styled('div', {
  shouldForwardProp: (p) => p !== 'selected' && p !== 'nested' && p !== 'last',
})<{ selected: boolean; nested: boolean; last: boolean }>(
  css({
    color: NavBarHoverPageText,
    cursor: 'pointer',
    ':hover': {
      color: NavBarHoverPageTextHover,
    },
    fontSize: 12,
    lineHeight: '16px',
  }),
  ({ nested, last }) =>
    nested &&
    css({
      position: 'relative',
      ['::before, ::after' as string]: {
        pointerEvents: 'none',
        content: '""',
        position: 'absolute',
        left: 2,
      },
      '::before': {
        borderTop: 1,
        borderColor: NavBarHoverLeafLines,
        top: '50%',
        width: '5px',
        height: 0,
      },
      '::after': {
        borderLeft: 1,
        borderColor: NavBarHoverLeafLines,
        top: 0,
        width: 0,
        height: '100%',
      },
      ...(last && {
        ':last-child::after': {
          height: '50%',
        },
      }),
    }),
  ({ selected }) =>
    selected &&
    css({
      fontWeight: ThemeFontWeights.Bold,
      color: NavBarHoverPageSelectedText,
      bg: NavBarHoverPageSelectedFill,
    }),
);

const NavLink = styled(UnstyledLink, {
  shouldForwardProp: (p) => !['selected', 'nested'].includes(p),
})<{ selected: boolean; nested: boolean | undefined }>(
  css({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
    p: 1,
    borderLeft: '4px solid transparent',
  }),
  ({ selected }) =>
    selected &&
    css({
      borderLeftColor: NavBarHoverPageSelectedBorderLeft,
    }),
  ({ nested }) =>
    nested &&
    css({
      paddingLeft: 3,
    }),
);

/**
 * A leaf item is one that has no subsections. It may or may not be nested within a section.
 */
const NavBarLeafItem = ({ last, nested, name, selected, url }: NavBarLeafItemProps) => (
  <Wrapper selected={selected} nested={nested} last={last}>
    <NavLink href={url} selected={selected} nested={nested} className={boldTextWidth(name)}>
      {name}
    </NavLink>
  </Wrapper>
);

export default NavBarLeafItem;
