import React, { useCallback, useMemo, useState } from 'react';
import Waypoint from 'react-waypoint';

import { NavBarProps } from '../NavBar';
import { NavBarState } from '../types';
import { NavBarTriggerProps } from './NavBarTrigger';

interface UseNavBarTriggerInput {
  /**
   * The route that is currently selected in the NavBar.
   */
  renderedRoute?: NavBarProps['renderedRoute'];
  /**
   * The sections that populate the NavBar within the Workbench.
   */
  sections?: NavBarProps['sections'];
  /**
   * The prefix to use when storing the pinned state of the navbar.
   */
  storagePrefix: string;
}

export interface UseNavBarTriggerOutput {
  /**
   * Props to be passed to the `NavBar` component.
   * This is only defined when there should be a nav bar.
   */
  navBar?: Pick<
    NavBarProps,
    'renderedRoute' | 'sections' | 'state' | 'onMouseEnter' | 'onMouseLeave'
  >;
  /**
   * Props to be passed to the `NavBarTrigger` component.
   */
  navBarTrigger: Pick<
    NavBarTriggerProps,
    'collapsed' | 'disabled' | 'state' | 'onClick' | 'onMouseEnter' | 'onMouseLeave'
  >;
  /**
   * The state of the NavBar: pinned/open/etc
   */
  state: NavBarState;
  /**
   * An element used to detect when the user has scrolled down on the page.
   */
  waypoint: React.ReactNode;
}

const NAVBAR_PINNED_KEY = 'navbar.pinned';
const getPrefixedKey = (prefix: string) => `${prefix}.${NAVBAR_PINNED_KEY}`;

const WAYPOINT_TRIGGER_VALUE = 20;

/**
 * This hook derives NavBar state from mouse events on NavBar components.
 */
const useNavBarTrigger = ({
  renderedRoute,
  sections,
  storagePrefix: prefix,
}: UseNavBarTriggerInput): UseNavBarTriggerOutput => {
  const storageKey = useMemo(() => getPrefixedKey(prefix), [prefix]);

  const [isPinned, setIsPinned] = useState(() => sessionStorage.getItem(storageKey) === 'true');

  // Clicking the show button toggles the pinned state
  const handleClickShowButton = useCallback(() => {
    setIsPinned((wasPinned) => {
      if (wasPinned) {
        // Unpinning; treat this as though we're mousing out of the nav trigger
        // so that we don't return to "open" state.
        setIsHoveringTrigger(false);
      }

      sessionStorage.setItem(storageKey, `${!wasPinned}`);
      return !wasPinned;
    });
  }, [storageKey, setIsPinned]);

  // Track whether the mouse is over the NavBarTrigger
  const [isHoveringTrigger, setIsHoveringTrigger] = useState(false);

  // Track whether the mouse is over the NavBar
  const [isHoveringNavBar, setIsHoveringNavBar] = useState(false);

  // Track hover status on NavBarTrigger
  const handleMouseEnterTrigger = useCallback(() => setIsHoveringTrigger(true), []);
  const handleMouseLeaveTrigger = useCallback(() => setIsHoveringTrigger(false), []);

  // Track hover status on NavBar
  const handleMouseEnterNavBar = useCallback(() => setIsHoveringNavBar(true), []);
  const handleMouseLeaveNavBar = useCallback(() => setIsHoveringNavBar(false), []);

  // Determine the current nav bar state: open/closed/pinned.
  const state: NavBarState = useMemo(() => {
    if (isPinned) {
      return 'pinned';
    }
    return isHoveringNavBar || isHoveringTrigger ? 'open' : 'closed';
  }, [isHoveringNavBar, isHoveringTrigger, isPinned]);

  // Scrolling above/below a specified point toggles the NavBar's "collapsed" state.
  const [isBelowWaypoint, setIsBelowWaypoint] = useState(false);

  const handleEnterWaypoint = useCallback(() => {
    setIsBelowWaypoint(false);
  }, []);

  const handleLeaveWaypoint = useCallback(() => {
    setIsBelowWaypoint(true);
  }, []);

  // Create the Waypoint element which detects scrolling above/below a specified point
  const waypoint = useMemo(
    () => (
      <Waypoint
        onEnter={handleEnterWaypoint}
        onLeave={handleLeaveWaypoint}
        topOffset={WAYPOINT_TRIGGER_VALUE}
      />
    ),
    [handleEnterWaypoint, handleLeaveWaypoint],
  );

  const navBar = sections
    ? {
        renderedRoute,
        sections,
        state,
        onMouseEnter: handleMouseEnterNavBar,
        onMouseLeave: handleMouseLeaveNavBar,
      }
    : undefined;

  return {
    navBar,
    navBarTrigger: {
      collapsed: isBelowWaypoint,
      disabled: !navBar,
      state,
      onClick: handleClickShowButton,
      onMouseEnter: handleMouseEnterTrigger,
      onMouseLeave: handleMouseLeaveTrigger,
    },
    state,
    waypoint,
  };
};

export default useNavBarTrigger;
