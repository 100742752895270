import { CoreUITypedComponent } from './types/CoreUITypedComponent';

/**
 * Checks whether the given component type is an instance of a particular Core UI component. This
 * operates by checking a well-known static field on the component specific to Core UI components.
 * This field is used instead of, e.g., checking component identity directly because it is more
 * resilient in cases where components might be proxied, such as when hot module reloading is
 * enabled. This also provides a potential (but officially unsupported) escape hatch for teams to
 * wrap components with their own versions that can be recognized as compatible with the wrapped
 * component.
 */
export const isElementOfType = <TProps, TType extends string>(
  componentType: unknown,
  expectedType: TType,
): componentType is CoreUITypedComponent<TProps, TType> =>
  (typeof componentType === 'function' || typeof componentType === 'object') &&
  (componentType as CoreUITypedComponent<TProps, TType>)?.coreuiType === expectedType;
