// import/export doesn't handle default exports with overloads correctly
/* eslint-disable import/export */

import { CSSObject } from 'create-emotion';

import { css } from '@tanium/react-emotion-9';

export function boldTextWidthStyle(text: string): CSSObject {
  return {
    '&::before': {
      display: 'block',
      content: `'${text.replace(/'/g, "\\'")}'`,
      fontWeight: 'bold',
      height: '1px',
      marginTop: '-1px',
      color: 'transparent',
      overflow: 'hidden',
      visibility: 'hidden',
    },
  };
}

export default function boldTextWidth(text: boolean): null;
export default function boldTextWidth(text: string): string;
export default function boldTextWidth(text: string | boolean): string | null;
/**
 * Returns a className to use to render the given text in bold in a
 * pseudo-element, which can be used to render a container element at a
 * particular width suitable for fitting the bold text. This works well with
 * things like buttons and tabs with a single line of text that may or may not
 * be bold, and where it is desirable for the container's width to remain
 * constant in either case.
 *
 * @param text The text that should be used in the pseudo-element. If this
 * parameter is not a string, `null` is returned.
 *
 * @returns a className, or `null` if the `text` parameter was not a string.
 */
export default function boldTextWidth(text: string | boolean) {
  if (typeof text !== 'string') {
    return null;
  }

  return css(boldTextWidthStyle(text));
}
