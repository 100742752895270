import React from 'react';
import { AsComponentProp } from './types/AsComponentProp';
import { ElementRefPropType } from './types/ElementRefPropType';
import { WithElementRef } from './types/WithElementRef';

export type AnyComponentProps<TElementType> = TElementType extends React.ElementType
  ? AsComponentProp<TElementType> & React.ComponentProps<TElementType>
  : AsComponentProp<TElementType>;

type AnyComponentPropsWithRef<TElementType> = WithElementRef<
  AnyComponentProps<TElementType>,
  TElementType
>;

/**
 * A function that returns a component that can be rendered as an arbitrary component or DOM element
 * using the `as` prop.
 *
 * @param defaultAs The component to render as if no `as` prop is specified when the component is
 * rendered. Defaults to `div`.
 */
export const createAnyComponent = <TDefault extends React.ElementType = 'div'>(
  defaultAs: TDefault = 'div' as TDefault,
) =>
  React.forwardRef(
    // @ts-expect-error - satisfying the generic constraints below is challenging or impossible, and
    // the type assertion below limits the scope anyway
    <T extends React.ElementType = TDefault>(
      { as: C = defaultAs, ...props }: AnyComponentProps<T>,
      ref: ElementRefPropType<T>,
    ) => <C ref={ref} {...props} />,
  ) as <TElementType extends unknown = TDefault>(
    props: AnyComponentPropsWithRef<TElementType>,
  ) => React.ReactElement;
