import React from 'react';

import { OmitStrict } from '@tanium/coreui-utils';
import styled from '@tanium/react-emotion-9';
import { LinkBase } from '@tanium/react-link-base';
import { useUsingMouse } from '@tanium/react-mouse-interaction-context';

import { ButtonProps } from './types';

const noOutlineStyle = { ':focus': { outline: 'none' } };

const StyledButtonLink = styled(LinkBase, {
  shouldForwardProp: (prop) => !['usingMouse'].includes(prop),
})<ButtonProps & { usingMouse: boolean }>(
  {
    background: 'transparent',
    padding: 0,
    margin: 0,
    border: 'none',
  },
  ({ usingMouse }) => usingMouse && noOutlineStyle,
);

const ButtonLink = React.forwardRef<HTMLElement, OmitStrict<ButtonProps, 'variant'>>(
  (props, ref) => {
    const usingMouse = useUsingMouse();

    return (
      <StyledButtonLink {...props} innerRef={ref} usingMouse={usingMouse} component="button" />
    );
  },
);

export default ButtonLink;
