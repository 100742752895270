import React from 'react';

import { TestIdProps } from '@tanium/coreui-utils';
import { ButtonBase } from '@tanium/react-button-base';
import styled from '@tanium/react-emotion-9';
import { CloseIcon } from '@tanium/react-graphics';
import { css } from '@tanium/style-system';
import { ThemeSpace } from '@tanium/theme-data';

import { DismissButtonActive, DismissButtonFill, DismissButtonHover } from './themeDefinitions';

interface Props
  extends Omit<React.PropsWithoutRef<JSX.IntrinsicElements['button']>, 'css'>,
    TestIdProps {}

const StyledButton = styled(ButtonBase)(
  css((theme) => {
    const minorSpace = 'minor' in theme.space ? theme.space.minor[1] : 4;
    return {
      p: ThemeSpace.minor[1],
      m: `${-1 * minorSpace}px`,
      bg: 'transparent',
      border: 0,
      color: DismissButtonFill,
      ':hover': {
        color: DismissButtonHover,
      },
      ':active': {
        color: DismissButtonActive,
      },
    };
  }),
);

const DismissButton = ({ ...props }: Props) => (
  <StyledButton aria-label="Dismiss" type="button" {...props}>
    <CloseIcon height={12} width={12} focusable="false" />
  </StyledButton>
);

export default DismissButton;
