import React, { useCallback, useState } from 'react';

import { createStyledButtonBase } from '@tanium/react-button-base';
import styled from '@tanium/react-emotion-9';
import { OptionsIcon, PinIcon } from '@tanium/react-graphics';
import { useUsingMouse } from '@tanium/react-mouse-interaction-context';
import { css } from '@tanium/style-system';

import {
  NavBarButtonFill,
  NavBarButtonHoverPinnedFill,
  NavBarButtonOptionsIconFill,
  NavBarButtonPinIconFill,
} from '../../themeDefinitions';
import { NavBarState } from '../types';
import { NavBarTriggerType } from './NavBarTrigger';

interface ShowButtonProps {
  /** Callback to invoke when the user clicks on the show button */
  onClick: React.MouseEventHandler;
  /** The state of the navbar */
  navBarState: NavBarState;
  /** The navbar trigger type of the button */
  navBarType: NavBarTriggerType;
  /** Whether or not the top bar is collapsed */
  isCollapsed: boolean;
}

const StyledButton = styled(createStyledButtonBase('button'), {
  shouldForwardProp: (p) =>
    !['shouldUsePinIcon', 'shouldPadRight', 'isCollapsed', 'usingMouse', 'bg'].includes(p),
})<{ shouldUsePinIcon: boolean; shouldPadRight: boolean }>(({ shouldUsePinIcon, shouldPadRight }) =>
  css({
    bg: shouldUsePinIcon ? NavBarButtonHoverPinnedFill : NavBarButtonFill,
    // Cancel the line-height from bootstrap or other styling that
    // causes the element to be too tall and therefore not vertically centered.
    lineHeight: 0,
    p: '4px',
    mr: shouldPadRight ? '8px' : 0,
    borderRadius: '36px',
    color: shouldUsePinIcon ? NavBarButtonPinIconFill : NavBarButtonOptionsIconFill,
  }),
);

/**
 * Part of the nav bar UI that can be hovered or clicked to show/hide nav bar.
 * ShowButton always takes up 24x24.
 */
const ShowButton = ({ onClick, navBarState, navBarType, isCollapsed }: ShowButtonProps) => {
  const usingMouse = useUsingMouse();
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = useCallback(() => setIsHovering(true), []);
  const handleMouseLeave = useCallback(() => setIsHovering(false), []);

  const isOverview = navBarType === 'homepage';
  const isPinned = navBarState === 'pinned';
  const shouldPadRight = !isOverview || (isOverview && (isCollapsed || isPinned));
  const shouldUsePinIcon = isHovering || isPinned;
  const Icon = shouldUsePinIcon ? PinIcon : OptionsIcon;

  return (
    <StyledButton
      shouldUsePinIcon={shouldUsePinIcon}
      shouldPadRight={shouldPadRight}
      usingMouse={usingMouse}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Icon width={14} height={14} />
    </StyledButton>
  );
};

export default ShowButton;
