import React, { ComponentPropsWithoutRef } from 'react';

import styled from '@tanium/react-emotion-9';
import { Link } from '@tanium/react-link';
import { css } from '@tanium/style-system';

import { ParentPageText, ParentPageTextHover } from './themeDefinitions';

interface Props extends ComponentPropsWithoutRef<typeof Link> {
  /**
   * The content of the breadcrumb.
   */
  children: React.ReactNode;
}

const StyledBreadcrumb = styled(Link)(
  css({
    // needs && for higher specificity than the workbench-global link style
    ['&&' as string]: {
      color: ParentPageText,
      ':hover': {
        color: ParentPageTextHover,
        textDecoration: 'none',
      },
    },
  }),
);

/**
 * Breadcrumb component. Use with the BreadcrumbSet component to render navigational breadcrumbs.
 */
const Breadcrumb = (props: Props) => <StyledBreadcrumb {...props} />;

export default Breadcrumb;
